/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import pages from "../utils/pages";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },

  button: {
    color: "white",
    textTransform: "uppercase",
  },
  text: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(1),
  },
  media: { height: 200 },
  menuTextLink: {
    "text-decoration": "none",
    color: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.9)",
    },
  },
}));

const MylocSmallHeader = () => {
  const classes = useStyles();

  const network = useSelector(state => state.network.network);
  let history = useHistory();

  return (
    <>
      <Box align="center" onClick={() => history.push(pages.HOME)}>
        <img alt="" src="/logos/myloc_sharing_logo_177x90.png" />
      </Box>
      <Box align="center">
        <Link
          to={"#"}
          onClick={() => {
            history.push(pages.toNetwork(network));
          }}
          className={classes.menuTextLink}
        >
          {network}
        </Link>
      </Box>
    </>
  );
};
export default MylocSmallHeader;
