const LOCAL_STORAGE = "LOCAL_STORAGE";

const SESSION_STORAGE = "SESSION_STORAGE";

const _moveTo = async destinationStorage => {
  let sessionId = "";
  if (destinationStorage === LOCAL_STORAGE) {
    //SessionId
    sessionId = await sessionStorage.getItem("sessionId");
    return await localStorage.setItem("sessionId", sessionId);
  } else {
    //SessionId
    sessionId = await localStorage.getItem("sessionId");
    return await sessionStorage.setItem("sessionId", sessionId);
  }
};
const _loadItem = async (item, rememberMe) => {
  if (rememberMe === undefined || rememberMe === null) {
    //Try to get the remember me setting, first from local Storage, then session Storage
    rememberMe = await localStorage.getItem("rememberMe");

    //Was stored as string, need to parse it
    rememberMe = JSON.parse(rememberMe);

    if (rememberMe === null) {
      rememberMe = await sessionStorage.getItem("rememberMe");
      rememberMe = JSON.parse(rememberMe);
    }

    //Fallback
    if (rememberMe === null) {
      rememberMe = false;
    }
  }

  let data = "";

  //Use local storage only if rememberMe is true
  if (rememberMe === true) {
    data = await localStorage.getItem(item);
  } else {
    data = await sessionStorage.getItem(item);
  }

  //If no success - return null
  if (data === null) {
    return data;
  }

  //Try to parse and return correct type

  try {
    //Try to parse as json object
    data = JSON.parse(data);
    return data;
  } catch (e) {
    //No success, try as boolean
    if (data === "true") {
      data = true;
      return data;
    } else if (data === "false") {
      data = false;
      return data;
    }

    //Still here? Return string if not-a-number,");
    if (isNaN(data)) {
      //String probably, but might be "null"
      if (data === "null") {
        data = null;
      }
      return data;
    } else {
      //Is numeric, return as number
      data = Number(data);
      return data;
    }
  }
};
const _saveItem = async (item, data, rememberMe) => {
  if (typeof data === "object") {
    data = JSON.stringify(data);

    //Fix "null" since a stringified null becomes "null"
    if (data === "null") {
      data = null;
    }
  }

  //If it is the rememberMe setting itself, use it!
  if (item === "rememberMe") {
    rememberMe = data;

    if (!rememberMe) {
      //Move from LocalStorage to SessionStorage
      _moveTo(SESSION_STORAGE);
      //Clear localStorage
      localStorage.clear();

      //TODO flytta över ev. sessionsId
    } else {
      //Move from sessionStorage to localStorage
      _moveTo(LOCAL_STORAGE);
    }
  }

  if (rememberMe === undefined) {
    rememberMe = await _loadItem("rememberMe");
  }

  if (rememberMe) {
    return await localStorage.setItem(item, data);
  } else {
    return await sessionStorage.setItem(item, data);
  }
};

const _deleteItem = async item => {
  //Delete in both session and local storage
  const successLocal = await localStorage.removeItem(item);
  const successSession = await sessionStorage.removeItem(item);
  return successLocal && successSession;
};

const storage = Object.freeze({
  loadItem: async (item, rememberMe) => {
    return await _loadItem(item, rememberMe);
  },
  saveItem: async (item, data, rememberMe) => {
    return await _saveItem(item, data, rememberMe);
  },
  deleteItem: async (item, rememberMe) => {
    return await _deleteItem(item, rememberMe);
  },
});

export default storage;
