import React from "react";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";

const Spacer = () => {
  return <div style={{ height: "4px" }}></div>;
};
const MylocProgressIndicator = props => {
  if (props.loading && !props.loadingFreeze) {
    return <LinearProgress />;
  } else {
    return <Spacer></Spacer>;
  }
};

const mapStateToProps = state => {
  return {
    loading: state.common.loading,
  };
};

export default connect(mapStateToProps)(MylocProgressIndicator);
