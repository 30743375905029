/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import RoundedButton from "../components/RoundedButton";
import { EditorState, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const MylocEditor = ({ initialHTML, onSave, onCancel }) => {
  const getHTML = html => {
    const blocksFromHtml = htmlToDraft(html);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    return EditorState.createWithContent(contentState);
  };

  const [editorState, setEditorState] = useState(getHTML(initialHTML));
  const onEditorStateChange = newEditorState => {
    setEditorState(newEditorState);
  };

  return (
    <>
      <Editor
        toolbar={{
          options: [
            "inline",
            "fontSize",
            "list",
            "textAlign",
            "colorPicker",
            "link",
            "embedded",
            "emoji",
            "image",
            "remove",
            "history",
          ],
        }}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <RoundedButton
          color="primary"
          onClick={() => onSave(draftToHtml(convertToRaw(editorState.getCurrentContent())))}
        >
          Spara
        </RoundedButton>
        <RoundedButton onClick={onCancel}>Avbryt</RoundedButton>
      </div>
    </>
  );
};
export default MylocEditor;
