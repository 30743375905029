/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";
import ReactPlayer from "react-player";

const MylocVideo = ({ url, previewImage, ...rest }) => {
  return (
    <ReactPlayer
      style={{ borderRadius: "10px", border: "solid 1px darkgrey", overflow: "hidden", margin: "auto" }}
      controls={true}
      playing={previewImage ? true : false}
      light={previewImage}
      url={url}
      {...rest}
      onEnded={() => {
        return;
      }}
    ></ReactPlayer>
  );
};

export default MylocVideo;
