/* eslint-disable react/prop-types */
import React from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: { main: "#5ac3da", light: "#a0d5e3", dark: "#39abc4", contrastText: "white" },
    background: {
      default: "#ffffff",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export const MylocThemeProvider = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
