/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import NotLoggedInDialog from "../components/NotLoggedInDialog";

function PrivateRoute({ children, session, ...rest }) {
  const loggedIn = useSelector(state => state.session.loggedIn);
  return (
    <>
      {!loggedIn ? (
        <NotLoggedInDialog />
      ) : (
        <Route
          {...rest}
          render={({ location }) =>
            session.loggedIn ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
}

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps)(PrivateRoute);
