// Initialized in store but in needed to be here too...

const initialState = {
  message: "",
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case RESET_MESSAGE:
      return {
        ...state,
        message: "",
      };
    default:
      return state;
  }
};

//Actions
const SET_MESSAGE = "SET_MESSAGE";
const RESET_MESSAGE = "RESET_MESSAGE";

// Action creators
export function setMessage(message) {
  return { type: SET_MESSAGE, message };
}

export function resetMessage() {
  return { type: RESET_MESSAGE };
}

export default messageReducer;
