/* eslint-disable no-undef */
import React from "react";
import Rating from "@material-ui/lab/Rating";
import { Tooltip, ButtonBase } from "@material-ui/core";

const MylocStars = params => {
  const { numStars, text, description } = params;
  return text && description ? (
    <div>
      <Tooltip title={`${text} - ${description}`}>
        <ButtonBase>
          <Rating name="read-only" value={numStars} readOnly size="medium" />
        </ButtonBase>
      </Tooltip>
    </div>
  ) : (
    <Rating name="read-only" value={numStars} readOnly size="medium" />
  );
};

export default MylocStars;
