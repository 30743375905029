import services from "./services";
import { sendRequest } from "../utils/restUtil";

//Pass in an optional callback function as "postHandlingCallback". It will he handled if call is successful"
// This is an exact copy from adServices should probably be merged into a util
const _handleRequest = async (service, data, postHandlingCallback) => {
  try {
    const responseData = await sendRequest(service, data, postHandlingCallback);
    //Handle success result. Errorhandler handles if not
    _handleResponse(service, responseData);
  } catch (e) {
    console.log(
      "Error ocurred in _handlerRequest, but should have been taken care of in ErrorHandler: " + e.message,
      e,
    );
  }
};

const _handleResponse = (service, data) => {
  switch (service) {
    case services.LIST_TRANSPORT_SERVICES:
      break;
    case services.UPDATE_TRANSPORT_SERVICES:
      break;
    default:
      return;
  }
};

//Pass in an optional callback function as "postHandlingCallback". It will he executed if call is successful, i e redirection"
const handleRequest = (service, data, postHandlingCallback) => {
  _handleRequest(service, data, postHandlingCallback);
};

export default {
  handleRequest,
};
