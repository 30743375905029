/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import store from "../store";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const resetError = () => {
  let resetErrorToDispatch = {
    type: "SET_ERROR",
    error: "",
    errorMessage: "",
  };

  store.dispatch(resetErrorToDispatch);
};

const MylocErrorMessage = props => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (props.e) {
      if (props.e.errorMessage) {
        setErrorMessage(props.e.errorMessage);
        setShowError(true);
      }
    } else {
      setShowError(false);
    }
  }, [props.e]);

  const handleCloseError = () => {
    setShowError(false);
    resetError();
  };

  return (
    <Snackbar open={showError} autoHideDuration={5000} onClose={handleCloseError}>
      <Alert severity="error" onClose={handleCloseError}>
        {errorMessage}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = state => {
  return {
    e: state.e,
  };
};

export default connect(mapStateToProps)(MylocErrorMessage);
