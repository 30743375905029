/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";

import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined";
import SearchIcon from "@material-ui/icons/Search";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import AddCircleIconOutline from "@material-ui/icons/AddCircleOutline";
import MenuButton from "../components/MenuButton";
import SearchField from "../components/SearchField";
import pages from "../utils/pages";
import * as dialogActionCreators from "../reducers/dialogReducer";
import menuItems from "../utils/menuItems";
import accountServices from "../services/accountServices";
import services from "../services/services";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    height: "100%",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(3),
    "padding-bottom": theme.spacing(16),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up("xs")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: -drawerWidth,
      "padding-top": theme.spacing(16),
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  searchLine: {
    display: "flex",
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12,
    display: "flex",
  },
  rightSection: {
    width: "100%",
  },
  headSearchLine: {
    "padding-left": theme.spacing(16),
    "min-width": "250px",
    "max-width": "600px",
    "margin-right": 0,
    "margin-left": "auto",
  },
  largeIcon: {
    fontSize: "1.5em",
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  menuLabel: { display: "flex", "justify-content": "center" },
  menuAppBarIcons: {
    display: "flex",
    "margin-left": "auto",
    "margin-right": "auto",
  },
  menuAppBarIconLabel: {
    display: "flex",
    "justify-content": "center",
  },
  menuTextLink: {
    "text-decoration": "none",
    color: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.9)",
    },
  },
}));

const MylocTopMenu = props => {
  const classes = useStyles();

  const [networks, setNetworks] = useState();

  let history = useHistory();
  const network = useSelector(state => state.network.network);
  const user = useSelector(state => state.user.user);

  const dispatch = useDispatch();

  const getNetworks = () => {
    accountServices.handleRequest(services.NETWORKS, null, setNetworks);
  };
  useEffect(() => {
    if (!networks) {
      getNetworks();
    }
  }, [networks]);

  const showNetworkNotSelected = () => {
    dispatch(dialogActionCreators.setNetworkNotSelectedOpen());
  };

  const navigateToPage = (page, checkLoggedIn) => {
    if (checkLoggedIn && !props.session.loggedIn) {
      props.setNotLoggedInOpen(true, "Du behöver vara inloggad för att skapa en ny annons.");
    } else if (!network) {
      showNetworkNotSelected();
    } else {
      history.push(page);
    }
  };

  const handleSearch = () => {
    history.push(pages.toNetworkADS(network));
  };

  return (
    <div className={classes.rightSection}>
      <Box display="flex" flexDirection="row" justifyContent="flex-end">
        <Box>
          <AdsIcons network={network} classes={classes} navigateToPage={navigateToPage} />
        </Box>

        <Box className={classes.menuAppBarItemContainer}>
          <Container>
            <IconButton
              size="small"
              onClick={() => {
                navigateToPage(pages.AD_CREATE, true);
              }}
            >
              <AddCircleIconOutline className={classes.largeIcon} color="primary" />
            </IconButton>
          </Container>
          <Typography component="div" className={classes.menuLabel}>
            <Link
              to={"#"}
              onClick={() => {
                navigateToPage(pages.AD_CREATE, true);
              }}
              className={classes.menuTextLink}
            >
              Ny annons
            </Link>
          </Typography>
        </Box>

        {props.session && props.session.loggedIn ? (
          <Box className={classes.menuAppBarItemContainer}>
            <MenuButton
              iconType={EditOutlinedIcon}
              items={menuItems.MY_ADS}
              label={user && !user?.company?.lp ? "Mina annonser" : "Våra Annonser"}
            />
          </Box>
        ) : null}
        {props.session && props.session.loggedIn && user && !user?.company?.lp ? (
          <Box className={classes.menuAppBarItemContainer}>
            <MenuButton iconType={ShoppingBasketOutlinedIcon} items={menuItems.MY_BARGAINS} label="Mina fynd" />
          </Box>
        ) : null}

        <Box className={classes.menuAppBarItemContainer}>
          {props.session.sessionId ? (
            <MenuButton
              iconType={AccountCircleOutlined}
              items={props.session.sessionId !== null ? menuItems.PROFILE_LOGGED_IN : menuItems.PROFILE_LOGGED_OUT}
              headingText={
                user?.userInformation?.firstName || user?.userInformation?.surName
                  ? `${user.userInformation.firstName} ${user.userInformation.surname}`
                  : ""
              }
              label="Profil"
            />
          ) : (
            <>
              <Container>
                <IconButton
                  size="small"
                  onClick={() => {
                    navigateToPage(pages.LOGIN, false);
                  }}
                >
                  <AccountCircleOutlined className={classes.largeIcon} color="primary" />
                </IconButton>
              </Container>
              <Typography component="div" className={classes.menuLabel}>
                <Link to={pages.LOGIN} className={classes.menuTextLink}>
                  Logga in
                </Link>
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box className={classes.headSearchLine}>
        <SearchField showWatches={false} onSearch={handleSearch} />

        {/* 
        <MylocTextField
          placeholder="Vad söker du?"
          fullWidth
          value=""
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <SearchIcon color="primary" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        >
          {t("Search")}
        </MylocTextField>
        */}
      </Box>
    </div>
  );
};

const AdsIcons = ({ classes, navigateToPage, network }) => {
  return (
    <>
      <Container>
        <IconButton
          size="small"
          onClick={() => {
            navigateToPage(pages.toNetworkADS(network), false);
          }}
        >
          <SearchIcon className={classes.largeIcon} color="primary" />
        </IconButton>
      </Container>

      <Typography component="div" className={classes.menuLabel}>
        <Link to={pages.toNetworkADS(network)} className={classes.menuTextLink}>
          Annonser
        </Link>
      </Typography>
    </>
  );
};
const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = {
  setNotLoggedInOpen: dialogActionCreators.setNotLoggedInOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MylocTopMenu);
