/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { fade } from "@material-ui/core/styles/colorManipulator";

import AdDescription from "../components/AdDescription";
import AdBookingInfo from "../components/AdBookingInfo";
import useToggleArray from "../hooks/useToggleArray";
import ImageContainer from "../components/ImageContainer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import RoundedButton from "../components/RoundedButton";
import CreateIcon from "@material-ui/icons/Create";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

import {
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
  Checkbox,
  IconButton,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import adServices from "../services/adServices";
import Header from "../components/Header";

import MylocTextField from "../myloc-components/MylocTextField";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as errorActionCreators from "../reducers/errorReducer";
import * as dialogActionCreators from "../reducers/dialogReducer";
import adStatus from "../utils/adStatus";
import pages from "../utils/pages";

// This function is copied from Material-ui, should be a component?
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
  carousel: {
    display: "flex",

    marginBottom: theme.spacing(2),
  },
  image: {
    objectFit: "cover",
    height: "150px",
    width: "150px",
    [theme.breakpoints.up("md")]: {
      height: "400px",
      width: "400px",
    },

    //  width: "100%",
  },
  container: {
    [theme.breakpoints.up("md")]: {
      width: "75%",
    },
  },
  title: {
    marginTop: theme.spacing(1),
  },
  table: {
    width: "300px",
  },
  adType: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleBox: {
    //marginLeft: theme.spacing(1),
    flexWrap: "wrap",
  },
  addresses: {
    marginTop: theme.spacing(3),
    backgroundColor: theme.palette.primary.light,
  },
  tab: {
    backgroundColor: theme.palette.primary.light,
    borderRight: "1px solid grey",
    borderLeft: "1px solid grey",
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.dark,
  },
  bookingsToMediate: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  imageOuterContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  actionsRow: {
    backgroundColor: fade(theme.palette.primary.light, 0.5),
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(0.5),
  },
  timeStamp: {
    display: "flex",
    alignItems: "center",
  },
  publishDate: {
    marginLeft: theme.spacing(1),
  },
}));

const Ad = props => {
  //const { t } = useTranslation();
  const classes = useStyles();
  const [ad, setAd] = useState(undefined);
  const [numberOfItemsToAdd, setNumberOfItemsToAdd] = useState(0);
  const [numberOfItemsToRemove, setNumberOfItemsToRemove] = useState(0);
  const [topButtons, setTopButtons] = useState([]);
  const [listActions, setListActions] = useState([]);
  const [dialogBookOpen, setDialogBookOpen] = useState(false);
  const [dialogUnbookOpen, setDialogUnbookOpen] = useState(false);
  const [actionsDrawerOpen, setActionsDrawerOpen] = useState(false);
  const [dialogWrongCompanyGroupOpen, setDialogWrongCompanyGroupOpen] = useState(false);
  const [dialogMediateOpen, setDialogMediateOpen] = useState(false);
  const [dialogBookingInfoOpen, setDialogBookingInfoOpen] = useState(false);
  //const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedBookings, toggleSelectedBookings, setSelectedBookings] = useToggleArray();
  const [isUsersAd, setIsUsersAd] = useState(false);
  const [imageToShow, setImageToShow] = useState(0);

  const { id } = props;
  const history = useHistory();

  const user = useSelector(state => state.user.user);
  const loggedIn = useSelector(state => state.session.loggedIn);
  const loading = useSelector(state => state.common.loading);
  const network = useSelector(state => state.network.network);

  const dispatch = useDispatch();

  const handleBookAd = () => {
    const numberToBook = Number(numberOfItemsToAdd);
    if (numberToBook !== 0) {
      if (numberToBook > numberRemaining) {
        props.setError({
          errorMessage: `För många valda. Det finns endast ${numberRemaining} kvar`,
          error: "",
        });
        return;
      }
      if (numberToBook < 0) {
        props.setError({
          errorMessage: `Ogiltigt värde!`,
          error: "",
        });
        return;
      }
      adServices.book(id, numberToBook).then(response => {
        const { ad } = response;
        setAd(ad);
        setDialogBookOpen(false);
      });
    } else {
      setDialogBookOpen(false);
    }
  };

  let numberBookedByMe = 0;
  let numberOrderedByMe = 0;
  if (ad && ad.bookings) {
    ad.bookings.forEach(booking => {
      if (booking.bookedBy.userId === user.email && Number(booking.status.id) === 40) {
        if (booking.transportBooked) {
          numberOrderedByMe = numberOrderedByMe + booking.numberOfItems;
        }
        numberBookedByMe = numberBookedByMe + booking.numberOfItems;
      }
    });
  }

  let numberBookableByMe = 0;
  if (ad && ad.info && ad.info.quantity) {
    numberBookableByMe = numberBookedByMe + ad.info.quantity.remaining;
  }

  let numberRemaining = 0;
  if (ad && ad.info && ad.info.quantity) {
    numberRemaining = ad.info.quantity.remaining;
  }

  const handleUnbookAllForUser = userId => {
    adServices.unbookAll(id, userId).then(response => {
      const { ad } = response;
      setAd(ad);
    });
  };

  const handleUnbookAd = userId => {
    const numberToUnbook = Number(numberOfItemsToRemove);
    if (numberToUnbook !== 0) {
      if (numberToUnbook < 0) {
        props.setError({
          errorMessage: `Ogiltigt värde!`,
          error: "",
        });
        return;
      }
      if (numberToUnbook > numberBookedByMe) {
        props.setError({
          errorMessage: `För många valda. Du har endast bokat ${numberBookedByMe} st`,
          error: "",
        });
        return;
      }

      adServices.unbook(id, numberToUnbook, userId).then(response => {
        const { ad } = response;
        setAd(ad);
        setDialogUnbookOpen(false);
      });
    } else {
      setDialogUnbookOpen(false);
    }
  };

  const handlePublishAd = useCallback(() => {
    adServices.publish(id).then(response => {
      const { ad } = response;
      setAd(ad);
    });
  }, [id]);

  const handleUnpublishAd = useCallback(() => {
    adServices.unpublish(id).then(response => {
      const { ad } = response;
      setAd(ad);
    });
  }, [id]);

  const handleArchiveAd = useCallback(() => {
    adServices.archive(id).then(() => {
      history.goBack();
    });
  }, [history, id]);

  const handleOrderTransport = useCallback(() => {
    if (ad?.info?.hasOnGoingStorageOrder) {
      dispatch(
        errorActionCreators.setError({
          errorMessage: "Inlagring pågår, försök senare eller kontakta transportör",
          error: "",
        }),
      );
      return;
    }
    history.push(`${pages.toNetworkADS(network)}/${id}/orderTransport`);
  }, [ad, dispatch, history, id, network]);

  const handleOrderStorage = useCallback(() => {
    history.push(`${pages.toNetworkADS(network)}/${id}/orderStorage`);
  }, [history, id, network]);

  const handleEditAd = id => {
    history.push(pages.toEditAd(id));
  };

  const handleMediateAd = () => {
    adServices.mediate(id, selectedBookings[0].bookedBy.userId, selectedBookings[0].numberOfItems).then(response => {
      const { ad } = response;
      setAd(ad);
      setDialogMediateOpen(false);
      setSelectedBookings([]);
    });
  };

  const handleOpenBookingInfo = () => {
    setDialogBookingInfoOpen(true);
    setActionsDrawerOpen(false);
  };

  useEffect(() => {
    if (id) {
      adServices.get(id).then(response => {
        const { ad } = response;
        setAd(ad);
      });
    }
  }, [id]);

  const handleOpenBookingDialog = useCallback(() => {
    const now = new Date();
    const restrictedUntilDate = new Date(ad.restrictedUntilDate);
    if (loggedIn) {
      if (ad.info.companyGroup === user.companyGroup || now > restrictedUntilDate) {
        setDialogBookOpen(true);
      } else {
        setDialogWrongCompanyGroupOpen(true);
      }
    } else {
      dispatch(dialogActionCreators.setNotLoggedInOpen(true, "Du behöver vara inloggad för att boka annons."));
    }
  }, [ad, dispatch, loggedIn, user.companyGroup]);

  useEffect(() => {
    const newTopButtons = [];
    const newListActions = [];
    let newIsUsersAd = false;
    if (ad) {
      if (ad.info && ad.info.logisticsCompany) {
        if (user?.company?.lp && user?.company?.id === ad.info.logisticsCompany) {
          newIsUsersAd = true;
        }
      } else {
        if (ad.owner && ad.owner.email === user.email) {
          newIsUsersAd = true;
        }
      }
      setIsUsersAd(newIsUsersAd);

      if (newIsUsersAd) {
        if (ad.info) {
          newListActions.push({ label: "Bokningsinfo", onClick: handleOpenBookingInfo });
        }
        // Status == 10
        if (ad.info.status && Number(ad.info.status.id) === adStatus.DRAFT) {
          newTopButtons.push({ label: "Publicera", onClick: handlePublishAd });
        } else {
          if (ad.info.status && Number(ad.info.status.id) <= 20) {
            newListActions.push({ label: "Ta bort", onClick: handleArchiveAd });
          }
          // Status > 10
          if (ad.info.status && Number(ad.info.status.id) === adStatus.PUBLISHED) {
            newListActions.push({ label: "Avpublicera", onClick: handleUnpublishAd });
          }
          if (ad.info.status && Number(ad.info.status.id) === adStatus.BOOKED) {
            if (ad.info && ad.info.quantity && ad.info.quantity.total > ad.info.quantity.remaining) {
              newTopButtons.push({ label: "Förmedla", onClick: () => setDialogMediateOpen(true) });
            }
          }
          if (ad.info.status && Number(ad.info.status.id) < adStatus.BOOKED) {
            if (ad.info && ad.info.quantity && ad.info.quantity.remaining > 0 && !ad.info.logisticsCompany) {
              newListActions.push({ label: "Beställ inlagring", onClick: handleOrderStorage });
            }
          }
        }
      } else {
        if (Number(ad.info.status.id) === adStatus.DRAFT) {
          // Do not show drafts for other users
          history.push(pages.toNetworkADS(network));
        } else {
          // Always show "Boka"-button on other users ads
          if (ad.owner && ad.owner.email !== user.email) {
            if (ad.info && ad.info.quantity && ad.info.quantity.remaining > 0 && numberBookedByMe === 0) {
              newTopButtons.push({ label: "Boka", onClick: handleOpenBookingDialog });
            }
          }
        }
        if (loggedIn) {
          if (numberBookedByMe > numberOrderedByMe) {
            newTopButtons.push({ label: "Beställ transport", onClick: handleOrderTransport });
          }
        }
      }
    }
    setTopButtons(newTopButtons);
    setListActions(newListActions);
  }, [
    ad,
    handleArchiveAd,
    handleOpenBookingDialog,
    handleOrderStorage,
    handleOrderTransport,
    handlePublishAd,
    handleUnpublishAd,
    history,
    loggedIn,
    network,
    numberBookedByMe,
    numberOrderedByMe,
    user,
  ]);

  let imageArraySize = 0;
  if (ad && ad.images && ad.images.files) {
    imageArraySize = ad.images.files.length;
  }

  const handleNextImage = () => {
    if (ad && ad.images && ad.images.files) {
      if (imageToShow === imageArraySize - 1) {
        setImageToShow(0);
      } else {
        setImageToShow(imageToShow + 1);
      }
    }
  };

  const handlePreviousImage = () => {
    if (ad && ad.images && ad.images.files) {
      if (imageToShow === 0) {
        setImageToShow(imageArraySize - 1);
      } else {
        setImageToShow(imageToShow - 1);
      }
    }
  };

  const handleOpenActionsDrawer = () => {
    setActionsDrawerOpen(true);
  };

  return ad ? (
    <>
      <Header heading="" linkBack={() => history.goBack()} />
      <div className={classes.imageOuterContainer}>
        {ad.images.files && ad.images.files.length > 1 ? (
          <div className={classes.carousel}>
            <IconButton aria-label="left" size="medium" onClick={handlePreviousImage}>
              <ArrowBackIosIcon fontSize="inherit" />
            </IconButton>
            <Badge badgeContent={`${imageToShow + 1}/${imageArraySize}`} color="primary">
              <ImageContainer
                width="300px"
                height="200px"
                small={`${process.env.REACT_APP_TOMCAT_ROOT_URL}/${
                  ad.images.files[imageToShow].thumbnailUrl
                    ? ad.images.files[imageToShow].thumbnailUrl
                    : ad.images.files[imageToShow].url
                }`}
                large={`${process.env.REACT_APP_TOMCAT_ROOT_URL}/${ad.images.files[imageToShow].url}`}
                alt={`${ad.info.title}`}
              />
            </Badge>
            <IconButton aria-label="right" size="medium" onClick={handleNextImage}>
              <ArrowForwardIosIcon fontSize="inherit" />
            </IconButton>
          </div>
        ) : (
          <ImageContainer
            width="300px"
            height="200px"
            small={`${process.env.REACT_APP_TOMCAT_ROOT_URL}/${
              ad.images.files[imageToShow].thumbnailUrl ? ad.images.mainFile.thumbnailUrl : ad.images.mainFile.url
            }`}
            large={`${process.env.REACT_APP_TOMCAT_ROOT_URL}/${ad.images.mainFile.url}`}
            alt={`${ad.info.title}`}
          />
        )}
        {/*<Typography>Publicerad: {ad.publishDate}</Typography>*/}
      </div>

      <Drawer anchor="bottom" open={actionsDrawerOpen} onClose={() => setActionsDrawerOpen(false)}>
        <List>
          {listActions.map((listAction, index) => (
            <ListItem key={index} button onClick={listAction.onClick}>
              <ListItemText primary={listAction.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <div className={classes.titleRow}>
        <Typography variant="h4" className={classes.title}>
          {ad.info.title}
        </Typography>
        <Box className={classes.titleBox} display="flex" justifyContent="flex-end" alignContent="center">
          {topButtons.map((button, index) => {
            return (
              <RoundedButton className={classes.button} key={index} onClick={button.onClick}>
                {button.label}
              </RoundedButton>
            );
          })}
        </Box>
      </div>
      <div className={classes.actionsRow}>
        <div className={classes.timeStamp}>
          <AccessTimeIcon />
          <Typography className={classes.publishDate}>{ad.publishDate}</Typography>
        </div>
        {isUsersAd ? (
          <Box display="flex" justifyContent="flex-end">
            {ad.info && ad.info.status && Number(ad.info.status.id) <= 40 && (
              <IconButton size="small" onClick={() => handleEditAd(ad.id)}>
                <CreateIcon />
              </IconButton>
            )}
            <IconButton size="small" onClick={handleOpenActionsDrawer}>
              <MoreVertIcon />
            </IconButton>
          </Box>
        ) : null}
      </div>

      <Dialog open={dialogBookOpen}>
        <DialogTitle id="form-dialog-book">
          {Number(numberBookedByMe) > 0
            ? `Du har bokat ${numberBookedByMe} av ${numberBookableByMe} tillgängliga`
            : `Det finns ${numberBookableByMe} tillgängliga för bokning`}
        </DialogTitle>
        <DialogContent>
          <MylocTextField
            type="number"
            inputProps={{ min: 0, max: ad.info.quantity.remaining }}
            label="Antal att boka"
            defaultValue={0}
            style={{ width: 110 }}
            onChange={e => setNumberOfItemsToAdd(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBookAd} color="primary">
            Ok
          </Button>
          <Button onClick={() => setDialogBookOpen(false)} color="primary">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogWrongCompanyGroupOpen}>
        <DialogTitle id="form-dialog-book">
          Annonsen tillhör{" "}
          {ad.info &&
            ad.info.companyGroup &&
            ad.info.companyGroup.charAt(0).toUpperCase() + ad.info.companyGroup.slice(1).toLowerCase()}{" "}
          och är inte tillgänglig än
        </DialogTitle>
        <DialogContent>
          <Typography>Annonsen blir tillgänglig för grannkommuner {ad.restrictedUntilDate}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogWrongCompanyGroupOpen(false)} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogUnbookOpen}>
        <DialogTitle id="form-dialog-unbook">
          Du har bokat {numberBookedByMe} av {numberBookableByMe} tillgängliga
        </DialogTitle>
        <DialogContent>
          {numberOrderedByMe > 0 && (
            <Typography>{`(${numberOrderedByMe} är redan beställda och kan ej avbokas)`}</Typography>
          )}
          <MylocTextField
            type="number"
            label="Antal att avboka"
            inputProps={{ min: numberOrderedByMe, max: numberBookableByMe }}
            defaultValue={0}
            onChange={e => setNumberOfItemsToRemove(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleUnbookAd(user.email)} color="primary">
            Ok
          </Button>
          <Button onClick={() => setDialogUnbookOpen(false)} color="primary">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={dialogMediateOpen} fullWidth maxWidth="md">
        <DialogTitle>Bokningar</DialogTitle>
        <DialogContent>
          {ad.bookings
            .filter(booking => Number(booking.status.id) === 40)
            .map((booking, index) => {
              return (
                <div key={index} className={classes.bookingsToMediate}>
                  <Checkbox
                    checked={selectedBookings.some(selectedBooking => {
                      return selectedBooking.bookedBy.userId === booking.bookedBy.userId;
                    })}
                    onChange={() => toggleSelectedBookings(booking)}
                    color="primary"
                  />
                  <Typography>{`${booking.bookedBy.userId} (${booking.numberOfItems} st)`}</Typography>
                </div>
              );
            })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleMediateAd()} color="primary">
            Förmedla valda
          </Button>
          <Button onClick={() => setDialogMediateOpen(false)} color="primary">
            Avbryt
          </Button>
        </DialogActions>
      </Dialog>

      <AdDescription
        ad={ad}
        myBooking={{
          numberBookedByMe: numberBookedByMe,
          numberBookableByMe: numberBookableByMe,
          numberOrderedByMe: numberOrderedByMe,
          setDialogBookOpen: setDialogBookOpen,
          setDialogUnbookOpen: setDialogUnbookOpen,
        }}
      />

      <Dialog open={dialogBookingInfoOpen} onClose={() => setDialogBookingInfoOpen(false)} maxWidth="md" fullWidth>
        <AdBookingInfo ad={ad} handleUnbookAd={handleUnbookAllForUser}></AdBookingInfo>
      </Dialog>
    </>
  ) : !loading ? (
    <Typography>Det finns ingen aktiv annons med id: {id}</Typography>
  ) : null;
};

export default Ad;
