/* eslint-disable react/prop-types */
// React/javascript libraries
import React, { useState, useEffect } from "react";
import { Typography, Button, Paper, Box, Checkbox } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MylocTextField from "../myloc-components/MylocTextField";
import Header from "../components/Header";
import adServices from "../services/adServices";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fade } from "@material-ui/core/styles/colorManipulator";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import toDate from "date-fns/toDate";
import compareAsc from "date-fns/compareAsc";
import * as messageActionCreators from "../reducers/messageReducer";
import * as errorActionCreators from "../reducers/errorReducer";
import services from "../services/services";
import logisticsPartnerServices from "../services/logisticsPartnerServices";
import pages from "../utils/pages";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  paper: {
    backgroundColor: fade(theme.palette.primary.light, 0.3),
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    display: "flex",
  },
  paperHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
}));

const OrderStorage = props => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const user = useSelector(state => state.user.user);
  // Do these locally for now, maybe later in an addressReducer that could be used in other places where we have addresses
  const [deliveryInfo, setDeliveryInfo] = useState("");
  const initialReferenceId = user?.userInformation.referenceId;
  const [referenceId, setReferenceId] = useState(initialReferenceId);
  const [deliveryDate, setDeliveryDate] = useState(format(startOfToday(), "yyyy-MM-dd"));
  const [deliveryTime, setDeliveryTime] = useState(format(new Date(), "HH:mm"));
  const [transportServices, setTransportServices] = useState([]);
  const [selectedTransportService, setSelectedTransportService] = useState("");

  const network = useSelector(state => state.network.network);

  useEffect(() => {
    if (user && user.company && user.company.id) {
      logisticsPartnerServices.handleRequest(
        services.LIST_TRANSPORT_SERVICES,
        `?network=${network}&customer=${user.company.id}`,
        data => {
          setTransportServices(data.transportServices);
        },
      );
    }
  }, [network, user]);

  const handleOrderStorage = evt => {
    evt.preventDefault();
    let orderType = "";
    let logisticsCompany = "";
    if (transportServices[selectedTransportService] && transportServices[selectedTransportService].logisticsPartner) {
      orderType = transportServices[selectedTransportService].orderType;
      logisticsCompany = transportServices[selectedTransportService].logisticsPartner.id;
    }

    if (!logisticsCompany) {
      props.setError({ errorMessage: "Transportör måste väljas", error: "" });
      return;
    }

    const deliveryDateTime = toDate(
      new Date(
        deliveryDate.substring(0, 4),
        deliveryDate.substring(5, 7) - 1,
        deliveryDate.substring(8, 10),
        deliveryTime.substring(0, 2),
        deliveryTime.substring(3, 5),
        0,
      ),
    );
    if (compareAsc(deliveryDateTime, new Date()) < 1) {
      props.setError({ errorMessage: "Önskad leveranstid har redan passerat", error: "" });
    }

    const data = {
      information: deliveryInfo,
      orderType: orderType,
      logisticsCompany: logisticsCompany,
      reference: referenceId,
      toStorage: true,
      requestedDateTime: deliveryDateTime,
    };
    data.queryParam = {
      sessionId: props.session.sessionId,
    };
    data.pathParam = {
      adId: props.id,
    };

    adServices.handleRequest(services.ORDER_TRANSPORT, data, () => {
      history.push(`${pages.toNetworkADS(network)}/${props.id}`);
    });
  };

  return (
    <form onSubmit={handleOrderStorage}>
      <Header heading="" linkBack={history.push(`${pages.toNetworkADS(network)}/${props.id}`)} />
      <Typography className={classes.heading} variant="h4">
        Beställ inlagring
      </Typography>
      <Typography variant="h6">Välj vem som ska hämta upp sakerna</Typography>
      {transportServices
        .filter(transportService => transportService.category === "STOCKIN")
        .map((transportService, index) => {
          let description = transportService.description.split("\n");
          return (
            <Paper key={index} className={classes.paper}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxOutlinedIcon fontSize="large" />}
                className={classes.checkbox}
                checked={selectedTransportService === index}
                onChange={() => setSelectedTransportService(index)}
                color={theme.palette.primary.dark}
                inputProps={{ "aria-label": "logistics partner checkbox" }}
              />
              <div>
                <Typography className={classes.paperHeading}>{transportService.logisticsPartner.name}</Typography>
                {description.map((line, index) => (line ? <Typography key={index}>{line}</Typography> : <br />))}
              </div>
            </Paper>
          );
        })}
      {!transportServices.find(service => service.category === "STOCKIN") && (
        <Paper className={classes.paper}>
          <div>
            <Typography className={classes.paperHeading}>Ingen transportör tillgänglig</Typography>
          </div>
        </Paper>
      )}
      <MylocTextField
        required
        fullWidth
        value={referenceId}
        label="Referensnummer"
        onChange={e => setReferenceId(e.target.value)}
      />
      <MylocTextField
        fullWidth
        multiline
        value={deliveryInfo}
        label="Information till transportör"
        onChange={e => setDeliveryInfo(e.target.value)}
      />
      <Box display="flex">
        <MylocTextField
          required
          className={classes.date}
          id="new-date"
          label="Önskad hämtning"
          value={deliveryDate}
          type="date"
          onChange={e => setDeliveryDate(e.target.value)}
        />
        <MylocTextField
          required
          className={classes.time}
          id="new-time"
          label="tid"
          value={deliveryTime}
          type="time"
          onChange={e => setDeliveryTime(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>

      <Button type="submit" variant="contained" color="primary">
        Beställ
      </Button>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    session: state.session,
    installation: state.installation,
  };
};

const mapDispatchToProps = {
  setError: errorActionCreators.setError,
  setMessage: messageActionCreators.setMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderStorage);
