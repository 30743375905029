import pages from "./pages";

const _MY_ADS = [
  { label: "Alla", url: pages.MY_ADS },
  { label: "Utkast", url: pages.MY_ADS_DRAFT },
  { label: "Publicerade", url: pages.MY_ADS_PUBLISHED },
  //{ label: "Publicera senare", url: pages.MY_ADS_PUBLISH_LATE },
  { label: "Bokade", url: pages.MY_ADS_BOOKED },
  { label: "Förmedlade", url: pages.MY_ADS_MEDIATED },
];

const _MY_BARGAINS = [
  { label: "Alla", url: pages.MY_BARGAINS },
  { label: "Bokade av mig", url: pages.MY_BARGAINS_BOOKED },
  { label: "Förmedlade till mig", url: pages.MY_BARGAINS_MEDIATED },
];

const _PROFILE_LOGGED_IN = [
  { label: "Ändra profil", url: pages.USER_PROFILE },
  { label: "Logistikinställningar", url: pages.TRANSPORT_SETTINGS, lpOnly: true },
  { label: "Logga ut", url: pages.LOGOUT },
];

const _PROFILE_LOGGED_OUT = [{ label: "Logga in", url: pages.LOGIN }];

const adStatus = Object.freeze({
  MY_ADS: _MY_ADS,
  MY_BARGAINS: _MY_BARGAINS,
  PROFILE_LOGGED_IN: _PROFILE_LOGGED_IN,
  PROFILE_LOGGED_OUT: _PROFILE_LOGGED_OUT,
});

export default adStatus;
