/* eslint-disable react/prop-types */
import React from "react";
import { VictoryBar, VictoryChart, VictoryLabel, VictoryAxis } from "victory";

const BarChart = props => {
  const { chart } = props;
  const { data } = chart;
  const barData = [];
  data.forEach(datum => {
    barData.push({ x: datum.name, y: Number(datum.value), color: datum.color, label: datum.value });
  });

  return (
    <div>
      <VictoryChart>
        <VictoryAxis
          style={{
            axis: { stroke: "white" },
            tickLabels: { fontSize: 20, padding: 5 },
          }}
        ></VictoryAxis>
        <VictoryBar
          data={barData}
          labelComponent={<VictoryLabel style={{ fontSize: 22 }} dy={-5} />}
          //barWidth=
          style={{
            data: { fill: ({ datum }) => datum.color },
          }}
        ></VictoryBar>
      </VictoryChart>
    </div>
  );
};

export default BarChart;
