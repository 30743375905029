/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: 25,
  },
  primaryButton: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.white,
  },
}));

const RoundedButton = props => {
  const { className, primary, ...rest } = props;
  const classes = useStyles();
  let classNames = `${classes.button}`;

  if (primary) {
    classNames = `${classNames} ${classes.primaryButton}`;
  }
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  return (
    <Button variant="contained" className={classNames} color={primary ? "primary" : "default"} {...rest}>
      {props.children}
    </Button>
  );
};

export default RoundedButton;
