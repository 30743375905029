/* eslint-disable react/prop-types */
import React from "react";
import { connect, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { List, ListItem, ListItemText, Collapse } from "@material-ui/core";
//import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import useToggle from "../hooks/useToggle";
import * as Constants from "../constants";
import pages from "../utils/pages";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textTransform: "uppercase",
    padding: theme.spacing(1),
  },
  item: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MainMenu = props => {
  let history = useHistory();
  const classes = useStyles();
  //const { t } = useTranslation();
  const [myAdsOpen, toggleMyAdsOpen] = useToggle(false);
  const [myDealsOpen, toggleMyDealsOpen] = useToggle(false);
  const user = useSelector(state => state.user.user);

  const login = () => {
    history.push("/login");
  };

  const logout = () => {
    history.push("/logout");
  };

  const linksToPartners = () => {
    history.push("/links");
  };

  const howItWorks = () => {
    history.push("/how-it-works");
  };

  const instructionVideos = () => {
    history.push("/instruction-videos");
  };

  const linkToProfile = () => {
    history.push(pages.USER_PROFILE);
  };

  const linkToAds = (adType, status) => {
    let url = "";
    if (adType === Constants.adTypes.MY_ADS) {
      url = "/myAds";
    }
    if (adType === Constants.adTypes.MY_BARGAINS) {
      url = "/myBargains";
    }
    if (status) {
      url = url.concat(`?statusFrom=${status}&statusTo=${status}`);
    }
    history.push(url);
  };

  const userIsLP = user?.company?.lp;

  let myAdsLabel = "Mina annonser";
  if (userIsLP) {
    myAdsLabel = "Våra annonser";
  }

  const menuItems = [
    { id: 0, label: "Profil", clickHandler: linkToProfile, onlyLoggedIn: true },
    {
      id: 1,
      label: myAdsLabel,
      onlyLoggedIn: true,
      clickHandler: toggleMyAdsOpen,
      isOpen: myAdsOpen,
      subMenus: [
        { label: "Alla", clickHandler: () => linkToAds(Constants.adTypes.MY_ADS) },
        { label: "Utkast", clickHandler: () => linkToAds(Constants.adTypes.MY_ADS, Constants.adStatus.DRAFT) },
        {
          label: "Publicerade",
          clickHandler: () => linkToAds(Constants.adTypes.MY_ADS, Constants.adStatus.PUBLISHED),
        },
        {
          label: "Publiceras senare",
          clickHandler: () => undefined, //linkToAds(Constants.adTypes.MY_ADS, Constants.adSubTypes.PUBLISH_LATER),
        },
        { label: "Bokade", clickHandler: () => linkToAds(Constants.adTypes.MY_ADS, Constants.adStatus.BOOKED) },
        { label: "Förmedlade", clickHandler: () => linkToAds(Constants.adTypes.MY_ADS, Constants.adStatus.MEDIATED) },
      ],
    },
    {
      id: 2,
      label: "Mina fynd",
      onlyLoggedIn: true,
      notLogisticsCompany: true,
      clickHandler: toggleMyDealsOpen,
      isOpen: myDealsOpen,
      subMenus: [
        { label: "Alla", clickHandler: () => linkToAds(Constants.adTypes.MY_BARGAINS) },
        {
          label: "Bokade av mig",
          clickHandler: () => linkToAds(Constants.adTypes.MY_BARGAINS, Constants.adStatus.BOOKED),
        },
        {
          label: "Förmedlade till mig",
          clickHandler: () => linkToAds(Constants.adTypes.MY_BARGAINS, Constants.adStatus.MEDIATED),
        },
      ],
    },
    //{ id: 3, label: "Bevakningar" },
    //{ id: 4, label: "Meddelanden" },
    //{ id: 5, label: "Beställ transport" },
    { id: 6, label: "Länkar till partners", clickHandler: linksToPartners },
    //{ id: 7, label: 'Vad är "myloc dela"?' },
    { id: 7, label: "Så fungerar det!", clickHandler: howItWorks },
    { id: 8, label: "Instruktionsfilmer", clickHandler: instructionVideos },
    props.session.loggedIn
      ? { id: 9, label: "Logga ut", clickHandler: logout }
      : { id: 9, label: "Logga in", clickHandler: login },
  ];

  return (
    <>
      <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
        {menuItems.map(item => {
          if (item.notLogisticsCompany && userIsLP) {
            return null;
          }
          if (item.onlyLoggedIn && !props.session.loggedIn) {
            return null;
          }
          let clickHandler = undefined;
          if (item.clickHandler) {
            clickHandler = item.clickHandler;
          }
          return (
            <div key={item.id}>
              <ListItem className={classes.item} button>
                <ListItemText primary={item.label} onClick={clickHandler} />
              </ListItem>
              {item.subMenus ? (
                <Collapse in={item.isOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.subMenus.map((subItem, index) => {
                      let subClickHandler = undefined;
                      if (subItem.clickHandler) {
                        subClickHandler = subItem.clickHandler;
                      }
                      return (
                        <ListItem key={index} button className={classes.nested}>
                          <ListItemText primary={subItem.label} onClick={subClickHandler} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              ) : null}
            </div>
          );
        })}
      </List>
    </>
  );
};
const mapStateToProps = state => {
  return {
    session: state.session,
  };
};
export default connect(mapStateToProps)(MainMenu);
