/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
//import { useHistory } from "react-router-dom";
import MylocTextField from "../myloc-components/MylocTextField";
import accountServices from "../services/accountServices";
import services from "../services/services";
import { connect, useSelector } from "react-redux";
import * as commonActionCreators from "../reducers/commonReducer";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textTransform: "uppercase",
    padding: theme.spacing(1),
  },

  root: {
    "margin-top": theme.spacing(4),
  },

  media: {
    height: 300,
  },
}));

const Apply = props => {
  //let history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");

  const network = useSelector(state => state.network.network);

  useEffect(() => {});

  const registerUser = async evt => {
    evt.preventDefault();

    const data = { network: network, email: email };

    //New way of posting from pages. Response will be handled in response handler in account services instead
    accountServices.handleRequest(services.REGISTER, data, props.setRegistrationSuccess);
  };

  const handleChange = event => {
    setEmail(event.target.value);
  };

  return (
    <>
      <Typography variant="h5" fullWidth className={classes.heading}>
        {t("Registrera nytt konto")}
      </Typography>
      {!props.registrationSuccess ? (
        <div>
          <form onSubmit={registerUser}>
            <MylocTextField
              fullWidth
              required
              id="new-email-address"
              label={t("Email")}
              defaultValue=""
              onChange={handleChange}
            />
            <Button type="submit" style={{ borderRadius: 25 }} color="primary" variant="contained">
              {t("Register")}
            </Button>
          </form>
        </div>
      ) : (
        <Card className={classes.root}>
          <CardMedia className={classes.media} image="/images/giver.jpg" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Tack för att du registrerat dig
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Ett mail har skickats till {email}. Kontrollera e-posten och klicka på länken i mailet för att slutföra
              registreringen
            </Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    e: state.e,
    registrationSuccess: state.common.registrationSuccess,
  };
};

const mapDispatchToProps = {
  setRegistrationSuccess: commonActionCreators.setRegistrationSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Apply);
