import adListView from "../utils/adListView";

const initialState = {
  adListView: adListView.GRID,
  searchString: "",
  selectedCategory: "",
  selectedCompanies: [],
  savedScrollPositions: [],
};

const settingReducer = (state = initialState, action) => {
  let newSelectedCompanies = "";
  switch (action.type) {
    case SET_GRID_VIEW:
      return {
        ...state,
        adListView: adListView.GRID,
      };
    case SET_LIST_VIEW:
      return {
        ...state,
        adListView: adListView.LIST,
      };
    case SET_SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    case TOGGLE_SELECTED_COMPANY:
      newSelectedCompanies = [...state.selectedCompanies];
      if (newSelectedCompanies.indexOf(action.selectedCompany) > -1) {
        newSelectedCompanies.splice(newSelectedCompanies.indexOf(action.selectedCompany), 1);
      } else {
        newSelectedCompanies.push(action.selectedCompany);
      }
      return {
        ...state,
        selectedCompanies: newSelectedCompanies,
      };
    case SAVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.concat({ key: action.key, position: action.position }),
      };
    case REMOVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.filter(scrollPosition => scrollPosition.key !== action.key),
      };
    case CLEAR_SELECTED_COMPANIES:
      return {
        ...state,
        selectedCompanies: [],
      };
    default:
      return state;
  }
};

//Actions
const SET_GRID_VIEW = "SET_GRID_VIEW";
const SET_LIST_VIEW = "SET_LIST_VIEW";
const SET_SEARCH_STRING = "SET_SEARCH_STRING";
const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY";
const TOGGLE_SELECTED_COMPANY = "TOGGLE_SELECTED_COMPANY";
const CLEAR_SELECTED_COMPANIES = "CLEAR_SELECTED_COMPANIES";
const SAVE_SCROLL_POSITION = "SAVE_SCROLL_POSITION";
const REMOVE_SCROLL_POSITION = "REMOVE_SCROLL_POSITION";

// Action creators
export function setGridView() {
  return { type: SET_GRID_VIEW };
}

export function setListView() {
  return { type: SET_LIST_VIEW };
}

export function setSearchString(searchString) {
  return { type: SET_SEARCH_STRING, searchString };
}

export function setSelectedCategory(selectedCategory) {
  return { type: SET_SELECTED_CATEGORY, selectedCategory };
}

export function toggleSelectedCompany(selectedCompany) {
  return { type: TOGGLE_SELECTED_COMPANY, selectedCompany };
}

export function clearSelectedCompanies() {
  return { type: CLEAR_SELECTED_COMPANIES };
}

export function saveScrollPosition(key, x, y) {
  return { type: SAVE_SCROLL_POSITION, key: key, position: { x: x, y: y } };
}

export function removeScrollPosition(key) {
  return { type: REMOVE_SCROLL_POSITION, key: key };
}

export default settingReducer;
