import { getRequest, sendRequest } from "../utils/restUtil";
import services from "./services";
import store from "../store";
import { setSessionId, setLoggedIn } from "../reducers/sessionReducer";

//const baseUrl = "https://dela-dev.myloc.se/MyL/rs/circAc/account";
const baseUrl = `${process.env.REACT_APP_API_URL}/circAc/account`;

//Pass in an optional callback function as "postHandlingCallback", can be as an array of functions. It/They will he handled if call is successful"
const handleRequest = async (service, data, postHandlingCallback, postResponseCallback) => {
  try {
    const responseData = await sendRequest(service, data, postHandlingCallback);

    //Show service success result - if there is one
    _dispatchSuccessMessage(service);

    //Handle success result. Errorhandler handles if not
    _handleResponse(service, responseData);
    if (postResponseCallback) {
      postResponseCallback(responseData);
    }
  } catch (e) {
    console.log(
      "Error ocurred in _handlerRequest, but should have been taken care of in ErrorHandler: " + e.message,
      e,
    );
  }
};
const _handleResponse = (service, data) => {
  switch (service) {
    case services.LOGIN:
      _handleLoginResponse(data);
      break;
    case services.GET_USER_INFO:
      _handleGetUserInfoResponse(data);
      break;
    case services.REGISTER:
      //_handleRegisterResponse(data);
      break;
    case services.REQUEST_RESET_PASSWORD:
      _handleRequestResetPasswordResponse();
      break;
    case services.RESET_PASSWORD:
      _handleResetPasswordResponse();
      break;
    case services.CREATE:
      _handleCreateAccountResponse();
      break;
    default:
      return;
  }
};

const _dispatchSuccessMessage = service => {
  if (service.successMessage) {
    const dataToDispatch = {
      type: "SET_MESSAGE",
      message: service.successMessage,
    };
    store.dispatch(dataToDispatch);
  }
};

const _handleRequestResetPasswordResponse = () => {
  const dataToDispatch = {
    type: "SET_MESSAGE",
    message: "Instruktioner för att slutföra återställningen skickades till din e-postadress",
  };
  store.dispatch(dataToDispatch);
};

const _handleResetPasswordResponse = () => {
  const dataToDispatch = {
    type: "SET_MESSAGE",
    message: "Ditt lösenord har ändrats",
  };
  store.dispatch(dataToDispatch);
};

const _handleCreateAccountResponse = () => {
  const dataToDispatch = {
    type: "SET_MESSAGE",
    message: "Ditt konto är skapat. Logga in med e-post och lösenord för att börja använda tjänsten.",
  };
  store.dispatch(dataToDispatch);
};

const _handleGetUserInfoResponse = data => {
  store.dispatch({ type: "INIT_USER", user: data });
};

const _handleLoginResponse = data => {
  //TODO
  if (!data && !data.sessionId) {
    return;
  }

  let dataToDispatch = setSessionId(data.sessionId);
  store.dispatch(dataToDispatch);

  dataToDispatch = setLoggedIn(true);
  store.dispatch(dataToDispatch);
};
// POST /register
const register = async newObject => {
  return await sendRequest(`${baseUrl}/register`, newObject);
};

// POST /login
const login = user => {
  return sendRequest(`${baseUrl}/login`, user);
};

const getUserInfo = sessionId => {
  return getRequest(`${baseUrl}/user?id=${sessionId}`);
};

// GET /register
const getInfo = id => {
  return getRequest(`${baseUrl}/register?accountid=${id}`);
};

// POST /create
const create = (id, newAccount) => {
  return sendRequest(`${baseUrl}/create?accountid=${id}`, newAccount);
};

// GET /key
const getKey = (type, id) => {
  return getRequest(`${baseUrl}/key?${type}=${id}`);
};

//Pass in an optional callback function as "postHandlingCallback". It will he executed if call is successful, i e redirection"
const post = (service, data, postHandlingCallback) => {
  handleRequest(service, data, postHandlingCallback);
};

//Pass in an optional callback function as "postHandlingCallback" - or an array if multiple functions. It will he executed if call is successful, i e redirection"
/*
const handleRequest = (service, data, postHandlingCallback, postResponseCallback) => {
  _handleRequest(service, data, postHandlingCallback, postResponseCallback);
};
*/
export default { register, login, create, getInfo, getKey, getUserInfo, post, handleRequest };
