import React from "react";
// Maybe change to BrowserRouter later
import { MylocThemeProvider } from "./providers/theme";
//import { useTranslation } from "react-i18next";
import store from "./store.js";
import MylocInit from "./myloc-components/MylocInit";

// redux
import { Provider } from "react-redux";
import NavigationContent from "./components/NavigationContent";

const App = () => {
  return (
    <MylocThemeProvider>
      <Provider store={store}>
        <MylocInit></MylocInit>
        <NavigationContent />
      </Provider>
    </MylocThemeProvider>
  );
};

export default App;
