/* eslint-disable react/prop-types */
import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Container } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: fade(theme.palette.primary.light, 0.5),
  },
  linkButton: {
    textDecoration: "none",
    color: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.9)",
    },
    alignSelf: "flex-end",
    marginLeft: theme.spacing(3),
    whiteSpace: "nowrap",
  },
}));

const MylocMenuIcon = () => {
  let history = useHistory();
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Button className={classes.linkButton} onClick={() => history.push("/how-it-works")}>
          Så fungerar det!
        </Button>
        <Button className={classes.linkButton} onClick={() => history.push("/instruction-videos")}>
          Instruktionsfilmer
        </Button>
        <Button className={classes.linkButton} onClick={() => history.push("/links")}>
          Länkar till partners
        </Button>
      </Container>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps)(MylocMenuIcon);
