export const adTypes = {
  MY_ADS: "myAds",
  MY_BARGAINS: "myBargains",
  SEARCH: "search",
};

export const adSubTypes = {
  ALL: "all",
  DRAFTS: "drafts",
  PUBLISHED: "published",
  PUBLISH_LATER: "publishLater",
  BOOKED: "booked",
  MEDIATED: "mediated",
  BOOKED_BY_ME: "bookedByMe",
  MEDIATED_TO_ME: "mediatedToMe",
};

export const adStatus = {
  DRAFT: 10,
  PUBLISHED: 20,
  BOOKED: 40,
  MEDIATED: 90,
};

export const statusHeadings = {
  "10": "Utkast",
  "20": "Publicerade",
  "40": "Bokade",
  "90": "Förmedlade",
};
