/* eslint react/prop-types: 0 */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Button,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  emptyBookings: {
    padding: theme.spacing(2),
  },
}));

const headings = ["Bokad av", "Bokades", "Antal", ""];

const AdBookingInfo = props => {
  const classes = useStyles();
  const { ad } = props;

  return ad ? (
    ad.bookings.length === 0 ? (
      <Typography className={classes.emptyBookings}>Det finns inga bokningar ännu.</Typography>
    ) : (
      <div>
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {headings.map((heading, index) => (
                  <TableCell key={index}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {ad.bookings.map((booking, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {booking.bookedBy.firstName} {booking.bookedBy.surname} ({booking.bookedBy.email})
                  </TableCell>
                  <TableCell>{booking.bookedDate}</TableCell>
                  <TableCell>{booking.numberOfItems}</TableCell>
                  {Number(booking.status.id) === 90 ? (
                    <TableCell>Förmedlad</TableCell>
                  ) : (
                    <TableCell>
                      <Button onClick={() => props.handleUnbookAd(booking.bookedBy.userId)} color="primary">
                        Avboka
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  ) : null;
};

export default AdBookingInfo;
