import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "./i18n";
import { withStyles } from "@material-ui/core/styles";
import * as serviceWorker from "./serviceWorker";

const styles = theme => ({
  "@global": {
    // MUI typography elements use REMs, so you can scale the global
    // font size by setting the font-size on the <html> element.
    html: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 13,
      },
    },
  },
});

const AppWithStyle = withStyles(styles)(App);
ReactDOM.render(<AppWithStyle />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
