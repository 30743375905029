/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import adServices from "../services/adServices";

const useStyles = makeStyles(() => ({
  logo: {
    position: props => (props.relative ? "relative" : "absolute"),
    top: props => (props.relative ? 15 : 5),
    right: props => (props.relative ? 15 : 5),
    width: "unset",
  },
}));

const CompanyLogo = props => {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    adServices.getAdLogo(props.companyGroup).then(response => {
      setLogo(`${process.env.REACT_APP_COMPANY_LOGOS}${response}`);
    });
  }, [props.companyGroup]);

  const classes = useStyles(props);

  return (
    <Avatar
      className={classes.logo}
      style={{ border: 0, objectFit: "cover" }}
      variant="square"
      alt={props.companyGroup}
      src={`${logo}`}
    />
  );
};

export default CompanyLogo;
