/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useEffect, useState } from "react";
import services from "../services/services";

import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@material-ui/core";
import * as dialogActionCreators from "../reducers/dialogReducer";

import { useDispatch, useSelector } from "react-redux";
import MylocSelect from "../myloc-components/MylocSelect";
import accountServices from "../services/accountServices";
import pages from "../utils/pages";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

const NetworkNotSelectedDialog = ({ toPage }) => {
  const dispatch = useDispatch();
  const dialog = useSelector(state => state.dialog);
  const { t } = useTranslation();

  const [networks, setNetworks] = useState();
  const [network, setNetwork] = useState();

  let history = useHistory();

  const getNetworks = () => {
    accountServices.handleRequest(services.NETWORKS, null, setNetworks);
  };
  const handleNetworkSelect = event => {
    const network = event.target.value;

    //Set locally
    setNetwork(network);
    //And dispatch globally
    dispatch(dialogActionCreators.setNetworkNotSelectedClosed());

    history.push(toPage ? toPage : pages.toNetwork(network));
  };

  useEffect(() => {
    if (!networks) {
      getNetworks();
    }
  }, [networks]);

  return (
    <Dialog open={dialog.networkNotSelected.open}>
      <DialogTitle id="form-dialog-book">Välj Marknad</DialogTitle>
      <DialogContent>
        <Typography>{dialog.notLoggedIn.message}</Typography>
        <MylocSelect
          items={networks}
          selectedItem={network}
          itemValueField={"id"}
          itemKey={"id"}
          title={t("SELECT_TRIPLE_DOTS")}
          handleSelect={handleNetworkSelect}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => dispatch(dialogActionCreators.setNetworkNotSelectedClosed())}>
          Stäng
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NetworkNotSelectedDialog;
