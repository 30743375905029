/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalImage from "react-modal-image";

const useStyles = makeStyles(() => ({
  imageContainer: {
    width: props => props.width,
    height: props => props.height,
    lineHeight: props => props.height,
    textAlign: "center",
    backgroundColor: "lightGray",
  },
  image: {
    verticalAlign: "middle",
  },
}));

const ImageContainer = props => {
  const classes = useStyles(props);
  return (
    <ModalImage
      imageContainer={classes.imageContainer}
      imageCss={classes.image}
      className={classes.image}
      small={props.small}
      large={props.large}
      alt={props.alt}
    />
  );
};

export default ImageContainer;
