/* eslint-disable react/prop-types */
// React/javascript libraries
import React, { useState } from "react";
import { connect } from "react-redux";
//import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import { compose } from "recompose";
import queryString from "query-string";

// Material ui
import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
// Myloc imports
import MylocTextField from "../myloc-components/MylocTextField";
import MylocErrorMessage from "../myloc-components/MylocErrorMessage";
import accountServices from "../services/accountServices";
import * as userAction from "../reducers/userReducer";
import pages from "../utils/pages";
import * as errorActionCreators from "../reducers/errorReducer";

import services from "../services/services";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  rememberMeCheckBox: {
    alignSelf: "center",
    marginTop: theme.spacing(3),
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  link: {
    alignSelf: "center",
  },
}));

const ResetPassword = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [showError, setShowError] = useState(false);
  const [errorMessage] = useState("");
  const [secondPassword, setSecondPassword] = useState("");

  const queryStringValues = queryString.parse(location.search);

  const updateEmail = event => {
    setEmail(event.target.value);
  };

  const handleResetInitiate = e => {
    e.preventDefault();
    accountServices.handleRequest(services.REQUEST_RESET_PASSWORD, email, () => history.push(pages.LOGIN));
  };

  const handleResetPassword = (e, password, resetToken) => {
    e.preventDefault();

    if (password !== secondPassword) {
      props.setError({ errorMessage: "Lösenorden överensstämmer inte", error: "" });
      return;
    }

    const data = {
      resetToken: resetToken,
      newPassword: password,
      newPasswordRepeat: password,
    };
    accountServices.handleRequest(services.RESET_PASSWORD, data, () => history.push(pages.LOGIN));
  };
  return (
    <>
      {queryStringValues.resettoken ? (
        <form
          onSubmit={e => {
            handleResetPassword(e, props.user.password, queryStringValues.resettoken);
          }}
        >
          <Container className={classes.container} maxWidth="sm">
            <Typography variant="h5" align="center">
              Ange ditt nya lösenord
            </Typography>

            <MylocTextField
              fullWidth
              required
              label={t("Password")}
              type="password"
              onChange={e => props.setPassword(e.target.value)}
            />
            <MylocTextField
              fullWidth
              required
              label="Ange lösenordet igen"
              type="password"
              onChange={e => setSecondPassword(e.target.value)}
            />
            <Button type="submit" style={{ borderRadius: 25 }} color="primary" variant="contained">
              Spara lösenord
            </Button>
          </Container>
        </form>
      ) : (
        <form onSubmit={handleResetInitiate}>
          <Container className={classes.container} maxWidth="sm">
            <Typography variant="h5" align="center">
              Återställ lösenord
            </Typography>

            <MylocTextField
              fullWidth
              required
              id="new-email-address"
              label={t("Email")}
              defaultValue=""
              onChange={updateEmail}
            />
            <Button type="submit" style={{ borderRadius: 25 }} color="primary" variant="contained">
              Återställ
            </Button>
          </Container>
        </form>
      )}
      <MylocErrorMessage showError={showError} setShowError={setShowError} errorMessage={errorMessage} />
    </>
  );
};

const mapDispatchToProps = {
  setPassword: userAction.setPassword,
  setError: errorActionCreators.setError,
};

const mapStateToProps = state => {
  return {
    session: state.session,
    user: state.user,
  };
};

//const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));
//export default enhance(ResetPassword);
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
