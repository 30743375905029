/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import { withRouter } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
//import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";

import { useTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";

import pages from "../utils/pages";
import * as dialogActionCreators from "../reducers/dialogReducer";

const useStyles = makeStyles(theme => ({
  bottomNav: {
    position: "fixed",
    left: "0px",
    bottom: "0px",
    height: "50px",
    width: "100%",
    borderTop: `1px solid ${theme.palette.primary.main}`,
    zIndex: 2,
  },
}));

const Footer = props => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  const classes = useStyles();
  const network = useSelector(state => state.network.network);

  const dispatch = useDispatch();

  const setTab = value => {
    switch (value) {
      case 0:
        props.history.push("/");
        break;
      case 1:
        if (network) {
          props.history.push(pages.toNetworkADS(network));
        } else {
          dispatch(dialogActionCreators.setNetworkNotSelectedOpen());
        }
        break;
      case 2:
        if (!props.session.loggedIn) {
          props.setNotLoggedInOpen(true, "Du behöver vara inloggad för att skapa en ny annons.");
        } else {
          props.history.push(pages.AD_CREATE);
        }
        break;
      case 3:
        if (props.session.loggedIn) {
          props.history.push(pages.USER_PROFILE);
        } else {
          props.history.push(pages.LOGIN);
        }
        break;
      case 4:
        props.history.push("/menu");
        break;
      default:
        // Do nothing
        break;
    }
  };

  return props.location.pathname !== "/login" ? (
    <div className={classes.toolbar}>
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          setTab(newValue);
        }}
        showLabels
        className={classes.bottomNav}
      >
        <BottomNavigationAction label={t("Home")} icon={<HomeIcon />} />
        <BottomNavigationAction label={t("Search")} icon={<SearchIcon />} />
        <BottomNavigationAction style={{ whiteSpace: "nowrap" }} label={t("New ad")} icon={<AddCircleOutlineIcon />} />
        {props.session.loggedIn ? (
          <BottomNavigationAction label={t("Profil")} icon={<AccountCircleIcon />} />
        ) : (
          <BottomNavigationAction label={t("Logga in")} icon={<AccountCircleIcon />} />
        )}
        {/*<BottomNavigationAction label={t("Message")} icon={<ChatBubbleOutlineIcon />} />*/}
        <BottomNavigationAction label={t("Menu")} icon={<MenuIcon />} />
      </BottomNavigation>
    </div>
  ) : null;
};

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = {
  setNotLoggedInOpen: dialogActionCreators.setNotLoggedInOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
