const initialState = {
  error: "",
  errorMessage: "",
};

// Actions
const SET_ERROR = "SET_ERROR";

export default function errorReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}

// Action creators
export function setError(e) {
  return { type: SET_ERROR, error: e.error, errorMessage: e.errorMessage };
}
