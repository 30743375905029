// Initialized in store but in needed to be here too...

const initialState = {
  loading: false,
  loadingFreeze: false,
  registrationSuccess: false,
  registrationCompleted: false,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };
    case SET_LOADING_FREEZE:
      return {
        ...state,
        loadingFreeze: action.loadingFreeze,
      };
    case SET_REGISTRATION_SUCCESS:
      return {
        ...state,
        registrationSuccess: true,
      };

    case SET_REGISTRATION_COMPLETED:
      return {
        ...state,
        registrationCompleted: true,
      };
    case SET_REGISTRATION_RESET:
      return {
        ...state,
        registrationCompleted: false,
        registrationSuccess: false,
      };
    default:
      return state;
  }
};

//Actions
const SET_LOADING = "SET_LOADING";
const SET_LOADING_FREEZE = "SET_LOADING_FREEZE";
const SET_REGISTRATION_SUCCESS = "SET_REGISTRATION_SUCCESS";
const SET_REGISTRATION_COMPLETED = "SET_REGISTRATION_COMPLETED";
const SET_REGISTRATION_RESET = "SET_REGISTRATION_RESET";

// Action creators
export function setLoading(isLoading) {
  return { type: SET_LOADING, isLoading };
}

export function setLoadingFreeze(isLoadingFreeze) {
  return { type: SET_LOADING_FREEZE, isLoadingFreeze };
}

export function setRegistrationSuccess() {
  return { type: SET_REGISTRATION_SUCCESS };
}

export function setRegistrationCompleted() {
  return { type: SET_REGISTRATION_COMPLETED };
}

export function setRegistrationReset() {
  return { type: SET_REGISTRATION_RESET };
}

export default commonReducer;
