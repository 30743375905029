/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(2),
    textAlign: "center",
  },
  rating: {
    fontSize: 48,
  },
}));

const StarRating = props => {
  const classes = useStyles();
  const [hover, setHover] = React.useState(-1);
  const selectedRating = props.ratings.find(rating => {
    if (hover !== -1) {
      return rating.stars === hover;
    } else {
      return rating.stars === props.value;
    }
  });

  return (
    <div className={classes.root}>
      <Rating
        className={classes.rating}
        name="hover-feedback"
        value={props.value}
        onChange={(event, newValue) => {
          props.setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
      />
      <Typography align="center" variant="h5">
        {selectedRating && props.value !== null ? selectedRating.name : " Inget skick valt "}
      </Typography>
      <Typography align="center" variant="h5">
        {selectedRating && props.value !== null ? selectedRating.description : " - "}
      </Typography>
    </div>
  );
};

export default StarRating;
