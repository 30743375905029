/* eslint-disable react/prop-types */
import React, { Fragment, useState, useEffect } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  Box,
  Card,
  Typography,
  ButtonBase,
} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import fixRotation from "fix-image-rotation";
import MylocFormikTextField from "../myloc-components/MylocFormikTextField";
import MylocDropZone from "../myloc-components/MylocDropZone";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import DetailSegment from "./DetailSegmentWithHeader";
import RoundedButton from "../components/RoundedButton";
import StarRating from "../components/StarRating";
import * as Yup from "yup";
import * as errorActionCreators from "../reducers/errorReducer";

const useStyles = makeStyles(theme => ({
  starLine: {
    display: "flex",
    alignContent: "center",
  },

  date: {
    margin: theme.spacing(3),
  },
  time: {
    margin: theme.spacing(3),
  },
  button: {},
  cardContainer: {
    display: "flex",
  },
  cardDiv: {
    position: "relative",
  },
  card: {
    width: "100px",
    height: "100px",
    border: "grey 1px solid",
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
  },
  selectedCard: {
    border: `${theme.palette.primary.dark} 3px solid`,
  },
  media: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  removeImageButton: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    zIndex: 10,
  },
  successTitle: {
    textTransform: "uppercase",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AdSchema = Yup.object().shape({
  category: Yup.string().required("Kategori måste anges"),
  title: Yup.string().required("Rubrik måste anges"),
  numberOfItems: Yup.number()
    .typeError("Felaktigt värde")
    .min(1, "Måste vara minst 1"),
  height: Yup.number()
    .typeError("Felaktigt värde")
    .min(0, "Måste vara positivt"),
  length: Yup.number()
    .typeError("Felaktigt värde")
    .min(0, "Måste vara positivt"),
  width: Yup.number()
    .typeError("Felaktigt värde")
    .min(0, "Måste vara positivt"),
  depth: Yup.number()
    .typeError("Felaktigt värde")
    .min(0, "Måste vara positivt"),
  weight: Yup.number()
    .typeError("Felaktigt värde")
    .min(0, "Måste vara positivt"),
  addressRow1: Yup.string().required("Adress måste anges"),
  postalNo: Yup.string()
    .max(10, "Max 10 tecken")
    .required("Postnummer måste anges"),
  city: Yup.string().required("Ort måste anges"),
});

const quality = [
  {
    id: 0,
    stars: 5,
    name: `"Sprillans ny"`,
    description: "oanvänd",
  },
  {
    id: 1,
    stars: 4,
    name: `"Som ny"`,
    description: "ser oanvänd ut, inga brister",
  },
  {
    id: 2,
    stars: 3,
    name: `"Gott skick"`,
    description: "varsamt använd, fåtal brister men funktionsduglig",
  },
  {
    id: 3,
    stars: 2,
    name: `"Okej"`,
    description: "använd, flertal brister men funktionsduglig",
  },
  {
    id: 4,
    stars: 1,
    name: `"Behöver kärlek"`,
    description: "flitigt använd, stora brister, ej fullt funktionell",
  },
];

function AdDetailsForm(props) {
  const { categories, ad, buttons, pictures, setPictures, rating, setRating } = props;
  const theme = useTheme();
  const classes = useStyles(theme);
  let clickedButton = undefined;

  const [open, setOpen] = useState(false);
  //const [ratingError, setRatingError] = useState(false);
  const [picturesToShow, setPicturesToShow] = useState([]);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (!ad?.pickup?.addressRow1) {
      ad.pickup = user?.address;
    }
  }, [ad, user]);

  useEffect(() => {
    const newPicturesToShow = [];
    pictures.forEach(picture => {
      if (!picture.remove) {
        newPicturesToShow.push(picture);
      }
    });
    while (newPicturesToShow.length < 6) {
      newPicturesToShow.push({ new: true });
    }
    setPicturesToShow(newPicturesToShow);
  }, [pictures]);

  const openDialog = () => {
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const handleSetMainImage = selectedPicture => {
    const newPictures = pictures.map(picture => {
      return picture.id === selectedPicture.id ? { ...picture, mainImage: true } : { ...picture, mainImage: false };
    });
    setPictures(newPictures);
  };

  const handleRemovePicture = pictureToRemove => {
    let newPictures = [];
    if (!pictureToRemove.isStored) {
      newPictures = pictures.filter(picture => {
        return pictureToRemove.id !== picture.id;
      });
    } else {
      newPictures = pictures.map(picture => {
        return picture.id !== pictureToRemove.id ? picture : { ...picture, remove: true, mainImage: false };
      });
    }
    if (pictureToRemove.mainImage && newPictures.length > 0) {
      for (let i = newPictures.length - 1; i >= 0; i--) {
        if (!newPictures[i].remove) {
          newPictures[i].mainImage = true;
          break;
        }
      }
    }
    setPictures(newPictures);
  };

  const handlePhotoTaken = files => {
    let myRotationFunction = async function(ArrayOfFilesToBeRotated) {
      let blobOfArray = await fixRotation.fixRotation(ArrayOfFilesToBeRotated);
      return blobOfArray;
    };

    myRotationFunction(files).then(files => {
      const newPictures = pictures.filter(picture => !picture.empty);
      files.forEach(file => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const dataURL = reader.result;
          let nextId = 0;
          newPictures.forEach(picture => {
            nextId = Math.max(picture.id, nextId);
          });
          nextId++;
          let mainImage = false;
          if (!pictures.some(picture => picture.mainImage && !picture.remove)) {
            mainImage = true;
          }
          newPictures.push({
            dataURL: dataURL,
            remove: false,
            stored: false,
            empty: false,
            id: nextId,
            mainImage: mainImage,
          });
          setPictures(newPictures);
        };
        reader.readAsDataURL(file);
      });
    });
  };
  return ad ? (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={{
        category: ad.categoryId,
        title: ad.title,
        numberOfItems: ad.quantity.total,
        description: ad.description,
        width: ad.dimensions.width,
        height: ad.dimensions.height,
        length: ad.dimensions.length,
        depth: ad.dimensions.depth,
        weight: ad.dimensions.weight,
        qualityRank: ad.quality.rank,
        qualityText: ad.quality.description,
        addressRow1: ad.pickup?.addressRow1,
        addressRow2: ad.pickup?.addressRow2,
        postalNo: ad.pickup?.postalNo,
        city: ad.pickup?.city,
        pickupInformation: ad.pickupInformation,
      }}
      validate={async values => {
        //setRatingError(false);

        const errors = {};
        if (clickedButton && clickedButton.validate) {
          // validate for the schema need to be called manually because the validation is conditional on
          // clickedButton and rating need to be validated separately.
          await AdSchema.validate(values, { abortEarly: false })
            .then()
            .catch(function(err) {
              err.inner.forEach(error => {
                errors[error.path] = error.message;
              });
            });
          if (props.rating < 0) {
            errors.rating = true;
          }
        }

        if (Object.keys(errors).length !== 0) {
          props.setError({
            errorMessage: `Formuläret är inte rätt ifyllt. Se röda markeringar för detaljer`,
            error: "",
          });
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(false);
        clickedButton.onSubmit(values, clickedButton.saveAsDraft);
      }}
    >
      {({ submitForm, errors }) => (
        <Form>
          <DetailSegment headingText="Vad vill du skänka?">
            <MylocFormikTextField
              name="category"
              required
              fullWidth
              select
              label="Kategori"
              InputLabelProps={{ shrink: true }}
              SelectProps={{
                native: true,
              }}
            >
              <option value=""></option>
              {categories.map(category => {
                return (
                  <Fragment key={category.id}>
                    <option className={classes.category} value={category.id}>
                      {category.parentId ? `${category.title}` : `---- ${category.title} ----`}
                    </option>
                  </Fragment>
                );
              })}
            </MylocFormikTextField>
            <MylocFormikTextField fullWidth required name="title" label="Rubrik" />
            <MylocFormikTextField fullWidth required name="numberOfItems" label="Antal" />
            <MylocFormikTextField fullWidth name="description" label="Beskrivning" multiline />
            <IconButton onClick={openDialog}>
              <AddCircleOutlineIcon />
            </IconButton>
            <Button onClick={openDialog}>Lägg till mått och vikt</Button>
            {errors.width ||
              errors.height ||
              errors.weight ||
              errors.depth ||
              (errors.length && <Typography color="error">Fel i mått och vikt</Typography>)}
          </DetailSegment>
          <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Mått och vikt</DialogTitle>
            <DialogContent>
              <MylocFormikTextField
                name="width"
                error
                label="Bredd"
                InputProps={{
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                }}
              />
              <MylocFormikTextField
                name="length"
                label="Längd"
                InputProps={{
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                }}
              />
              <MylocFormikTextField
                name="height"
                label="Höjd"
                InputProps={{
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                }}
              />
              <MylocFormikTextField
                name="depth"
                label="Djup"
                InputProps={{
                  endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                }}
              />
              <MylocFormikTextField
                name="weight"
                label="Vikt"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialog} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <DetailSegment headingText="Hur är skicket?">
            {/*<Field component={RadioGroup} name="qualityRank">
              {quality.map(quality => {
                return (
                  <FormControlLabel
                    key={quality.id}
                    control={<Radio />}
                    value={quality.stars}
                    checked={Number(quality.stars) === Number(values.qualityRank)}
                    label={
                      <MylocStars
                        numStars={Number(quality.stars)}
                        text={`"${quality.name}" - ${quality.description}`}
                      />
                    }
                  />
                );
              })}
            </Field>*/}
            <Box display="flex" justifyContent="center">
              <StarRating ratings={quality} value={rating} setValue={setRating} />
            </Box>
            {errors.rating && <Typography color="error">Skick måste anges</Typography>}

            <MylocFormikTextField fullWidth name="qualityText" label="Bra att veta om skicket" multiline />
          </DetailSegment>
          <DetailSegment headingText="Vilka bilder vill du visa?">
            <Box display="flex" flexWrap="wrap">
              {picturesToShow.map((picture, index) => {
                let selected = "";
                if (picture.mainImage) {
                  selected = classes.selectedCard;
                }
                return picture && picture.new ? (
                  <div key={index} className={classes.card}>
                    <MylocDropZone onDrop={handlePhotoTaken} />
                  </div>
                ) : (
                  <div key={index} className={classes.cardDiv}>
                    <IconButton
                      className={classes.removeImageButton}
                      color="secondary"
                      aria-label="Remove picture"
                      onClick={() => handleRemovePicture(picture)}
                    >
                      <CancelIcon />
                    </IconButton>
                    <ButtonBase
                      disableRipple
                      onClick={() => {
                        handleSetMainImage(picture);
                      }}
                    >
                      <Card className={`${classes.card} ${selected}`} key={index}>
                        <img className={classes.media} src={picture.dataURL} alt={"no"} />
                      </Card>
                    </ButtonBase>
                  </div>
                );
              })}
            </Box>
          </DetailSegment>
          <DetailSegment headingText="Var finns objekten att hämta?">
            {user && (
              <>
                <MylocFormikTextField name="addressRow1" fullWidth required label="Adressrad 1" />
                <MylocFormikTextField name="addressRow2" fullWidth label="Adressrad 2" />
                <MylocFormikTextField name="postalNo" fullWidth required label="Postnummer" />
                <MylocFormikTextField name="city" fullWidth required label="Ort" />
                <MylocFormikTextField
                  fullWidth
                  name="pickupInformation"
                  label="Övrig hämtningsinfo"
                  //InputLabelProps={{ shrink: true }}
                  multiline
                />
              </>
            )}

            {buttons.map((button, index) => {
              return (
                <RoundedButton
                  key={index}
                  primary={button.primary}
                  onClick={() => {
                    clickedButton = button;
                    submitForm();
                  }}
                >
                  {button.label}
                </RoundedButton>
              );
            })}
          </DetailSegment>
        </Form>
      )}
    </Formik>
  ) : null;
}

const mapStateToProps = state => {
  return {
    ad: state.newAd,
    user: state.user,
  };
};
const mapDispatchToProps = {
  setError: errorActionCreators.setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdDetailsForm);
