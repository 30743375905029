import adStatus from "./adStatus";

const NETWORK_PARAM = ":network";

const AD_CREATE = "/ads/create";
const AD_EDIT = "/ads/:id/edit";
const AD_PUBLISH = "/ads/:id/publish";
const NETWORK_AD_SHOW = "/" + NETWORK_PARAM + "/ads/:id";
const AD_ORDER_STORAGE = "/" + NETWORK_PARAM + "/ads/:id/orderStorage";
const AD_ORDER_TRANSPORT = "/" + NETWORK_PARAM + "/ads/:id/orderTransport";
const APPLY = "/apply";
const HOME = "/";
const HOW_IT_WORKS = "/how-it-works";
const INSTRUCTION_VIDEOS = "/instruction-videos";
const LINKS = "/links";
const LOGIN = "/login";
const LOGOUT = "/logout";
const MENU = "/menu";
const MY_BARGAINS = "/myBargains";
const _MY_BARGAINS_BOOKED = `/myBargains?statusFrom=${adStatus.BOOKED}&statusTo=${adStatus.BOOKED}`;
const _MY_BARGAINS_MEDIATED = `/myBargains?statusFrom=${adStatus.MEDIATED}&statusTo=${adStatus.MEDIATED}`;
const MY_ADS = "/myAds";
const _MY_ADS_DRAFT = `/myAds?statusFrom=${adStatus.DRAFT}&statusTo=${adStatus.DRAFT}`;
const _MY_ADS_PUBLISHED = `/myAds?statusFrom=${adStatus.PUBLISHED}&statusTo=${adStatus.PUBLISHED}`;
const _MY_ADS_BOOKED = `/myAds?statusFrom=${adStatus.BOOKED}&statusTo=${adStatus.BOOKED}`;
const _MY_ADS_MEDIATED = `/myAds?statusFrom=${adStatus.MEDIATED}&statusTo=${adStatus.MEDIATED}`;
const MY_PAGE = "/myPage";
const NETWORK_ADS = "/" + NETWORK_PARAM + "/ads";
const NETWORK_HOME = "/" + NETWORK_PARAM;
const _RESET_PASSWORD = "/account/password";
const _USER_PROFILE = "/account/register";
const TRANSPORT_SETTINGS = "/company/transport-settings";
const WATCHES = "/watches";
const WATCHES_CREATE = "/watches/create";
const WATCHES_ID = "/watches/:id";

const toNetworkADS_CREATE = network => {
  const link = toNetworkADS(network) + "/create";
  return link;
};
const toNetworkADS = network => {
  const link = NETWORK_ADS.replace(NETWORK_PARAM, network);
  return link;
};
const toNetwork = network => {
  return NETWORK_HOME.replace(NETWORK_PARAM, network);
};

const toEditAd = id => {
  const link = `/ads/${id}/edit`;
  return link;
};

const pages = Object.freeze({
  AD_CREATE,
  AD_EDIT,
  AD_PUBLISH,
  AD_ORDER_STORAGE,
  AD_ORDER_TRANSPORT,
  APPLY,
  HOME,
  HOW_IT_WORKS,
  INSTRUCTION_VIDEOS,
  LINKS,
  LOGIN,
  LOGOUT,
  MENU,
  MY_PAGE,
  MY_BARGAINS,
  MY_BARGAINS_BOOKED: _MY_BARGAINS_BOOKED,
  MY_BARGAINS_MEDIATED: _MY_BARGAINS_MEDIATED,
  MY_ADS,
  MY_ADS_DRAFT: _MY_ADS_DRAFT,
  MY_ADS_PUBLISHED: _MY_ADS_PUBLISHED,
  MY_ADS_BOOKED: _MY_ADS_BOOKED,
  MY_ADS_MEDIATED: _MY_ADS_MEDIATED,
  NETWORK_AD_SHOW,
  NETWORK_ADS,
  NETWORK_HOME,
  RESET_PASSWORD: _RESET_PASSWORD,
  USER_PROFILE: _USER_PROFILE,
  TRANSPORT_SETTINGS,
  WATCHES,
  WATCHES_CREATE,
  WATCHES_ID,
  toNetwork,
  toNetworkADS,
  toNetworkADS_CREATE,
  toEditAd,
});

export default pages;
