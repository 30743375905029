/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { connect } from "react-redux";
import MuiAlert from "@material-ui/lab/Alert";
import store from "../store";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const resetMessage = () => {
  let resetMessageToDispatch = {
    type: "RESET_MESSAGE",
  };

  store.dispatch(resetMessageToDispatch);
};

const MylocSuccessMessage = props => {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (props.message) {
      setMessage(props.message);
      setShowMessage(true);
    } else {
      setShowMessage(false);
    }
  }, [props]);

  const handleCloseSuccess = (event, reason) => {
    setShowMessage(false);
    resetMessage();
  };

  return (
    <Snackbar open={showMessage} autoHideDuration={10000} onClose={handleCloseSuccess}>
      <Alert severity="success" onClose={handleCloseSuccess}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = state => {
  return {
    message: state.message.message,
  };
};

export default connect(mapStateToProps)(MylocSuccessMessage);
