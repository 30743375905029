/* eslint-disable react/prop-types */
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  hrWithText: {
    overflow: "visible" /* For IE */,
    padding: "0",
    border: "none",
    borderTop: "1px solid #333",
    color: "#333",
    textAlign: "center",
    marginTop: props => props.margin + 1,
    marginBottom: props => props.margin - 1,
    "&:after": {
      content: "attr(datacontent)",
      display: "inline-block",
      position: "relative",
      top: "-0.7em",
      fontSize: "1.5em",
      padding: "0 0.25em",
      background: "white",
    },
  },
}));

const HrWithText = props => {
  const classes = useStyles(props);

  return <hr className={classes.hrWithText} datacontent={props.text}></hr>;
};

export default HrWithText;
