const _DRAFT = 10;
const _PUBLISHED = 20;
const _BOOKED = 40;
const _MEDIATED = 90;

const adStatus = Object.freeze({
  DRAFT: _DRAFT,
  PUBLISHED: _PUBLISHED,
  BOOKED: _BOOKED,
  MEDIATED: _MEDIATED,
});

export default adStatus;
