/* eslint-disable react/prop-types */
import React from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import CameraAltIcon from "@material-ui/icons/CameraAlt";

const useStyles = makeStyles(theme => ({
  dropZone: {
    border: "1px black dashed",
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    cursor: "pointer",
    height: "85%",
    width: "85%",
  },
  dropIcon: {
    fontSize: "3rem",
  },
}));

const MylocDropZone = props => {
  const classes = useStyles();
  const { getRootProps, getInputProps } = useDropzone({ onDrop: props.onDrop });

  return (
    <div {...getRootProps({ className: `dropzone ${classes.dropZone}` })}>
      <input {...getInputProps()} />
      <CameraAltIcon className={classes.dropIcon} />
    </div>
  );
};

export default MylocDropZone;
