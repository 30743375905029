/* eslint-disable react/prop-types */
import React from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import Container from "@material-ui/core/Container";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  largeIcon: {
    fontSize: "1.5em",
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  menuLabel: { display: "flex", "justify-content": "center" },
  menuTextLink: {
    "text-decoration": "none",
    color: "rgba(0, 0, 0, 0.6)",
    "&:hover": {
      color: "rgba(0, 0, 0, 0.9)",
    },
  },
}));
const MenuButton = props => {
  const history = useHistory();
  const classes = useStyles();
  const user = useSelector(state => state.user.user);

  const { iconType, items, label } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = page => {
    setAnchorEl(null);
    history.push(page);
  };

  const open = Boolean(anchorEl);
  const Wrapper = iconType;

  return (
    <div>
      <Container>
        <IconButton aria-owns={open ? "menu-appbar" : null} aria-haspopup="true" onClick={handleMenu} size="small">
          {<Wrapper className={classes.largeIcon} color="primary"></Wrapper>}
        </IconButton>
        <Menu
          id="menu-appbar"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={open}
          onClose={handleClose}
        >
          <div>
            {props.headingText ? (
              <Box p={2}>
                <Typography variant="h6">{props.headingText}</Typography>
              </Box>
            ) : null}
            {items.map((menuItem, index) =>
              !menuItem.lpOnly || (user.company && user.company.lp) ? (
                <MenuItem key={index} onClick={() => handleClose(menuItem.url)}>
                  {menuItem.label}
                </MenuItem>
              ) : null,
            )}
          </div>
        </Menu>
      </Container>
      <Typography component="div" className={classes.menuLabel}>
        <Link to="#" onClick={handleMenu} className={classes.menuTextLink}>
          {label}
        </Link>
      </Typography>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps)(MenuButton);
