/* eslint react/prop-types: 0 */
import React from "react";
import { Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DetailSegment from "../components/DetailSegmentWithDivider";

import MylocStars from "../myloc-components/MylocStars";
import RoundedButton from "../components/RoundedButton";

const useStyles = makeStyles(theme => ({
  container: {
    //width: "75%",
  },
  title: {
    marginTop: theme.spacing(1),
  },
  table: {
    width: "300px",
  },
  button: {
    margin: theme.spacing(2),
  },
  adType: {
    backgroundColor: theme.palette.primary.light,
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  titleBox: {
    marginLeft: theme.spacing(1),
    flexWrap: "wrap",
  },
  addresses: {
    display: "flex",
  },
  capitalizedtext: {
    textTransform: "capitalize",
  },
  bookingButtons: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const quality = [
  {
    stars: "5",
    name: '"Sprillans ny"',
    description: "oanvänd",
  },
  {
    stars: "4",
    name: '"Som ny"',
    description: "ser oanvänd ut, inga brister",
  },
  {
    stars: "3",
    name: '"Gott skick"',
    description: "varsamt använd, fåtal brister men funktionsduglig",
  },
  {
    stars: "2",
    name: '"Okej"',
    description: "använd, flertal brister men funktionsduglig",
  },
  {
    stars: "1",
    name: '"Behöver kärlek"',
    description: "flitigt använd, stora brister, ej fullt funktionell",
  },
];

const AdDescription = props => {
  const { ad } = props;
  const { t } = useTranslation();

  const classes = useStyles();
  return (
    <div>
      <DetailSegment headingText="Fakta" noDivisor={true}>
        <Typography>Annonstyp: Skänkes</Typography>
        <Typography>{`Annons-id: ${ad.id}`}</Typography>
        <Typography>{`Antal kvar: ${ad.info.quantity.remaining} av ${ad.info.quantity.total}`}</Typography>
      </DetailSegment>
      {ad.info && ad.info.description ? (
        <DetailSegment headingText="Beskrivning">
          <Typography>{ad.info.description}</Typography>
        </DetailSegment>
      ) : null}
      <DetailSegment headingText={t("Condition")}>
        <MylocStars
          numStars={ad.info.quality.rank}
          text={quality[5 - ad.info.quality.rank] ? quality[5 - ad.info.quality.rank].name : ""}
          description={quality[5 - ad.info.quality.rank] ? quality[5 - ad.info.quality.rank].description : ""}
        ></MylocStars>
        <Typography>{ad.info.quality.description}</Typography>
      </DetailSegment>
      <DetailSegment headingText={t("Dimensions")}>
        <Typography>
          {t("Length")}: {ad.info.dimensions.length} cm
        </Typography>
        <Typography>
          {t("Width")}: {ad.info.dimensions.width} cm
        </Typography>
        <Typography>
          {t("Height")}: {ad.info.dimensions.height} cm
        </Typography>
        <Typography>
          {t("Depth")}: {ad.info.dimensions.depth} cm
        </Typography>
        <Typography>
          {t("Weight")}: {ad.info.dimensions.weight} kg
        </Typography>
      </DetailSegment>
      {/*<Typography variant="h6">{t("Available for pickup")}</Typography>
      <div className={classes.table}>
        <MylocSimpleTable
          rows={[
            { days: "Måndagar", times: "0830-1700" },
            { days: "Onsdagar", times: "0800-1000" },
          ]}
        ></MylocSimpleTable>
      </div>
        */}
      {props.myBooking && props.myBooking.numberBookedByMe >= 1 ? (
        <DetailSegment headingText="Min bokning">
          <Typography>
            {`Antal bokade av mig: ${props.myBooking.numberBookedByMe} av ${ad.info.quantity.total}`}{" "}
          </Typography>
          {props.myBooking.numberOfOrderedByMe >= 1 ? (
            <Typography>
              Du har beställt transport för {props.myBooking.numberOfOrderedByMe} st (Dessa går ej att avboka)
            </Typography>
          ) : null}
          {/*<Typography>Välj antalet att lägga till eller avboka</Typography>
          <MylocTextField
            type="number"
            label="Totalt antal bokade"
            inputProps={{ min: props.myBooking.numberBookedByMe, max: props.myBooking.numberBookableByMe }}
            defaultValue={props.myBooking.numberBookedByMe}
            onChange={e => props.myBooking.setNumberOfItems(Number(e.target.value))}
          />*/}
          <div className={classes.bookingButtons}>
            {ad && ad.info && ad.info.quantity && ad.info.quantity.remaining > 0 ? (
              <RoundedButton onClick={() => props.myBooking.setDialogBookOpen(true)}>Boka fler</RoundedButton>
            ) : null}
            <RoundedButton onClick={() => props.myBooking.setDialogUnbookOpen(true)}>Avboka</RoundedButton>
          </div>
        </DetailSegment>
      ) : null}
      {ad.info.pickup && props.session.loggedIn ? (
        <div>
          <DetailSegment
            columns={[
              {
                heading: t("Advertiser details"),
                content: (
                  <>
                    <Typography variant="h6"></Typography>
                    <Typography>{`${ad.owner.firstName} ${ad.owner.surname}`}</Typography>
                    <Typography>Tel: {ad.owner.phoneNumber}</Typography>
                    <Typography>Email: {ad.owner.email}</Typography>
                    <Typography></Typography>
                    <Typography>Kommun: {ad.info.companyGroup}</Typography>
                  </>
                ),
              },
              {
                heading: t("Can be picked up here"),
                content: (
                  <>
                    <Typography>{ad.info.pickup.addressRow1}</Typography>
                    <Typography>{ad.info.pickup.addressRow2}</Typography>
                    <Typography>{ad.info.pickup.postalNo}</Typography>
                    <Typography>{ad.info.pickup.city}</Typography>
                  </>
                ),
              },
            ]}
            afterColumns={
              <>
                <br />
                <Typography>Övrig hämtningsinformation: {ad.info.pickupInformation}</Typography>
              </>
            }
          />
        </div>
      ) : null}
      {ad.info.pickup && !props.session.loggedIn ? (
        <DetailSegment headingText={t("Advertiser details")}>
          <Typography>Kommun: {ad.info.companyGroup}</Typography>
        </DetailSegment>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps)(AdDescription);
