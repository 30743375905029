const initialState = {
  email: "",
  password: "",
  address: {
    addressRow1: "",
    addressRow2: "",
    country: "",
    description: "",
    city: "",
    postalNo: "",
  },
  company: {
    id: "",
    description: "",
    lp: false,
  },
  companyGroup: "",
  facility: {
    id: "",
    description: "",
  },
  user: {},
  userInformation: {
    surname: "",
    firstName: "",
    phoneNumber: "",
    referenceId: "",
  },
};

// Actions
const INIT_USER = "INIT_USER";
const SET_PASSWORD = "SET_PASSWORD";
const SET_EMAIL = "SET_EMAIL";
const SET_COMPANY = "SET_COMPANY";
const SET_COMPANY_DESCRIPTION = "SET_COMPANY_DESCRIPTION";
const SET_FACILITY = "SET_FACILITY";
const SET_FACILITY_DESCRIPTION = "SET_FACILITY_DESCRIPTION";
const SET_FIRST_NAME = "SET_FIRST_NAME";
const SET_SURNAME = "SET_SURNAME";
const SET_PHONE_NUMBER = "SET_PHONE_NUMBER";
const SET_REFERENCE_ID = "SET_REFERENCE_ID";
const SET_ADDRESS_ROW1 = "SET_ADDRESS_ROW1";
const SET_ADDRESS_ROW2 = "SET_ADDRESS_ROW2";
const SET_POSTAL_NO = "SET_POSTAL_NO";
const SET_CITY = "CITY";
const SET_COUNTRY = "COUNTRY";
const RESET_USER = "RESET_USER";
const CLEAR_USER = "CLEAR_USER";

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_USER:
      return { ...state, user: action.user };
    case SET_EMAIL:
      return { ...state, email: action.email };
    case SET_PASSWORD:
      return { ...state, password: action.password };
    case SET_COMPANY:
      return { ...state, company: { ...state.company, id: action.company } };
    case SET_COMPANY_DESCRIPTION:
      return { ...state, company: { ...state.company, description: action.description } };
    case SET_FACILITY:
      return { ...state, facility: { ...state.facility, id: action.facility } };
    case SET_FACILITY_DESCRIPTION:
      return { ...state, facility: { ...state.facility, description: action.description } };
    case SET_FIRST_NAME:
      return { ...state, userInformation: { ...state.userInformation, firstName: action.firstName } };
    case SET_SURNAME:
      return { ...state, userInformation: { ...state.userInformation, surname: action.surname } };
    case SET_PHONE_NUMBER:
      return { ...state, userInformation: { ...state.userInformation, phoneNumber: action.phoneNumber } };
    case SET_REFERENCE_ID:
      return { ...state, userInformation: { ...state.userInformation, referenceId: action.referenceId } };
    case SET_ADDRESS_ROW1:
      return { ...state, address: { ...state.address, addressRow1: action.addressRow1 } };
    case SET_ADDRESS_ROW2:
      return { ...state, address: { ...state.address, addressRow2: action.addressRow2 } };
    case SET_POSTAL_NO:
      return { ...state, address: { ...state.address, postalNo: action.postalNo.substring(0, 10) } };
    case SET_CITY:
      return { ...state, address: { ...state.address, city: action.city } };
    case SET_COUNTRY:
      return { ...state, address: { ...state.address, country: action.country } };
    case CLEAR_USER:
      return initialState;
    default:
      return state;
  }
}

// Action creators
export function initUsers(user) {
  return { type: INIT_USER, user };
}

export function setEmail(email) {
  return { type: SET_EMAIL, email };
}

export function setPassword(password) {
  return { type: SET_PASSWORD, password };
}

export function setCompany(company) {
  return { type: SET_COMPANY, company };
}

export function setCompanyDescription(description) {
  return { type: SET_COMPANY_DESCRIPTION, description };
}

export function setFacility(facility) {
  return { type: SET_FACILITY, facility };
}

export function setFacilityDescription(description) {
  return { type: SET_FACILITY_DESCRIPTION, description };
}

export function setFirstName(firstName) {
  return { type: SET_FIRST_NAME, firstName };
}

export function setSurname(surname) {
  return { type: SET_SURNAME, surname };
}

export function setPhoneNumber(phoneNumber) {
  return { type: SET_PHONE_NUMBER, phoneNumber };
}

export function setAddressRow1(addressRow1) {
  return { type: SET_ADDRESS_ROW1, addressRow1 };
}
export function setAddressRow2(addressRow2) {
  return { type: SET_ADDRESS_ROW2, addressRow2 };
}

export function setPostalNo(postalNo) {
  return { type: SET_POSTAL_NO, postalNo };
}

export function setCity(city) {
  return { type: SET_CITY, city };
}

export function setCountry(country) {
  return { type: SET_COUNTRY, country };
}

export function setReferenceId(referenceId) {
  return { type: SET_REFERENCE_ID, referenceId };
}

export function resetUser() {
  //The action is handled in store.js, setting state to undefined
  return { type: RESET_USER };
}

// Only re-initialize this reducer
export function clearUser() {
  return { type: CLEAR_USER };
}
