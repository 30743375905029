const howItWorks = [
  {
    title: `Kom igång!`,
    content: [
      `Skapa ett konto med din mailadress, om du vill lägga upp en annons eller lägga en bokning. För att titta på annonser behövs inget konto.`,
      `Ange dina kontaktuppgifter och adress, dessa används när du annonserar och bokar prylar.`,
    ],
  },

  {
    title: `Annonsera det du vill dela!`,
    content: [
      `Lägg upp en annons med något du vill skänka.`,
      `Låt andra inom ditt nätverk boka prylarna eller beställa tjänsterna som delas.`,
      `Annonsering är helt gratis!`,
      `Flera likadana objekt kan läggas upp som en annons, ange vilket antal. De som bokar kan boka det antalet de vill ha.`,
      `Annonsen går att "Spara som utkast" om man inte blir färdig med annonsen, annars kan man välja att "Publicera", då blir den synlig direkt för alla i nätverket.`,
    ],
  },

  {
    title: `Redigera annons`,
    content: [
      `Annonser du har valt att dela finns under "Mina annonser".`,
      `Det är möjligt att ändra informationen på annonsen t.ex. antalet.`,
    ],
  },

  {
    title: `Söka`,
    content: [
      `Sökning görs på beskrivningar och rubriker.`,
      `Vid sökning kan val av kategori göras.`,
      `En eller flera filter går att göra på sökningen, filter kan t.ex. vara vilka organisationer som har lagt upp annonsen.`,
    ],
  },

  {
    title: `Boka`,
    content: [
      `När du bokar en pryl på en annons väljer du hur många du vill ha. Observera att alla annonser är inte tillgängliga för bokning förrän efter en viss tid om man tillhör en annan organisation.`,
      `De du bokar blir reserverade till dig och ett mail med dina kontaktuppgifter skickas till annonsören.`,
      `Om du inte kan hämta varan själv kan du beställa transport.`,
      `När du har hämtat prylen markerar annonsören att den är förmedlad och annonsen försvinner från sidan.`,
      `Annonser som är bokade återfinns under "Mina fynd".`,
    ],
  },

  {
    title: `Förmedla`,
    content: [
      `Efter att en pryl har blivit hämtad ska annonsören förmedla att delningen är genomförd.`,
      `Förmedla finns som knapp under bokningsinfo-fliken på annonsen.`,
      `Vid förmedling försvinner annonsen från övriga i nätverket!`,
    ],
  },

  {
    title: `Nätverk`,
    content: [
      `Ett nätverk av organisationer som vill dela prylar eller tjänster mellan sig.`,
      `Du tillhör ett nätverk baserat på din mailadress.`,
    ],
  },

  {
    title: `Inlagring`,
    content: [
      `Om du inte har plats för sakerna som ska skänkas, beställ inlagring!`,
      `Valt inlagringsföretag hämtar dina prylar och lagrar dem för någon annan!`,
      `Inlagringsföretaget tar över annonsen, inga mer bekymmer.`,
      `Inlagring kan kosta, kontrollera när du beställer.`,
    ],
  },

  {
    title: `Transport`,
    content: [
      `Efter du har valt att boka en pryl på en annons kan du beställa en transport.`,
      `Bestäm vilken dag du önskar få dina prylar.`,
      `En transportör hämtar upp dina prylar och kör dem till dig.`,
      `Transportörer är specifikt valda av företaget eller nätverket du tillhör.`,
      `Transport kan kosta, kontrollera när du beställer.`,
    ],
  },
];

export default howItWorks;
