import axios from "axios";
import store from "../store";
import services from "../services/services";

/*
const getRequest = url => {
  const request = axios({
    method: "get",
    url: url,
  });
  //const request = axios.get(url);
  return request.then(response => response.data);
};
*/
const getRequest = async (url, postHandlingCallback) => {
  try {
    const response = await axios({
      method: "get",
      url: url,
    });
    //const request = axios.get(url);
    //Execute any callback
    if (postHandlingCallback) {
      postHandlingCallback();
    }

    return response.data;
  } catch (e) {
    handleError(e);
  }
};

//Defaulting method to POST if not set
const sendAxiosRequest = async (method = services.POST, url, data) => {
  let responseData = "";
  switch (method) {
    case services.GET:
      responseData = await axios.get(url);
      break;
    case services.POST:
      responseData = await axios.post(url, data);
      break;
    case services.PUT:
      responseData = await axios.put(url, data);
      break;
    case services.DELETE:
      responseData = await axios.delete(url, data);
      break;
    default:
      throw new Error("Method is not set");
  }
  return responseData;
};

const dispatchError = e => {
  let message;

  if (e.response) {
    if (e.response.data) {
      if (typeof e.response.data === "object") {
        message = e.response.data.errorMessage.errorMessage;
      } else {
        message = "Okänt fel";
      }
    } else {
      message = e.message;
    }
  } else {
    message = e.message;
  }

  let errorToDispatch = {
    type: "SET_ERROR",
    error: e,
    errorMessage: message,
  };

  store.dispatch(errorToDispatch);
};

const _setLoading = isLoading => {
  const dataToDispatch = {
    type: "SET_LOADING",
    loading: isLoading,
  };
  store.dispatch(dataToDispatch);
};

const _setLoadingFreeze = isLoadingFreeze => {
  const dataToDispatch = {
    type: "SET_LOADING_FREEZE",
    loadingFreeze: isLoadingFreeze,
  };
  store.dispatch(dataToDispatch);
};

const handleErrorResponse = e => {
  //Handle the error before dispatching
  //If response should be treated differently depending on response status: 400, 401, 403, 500 etc...

  dispatchError(e);
};
const handleNoResponse = e => {
  //TODO Handle the "No response" error before dispatching

  dispatchError(e);
};
const handleError = e => {
  if (e.response) {
    // The request was made and the server responded with a status code that falls out of the range of 2xx
    return handleErrorResponse(e);
  } else if (e.request) {
    // The request was made but no response was received

    return handleNoResponse(e);
  } else {
    return;
  }
};

/** Seems like this is not beeing used */
const postRequestWithImageUri = (url, newObject, uri, network) => {
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const formData = new FormData();
  var file = dataURLtoFile(uri, "filename.png");
  formData.append("mainphoto", file);
  formData.append("data", JSON.stringify(newObject));
  formData.append("network", network);

  const request = axios({
    method: "POST",
    url: url,
    //headers: {
    //  Accept: "application/json",
    //  "Content-Type": "multipart/form-data",
    //},
    data: formData,
  });

  return request.then(response => response.data);
};

const sendRequest = async (service, newObject, postHandlingCallback) => {
  let url = services.getUrl(service);

  //Some services need some fixing
  switch (service) {
    case services.CREATE: {
      //Add queryparameter from data
      url += "?accountid=" + newObject.id;
      newObject = newObject.newAccount;
      break;
    }
    case services.USER_UPDATE: {
      //Add queryparameter from data
      url += "?accountid=" + newObject.id;
      newObject = newObject.newAccount;
      break;
    }
    case services.LOGOUT: {
      //Add queryparameter from data
      url += "?id=" + newObject;
      break;
    }
    case services.GET_USER_INFO: {
      //Add queryparameter from data
      url += `?id=${newObject}`;
      break;
    }
    case services.RESET_PASSWORD: {
      //Add queryparameter from data
      //url += `?userid=${newObject}`;
      url += `?resettoken=${newObject.resetToken}`;
      delete newObject.resetToken;
      break;
    }
    case services.REQUEST_RESET_PASSWORD: {
      //Add queryparameter from data
      url += `?userid=${newObject}`;
      break;
    }
    case services.SET_PASSWORD: {
      //Add queryparameter from data
      url += `?sessionId=&resettoken=${newObject.resetToken}`;
      break;
    }
    case services.CREATE_AD: {
      //Add queryparameter from data
      url += `?id=${newObject.sessionId}`;
      delete newObject.sessionId;
      break;
    }
    case services.UPDATE_AD: {
      //Add queryparameter from data
      url += `/${newObject.pathParam.adId}`;
      url += `?id=${newObject.queryParam.sessionId}`;
      url += `&network=${newObject.queryParam.network}`;
      delete newObject.queryParam;
      delete newObject.pathParam;
      break;
    }
    case services.UPDATE_TRANSPORT_SERVICE: {
      //Add queryparameter from data
      url += `?id=${newObject.queryParam.sessionId}`;
      url += `&network=${newObject.queryParam.network}`;
      delete newObject.queryParam;
      break;
    }
    case services.ORDER_TRANSPORT: {
      //Add queryparameter from data
      url = url.replace(":adId", newObject.pathParam.adId);
      url += `?id=${newObject.queryParam.sessionId}`;
      delete newObject.queryParam;
      delete newObject.pathParam;
      break;
    }
    case services.LIST_ADS: {
      //Add queryparameter from data
      url += newObject;
      break;
    }
    case services.GET_STATISTICS: {
      //Add queryparameter from data
      url += newObject;
      break;
    }
    case services.GET_START_PAGE_HTML: {
      //Add queryparameter from data
      url += newObject;
      break;
    }
    case services.UPDATE_START_PAGE_HTML: {
      //Add queryparameter from data
      url += `/${newObject.pathParam.companyGroup}`;
      url += `?id=${newObject.queryParam.sessionId}`;
      url += `&network=${newObject.queryParam.network}`;
      delete newObject.queryParam;
      delete newObject.pathParam;
      break;
    }
    case services.LIST_TRANSPORT_SERVICES: {
      url += newObject;
      break;
    }

    default:
    //No url or data fix to be applied
  }
  try {
    //Activate loading indicator
    _setLoading(true);
    if (service.doFreeze) {
      _setLoadingFreeze(true);
    }

    const response = await sendAxiosRequest(service.method, url, newObject);

    //Execute any callback
    if (postHandlingCallback) {
      if (Array.isArray(postHandlingCallback)) {
        while (postHandlingCallback.length) {
          postHandlingCallback.shift().call(response.data);
        }
      } else {
        postHandlingCallback(response.data);
      }
    }

    return response.data;
  } catch (e) {
    handleError(e);
    throw e;
  } finally {
    //inActivate loading indicator
    _setLoading(false);
    _setLoadingFreeze(false);
  }
};

const deleteRequest = url => {
  const request = axios.delete(url);
  return request.then(response => response.data);
};

export { getRequest, sendRequest, postRequestWithImageUri, deleteRequest };
