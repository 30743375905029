/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { setNetwork } from "../reducers/networkActions";

function NetworkRoute({ children, computedMatch }) {
  const networkFromProps = useSelector(state => state.network.network);

  const networkFromPath = computedMatch.params.network;
  const pathParams = computedMatch.params;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!networkFromProps && networkFromPath) {
      dispatch(setNetwork(networkFromPath));
    }
  }, [dispatch, networkFromPath, networkFromProps]);

  if (!networkFromProps && networkFromPath) {
    return null;
  }

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, pathParams);
    }
  });

  return <div>{childrenWithProps}</div>;
}

export default NetworkRoute;
