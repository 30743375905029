/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.dark,
    color: "white",
    paddingLeft: theme.spacing(1),
  },
  content: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const DetailSegmentWithHeader = props => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" className={classes.heading}>
        {props.headingText}
      </Typography>
      <div className={classes.content}>{props.children}</div>
    </>
  );
};

export default DetailSegmentWithHeader;
