import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Typography, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invisibleIcon: {
    color: "rgba(0,0,0,0)",
  },
  heading: {
    textTransform: "uppercase",
  },
}));

const Header = ({ heading, linkBack }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <IconButton aria-label="left" size="medium" component={Link} onClick={linkBack}>
        <ArrowBackIosIcon fontSize="inherit" />
      </IconButton>
      <Typography className={classes.heading} variant="h5" align="center">
        {t(heading)}
      </Typography>
      {/* This button only exists for spacing, maybe there is a better way */}
      <IconButton aria-label="left" size="medium" disabled>
        <ArrowForwardIosIcon className={classes.invisibleIcon} fontSize="inherit" />
      </IconButton>
    </div>
  );
};

export default Header;
