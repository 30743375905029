/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
  },
  content: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  heading: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  sections: {
    display: "flex",
    flexWrap: "wrap",
  },
  section: {
    flexBasis: "50%",
  },
}));

const DetailSegmentWithHeader = props => {
  const classes = useStyles();

  return (
    <>
      {!props.noDivisor ? <Divider className={classes.divider}></Divider> : null}
      {props.columns ? (
        <div className={classes.sections}>
          {props.columns.map((column, index) => (
            <div className={classes.section} key={index}>
              <Typography className={classes.heading} variant="h6">
                {column.heading}
              </Typography>
              <div className={classes.content}>{column.content}</div>
            </div>
          ))}
        </div>
      ) : (
        <>
          <Typography className={classes.heading} variant="h6">
            {props.headingText}
          </Typography>
          <div className={classes.content}>{props.children}</div>
        </>
      )}
      {props.afterColumns ? <div className={classes.content}>{props.afterColumns}</div> : null}
    </>
  );
};

export default DetailSegmentWithHeader;
