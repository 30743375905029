/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";

import { Button, Grid, Typography, DialogContent, DialogActions, Dialog, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import adServices from "../services/adServices";

import { useTheme } from "@material-ui/core/styles";
import adDetailModes from "../utils/adDetailModes";
import adStatus from "../utils/adStatus";
import imageUrls from "../utils/imageUrls";

// redux
import { connect, useDispatch, useSelector } from "react-redux";
import * as adActionCreators from "../reducers/adReducer";

import Header from "../components/Header";
import services from "../services/services";
import AdDetailsForm from "./AdDetailsForm";
import pages from "../utils/pages";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: 0,
  },
  subheading: {
    paddingTop: theme.spacing(3),
    fontWeight: "bold",
  },
  starLine: {
    display: "flex",
    alignContent: "center",
  },

  date: {
    margin: theme.spacing(3),
  },
  time: {
    margin: theme.spacing(3),
  },
  button: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(5),
  },
  cardContainer: {
    display: "flex",
  },
  cardDiv: {
    position: "relative",
    width: 185,
    height: 200,
  },
  card: {
    width: 150,
    height: 150,
    padding: "20px",
    border: "grey 1px solid",
    margin: theme.spacing(2),
  },
  selectedCard: {
    border: `${theme.palette.primary.dark} 3px solid`,
  },
  media: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  removeImageButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 10,
  },
  successTitle: {
    textTransform: "uppercase",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const AdDetails = props => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const history = useHistory();
  const { id } = useParams();
  const [adId, setAdId] = useState(id);
  const { mode } = props;

  const [successDialogOpen, setSuccessDialogOpen] = useState(false);
  const [pictures, setPictures] = useState([]);
  const [rating, setRating] = React.useState(-1);
  // Need to have a starting value or the dropdown behaves strange
  const [categories, setCategories] = useState([{ id: 1, title: "" }]);

  const network = useSelector(state => state.network.network);
  const user = useSelector(state => state.user.user);
  const email = user?.email ?? "";
  const companyId = user?.company?.id ?? "";

  const dispatch = useDispatch();

  useEffect(() => {
    adServices.getCategories().then(response => {
      setCategories(response.categories);
    });
  }, []);

  const resetInformation = useCallback(() => {
    setRating(-1);
    setPictures([]);
    dispatch(adActionCreators.reset());
  }, [dispatch]);

  const handleMode = useCallback(() => {
    if (mode !== adDetailModes.CREATE) {
      adServices.get(id).then(response => {
        const { ad } = response;
        if (ad && ad.info) {
          // Adreducer should have the same structure as ad from backend...

          setRating(ad.info.quality.rank);
          const newAd = {
            typeId: ad.info.typeId,
            categoryId: ad.info.categoryId,
            title: ad.info.title,
            description: ad.info.description,
            status: ad.info.status,
            owner: ad.owner,
            quantity: ad.info.quantity,
            quality: ad.info.quality,
            dimensions: ad.info.dimensions,
            pickup: ad.info.pickup,
          };
          // This should probably move into PrivateRoute-component later
          let myAd = email === ad.owner.userId && !ad.info.logisticsCompany;
          let ourAd = companyId === ad.info.logisticsCompany;
          if (!myAd && !ourAd) {
            //history.goBack();
          }
          if (ad.images && ad.images.files && ad.images.mainFile) {
            const newPictures = [];
            ad.images.files.map(file => {
              let mainImage = false;
              if (file.fileId === ad.images.mainFile.fileId) {
                mainImage = true;
              }
              let nextId = 0;
              newPictures.forEach(picture => {
                nextId = Math.max(picture.id, nextId);
              });
              nextId++;
              let dataURL = `${process.env.REACT_APP_TOMCAT_ROOT_URL}/${file.thumbnailUrl}`;
              if (!dataURL) {
                dataURL = `${process.env.REACT_APP_TOMCAT_ROOT_URL}/${file.url}`;
              }
              newPictures.push({
                dataURL: dataURL,
                remove: false,
                mainImage: mainImage,
                fileId: file.fileId,
                id: nextId,
                isStored: true,
              });
              return null;
            });
            setPictures(newPictures);
          }
          dispatch(adActionCreators.initAd(newAd));
        }
      });
    } else {
      resetInformation();
    }
  }, [mode, id, email, companyId, dispatch, resetInformation]);

  // Init address information
  useEffect(() => {
    handleMode();
  }, [handleMode]);

  const updateAd = (values, saveAsDraft) => {
    let mainImageId = "";
    pictures.forEach(picture => {
      if (picture.mainImage) {
        mainImageId = picture.fileId;
      }
    });

    const updatedAd = {
      saveAsDraft: saveAsDraft,
      info: {
        typeId: 1,
        categoryId: values.category,
        title: values.title,
        description: values.description,
        pickupInformation: values.pickupInformation,
        quantity: {
          total: values.numberOfItems,
        },
        quality: {
          rank: rating,
          description: values.qualityText,
        },
        dimensions: {
          length: Number(values.length),
          width: Number(values.width),
          height: Number(values.height),
          depth: Number(values.depth),
          weight: Number(values.weight),
        },
        pickup: {
          addressRow1: values.addressRow1,
          addressRow2: values.addressRow2,
          postalNo: values.postalNo,
          city: values.city,
          country: "SE",
        },
      },
      mainImageId: mainImageId,
    };

    // add query and path parameters
    updatedAd.queryParam = {
      sessionId: props.session.sessionId,
      network: network,
    };
    updatedAd.pathParam = {
      adId: id,
    };

    adServices.handleRequest(services.UPDATE_AD, updatedAd, data => {
      setAdId(data.ad.id);

      // Handle mainPicture.
      let mainPicture = pictures.find(picture => picture.mainImage && !picture.remove);
      if (mainPicture) {
        if (!mainPicture.isStored) {
          adServices.uploadPicture(id, mainPicture.dataURL, mainPicture.mainImage).then(() => {
            history.goBack();
          });
        } else {
          history.goBack();
        }
      } else {
        adServices.uploadPicture(id, imageUrls.NO_IMAGE_AVAILABLE, true).then(() => {
          history.goBack();
        });
      }

      pictures
        .filter(picture => !picture.mainImage)
        .forEach(picture => {
          if (picture.isStored && picture.remove) {
            adServices.deletePicture(id, picture.fileId);
          }
          if (!picture.isStored) {
            adServices.uploadPicture(id, picture.dataURL, picture.mainImage);
          }
        });
      // Add the "no image"-image
    });
  };

  const createAd = (values, saveAsDraft) => {
    const newAd = {
      saveAsDraft: saveAsDraft,
      info: {
        typeId: 1,
        categoryId: values.category,
        title: values.title,
        description: values.description,
        pickupInformation: values.pickupInformation,
        quantity: {
          total: values.numberOfItems,
        },
        quality: {
          rank: rating,
          description: values.qualityText,
        },
        dimensions: {
          length: Number(values.length),
          width: Number(values.width),
          height: Number(values.height),
          depth: Number(values.depth),
          weight: Number(values.weight),
        },
        pickup: {
          addressRow1: values.addressRow1,
          addressRow2: values.addressRow2,
          postalNo: values.postalNo,
          city: values.city,
          country: "SE",
        },
      },
    };

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    let file = undefined;

    let image = "";
    pictures.forEach(picture => {
      if (picture.mainImage) {
        image = picture.dataURL;
      }
    });
    if (!image) {
      image = imageUrls.NO_IMAGE_AVAILABLE;
    }
    file = dataURLtoFile(image, "filename.png");

    const formData = new FormData();
    formData.append("mainphoto", file);
    formData.append("data", JSON.stringify(newAd));
    formData.append("network", network);
    formData.sessionId = props.session.sessionId;

    adServices.handleRequest(services.CREATE_AD, formData, data => {
      //Redirect user to login screen after successful registration
      setAdId(data.ad.id);
      pictures.forEach(picture => {
        if (!picture.mainImage) {
          adServices.uploadPicture(data.ad.id, picture.dataURL, true);
        }
      });
      setSuccessDialogOpen(true);
    });
  };

  const handleCloseSuccessDialog = () => {
    history.push(pages.toNetworkADS(network));
  };

  // Logic for which buttons to show on form
  let buttons = [];
  if (mode === adDetailModes.CREATE) {
    buttons.push({ label: "Publicera nu", saveAsDraft: false, validate: true, onSubmit: createAd, primary: true });
    buttons.push({ label: "Spara som utkast", saveAsDraft: true, validate: false, onSubmit: createAd });
  }
  if (mode === adDetailModes.EDIT) {
    buttons.push({ label: "Spara", saveAsDraft: false, validate: true, onSubmit: updateAd, primary: true });
    if (props.ad && props.ad.status && Number(props.ad.status.id) === adStatus.DRAFT) {
      buttons.push({ label: "Spara utkast", saveAsDraft: true, validate: false, onSubmit: updateAd });
    }
  }

  // Logic for headingText
  let headingText = "";
  if (mode === adDetailModes.CREATE) {
    headingText = "Skapa ny annons";
  }
  if (mode === adDetailModes.EDIT) {
    headingText = "Redigera annons";
  }

  return (
    <>
      <Header heading={headingText} linkBack={() => history.goBack()} />
      <Grid>
        {props.ad && (
          <AdDetailsForm
            categories={categories}
            buttons={buttons}
            pictures={pictures}
            setPictures={setPictures}
            rating={rating}
            setRating={setRating}
          />
        )}
        <Dialog open={successDialogOpen} onClose={handleCloseSuccessDialog} aria-labelledby="form-dialog-title">
          <DialogTitle className={classes.successTitle} id="form-dialog-title">
            Grattis din annons är skapad!
          </DialogTitle>
          <DialogContent>
            <Typography>Tips: Du kan märka upp det som ska skänkas med annons-id: {adId}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSuccessDialog} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ad: state.newAd,
    session: state.session,
    installation: state.installation,
    common: state.common,
    e: state.e,
  };
};

export default connect(mapStateToProps)(AdDetails);
