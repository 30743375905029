/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MainMenu from "./MainMenu";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    textTransform: "uppercase",
    padding: theme.spacing(1),
  },
  item: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuSharing = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" className={classes.heading}>
        {t("Menu")}
      </Typography>
      <MainMenu></MainMenu>
    </>
  );
};
const mapStateToProps = state => {
  return {
    session: state.session,
  };
};
export default connect(mapStateToProps)(MenuSharing);
