/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const MylocLoadingBackdrop = props => {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={props.common.loadingFreeze}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const mapStateToProps = state => {
  return {
    common: state.common,
  };
};

export default connect(mapStateToProps)(MylocLoadingBackdrop);
