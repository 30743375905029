/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

import adServices from "../services/adServices";

import {
  IconButton,
  Button,
  Breadcrumbs,
  Typography,
  Grid,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Myloc imports
import AppsIcon from "@material-ui/icons/Apps";
import { ExpandMore } from "@material-ui/icons";
import ViewListIcon from "@material-ui/icons/ViewList";
import ResultCard from "../components/ResultCard";
import ResultPanel from "../components/ResultPanel";
import Header from "../components/Header";
import SearchField from "../components/SearchField";
import queryString from "query-string";
import * as Constants from "../constants";
import { useLocation, useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import adStatus from "../utils/adStatus";
import adListView from "../utils/adListView";
import * as settingActionCreators from "../reducers/settingReducer";

import services from "../services/services";
import pages from "../utils/pages";
import { setNetwork } from "../reducers/networkActions";

const useStyles = makeStyles(theme => ({
  dropdowns: {
    //width: "45%",
  },
  categoryButton: {
    //width: "90%",
  },
  categoryDropdown: {
    borderRight: "1px solid black",
    marginRight: "10px",
    paddingRight: "10px",
  },
  breadcrumbLine: {
    display: "flex",
    justifyContent: "space-between",
  },
  listItem: {
    //    maxWidth: "25%",
  },
  listItemChild: {
    marginLeft: theme.spacing(1),
  },
  listItemParent: {
    fontWeight: "bold",
  },
  popoverPaper: {
    width: "90%",
    [theme.breakpoints.up("md")]: {
      width: "45%",
    },
    maxHeight: "60vh",
  },
}));

const AdList = props => {
  //const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [ads, setAds] = useState([]);
  const [filteredAds, setFilteredAds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [anchorElFilter, setAnchorElFilter] = React.useState(null);
  const [anchorElCategories, setAnchorElCategories] = React.useState(null);
  const [companies, setCompanies] = useState([]);
  const user = useSelector(state => state.user.user);

  let initialSelectedStatusFrom = "";
  let initialSelectedStatusTo = "";
  if (props.adType === Constants.adTypes.SEARCH) {
    initialSelectedStatusFrom = "20";
    initialSelectedStatusTo = "50";
  }
  const [selectedStatusFrom, setSelectedStatusFrom] = useState(initialSelectedStatusFrom);
  const [selectedStatusTo, setSelectedStatusTo] = useState(initialSelectedStatusTo);

  const network = useSelector(state => state.network.network);

  if (!network) {
    history.push(pages.HOME);
  }

  let queryParams = queryString.parse(location.search);

  const formatText = value => {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
  };

  useEffect(() => {
    let companyList = [];
    if (ads) {
      ads.forEach(ad => {
        if (
          !companyList.find(company => company.id === ad.info.companyGroup.toLowerCase()) &&
          ad.info.companyGroup !== ""
        ) {
          companyList.push({ id: ad.info.companyGroup.toLowerCase(), text: formatText(ad.info.companyGroup) });
        }
      });
      setCompanies(companyList);
    }
  }, [ads]);

  useEffect(() => {
    if (user && network) {
      if (user.networks?.length && user.networks[0] !== network) {
        dispatch(setNetwork(user.networks[0]));
        history.push(pages.toNetworkADS(user.networks[0]));
      }
    }
  }, [network, dispatch, history, user]);

  useEffect(() => {
    if (props.adType !== Constants.adTypes.SEARCH) {
      setSelectedStatusFrom(queryParams?.statusFrom ?? "");
      setSelectedStatusTo(queryParams?.statusTo ?? "");
    }
  }, [props.adType, queryParams]);

  useEffect(() => {
    let didCancel = false;
    const filter = {};
    if (props.setting.selectedCategory) {
      filter.category = props.setting.selectedCategory;
    }
    if (props.adType === Constants.adTypes.MY_ADS) {
      if (user?.company?.lp) {
        filter.logisticsCompany = user.company.id;
      } else {
        filter.owner = user?.email;
      }
    }
    if (props.adType === Constants.adTypes.MY_BARGAINS) {
      filter.recipient = user?.email;
    }

    if (selectedStatusFrom) {
      filter.statusFrom = selectedStatusFrom;
    }
    if (selectedStatusTo) {
      filter.statusTo = selectedStatusTo;
    }
    let queryString = `?network=${network}`;
    if (filter.category) {
      queryString = queryString.concat(`&category=${filter.category}`);
    }
    if (filter.owner) {
      queryString = queryString.concat(`&owner=${filter.owner}`);
    }
    if (filter.recipient) {
      queryString = queryString.concat(`&recipient=${filter.recipient}`);
    }
    if (filter.logisticsCompany) {
      queryString = queryString.concat(`&logisticsCompany=${filter.logisticsCompany}`);
    }
    if (filter.statusTo && filter.statusFrom) {
      queryString = queryString.concat(`&statusFrom=${filter.statusFrom}&statusTo=${filter.statusTo}`);
    }

    const fetchData = async () => {
      try {
        adServices.handleRequest(services.LIST_ADS, queryString, data => {
          if (!didCancel) {
            if (data && data.ads) {
              const { ads } = data;

              if (ads && Array.isArray(ads)) {
                setAds(ads);
              }
            }
          }
        });
      } catch (error) {
        if (!didCancel) {
          console.log("error");
        }
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
  }, [
    props.setting.searchString,
    selectedStatusTo,
    selectedStatusFrom,
    props.setting.selectedCategory,
    props.setting.selectedCompanies,
    props.adType,
    network,
    user,
  ]);

  useEffect(() => {
    if (filteredAds && filteredAds.length > 0) {
      if (props.setting && props.setting.savedScrollPositions && props.setting.savedScrollPositions.length > 0) {
        const savedPosition = props.setting.savedScrollPositions.find(savedScrollPosition => {
          return savedScrollPosition.key === `${location.pathname}${location.search}`;
        });
        if (savedPosition) {
          window.scrollTo(savedPosition.position.x, savedPosition.position.y);
          props.removeScrollPosition(`${location.pathname}${location.search}`);
        }
      }
    }
  }, [filteredAds, location.pathname, location.search, props]);

  useEffect(() => {
    adServices.getCategories().then(response => {
      setCategories([{ id: "", title: "Visa alla kategorier", parentId: "" }, ...response.categories]);
    });
  }, []);

  useEffect(() => {
    if (ads.length <= 0) {
      setFilteredAds(ads);
      return;
    }

    // filter searchstring
    const results = ads
      .filter(ad => {
        if (props.setting.searchString) {
          if (ad.info.title.toLowerCase().includes(props.setting.searchString.toLowerCase())) {
            return true;
          }
          if (String(ad.id).includes(props.setting.searchString.toLowerCase())) {
            return true;
          }
          if (
            ad.info &&
            ad.info.description &&
            ad.info.description.toLowerCase().includes(props.setting.searchString.toLowerCase())
          ) {
            return true;
          }
          return false;
        } else {
          return true;
        }
      })
      .filter(ad => {
        if (props.adType === Constants.adTypes.MY_BARGAINS) {
          if (ad.bookings.findIndex(booking => booking.bookedBy.userId === user.email) !== -1) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      })
      .filter(ad => {
        if (props.setting.selectedCompanies.length > 0) {
          if (ad.info && ad.info.companyGroup) {
            if (!props.setting.selectedCompanies.includes(ad.info.companyGroup.toLowerCase())) {
              return false;
            }
          }
        }
        return true;
      })
      .sort((a, b) => {
        // sort with latest publishDate first
        if (a.publishDate < b.publishDate) {
          return 1;
        } else {
          return -1;
        }
      });
    setFilteredAds(results);
  }, [props.setting.searchString, ads, props.setting.selectedCompanies, props.adType, user.email]);

  const handleCardSelect = ad => {
    props.saveScrollPosition(`${location.pathname}${location.search}`, window.scrollX, window.scrollY);
    if (Number(ad.info.status.id) === adStatus.DRAFT) {
      history.push(pages.toEditAd(ad.id));
    } else {
      history.push(`${pages.toNetworkADS(network)}/${ad.id}`);
    }
  };

  const handleOpenCategory = event => {
    setAnchorElCategories(event.currentTarget);
  };

  const handleOpenFilter = event => {
    setAnchorElFilter(event.currentTarget);
  };

  const handleSelectCategory = id => {
    props.setSelectedCategory(id);
    setAnchorElCategories(null);
  };
  const handleSelectCompany = id => {
    props.toggleSelectedCompany(id);
    setAnchorElFilter(null);
  };
  const handleClearFilter = () => {
    props.clearSelectedCompanies([]);
    setAnchorElFilter(null);
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const category = categories.find(aCategory => aCategory.id === props.setting.selectedCategory);
  const parentCategory = categories.find(aCategory => category && aCategory.id === category.parentId);
  let filterText = "Filter";
  if (props.setting.selectedCompanies.length > 0) {
    if (props.setting.selectedCompanies.length === 1) {
      filterText += " (1 filter valt)";
    } else {
      filterText += ` (${props.setting.selectedCompanies.length} filter valda)`;
    }
  }

  let headingText = "Search";
  let statusText = "";
  if (Constants.statusHeadings[String(selectedStatusFrom)]) {
    statusText = `- ${Constants.statusHeadings[String(selectedStatusFrom)]}`;
  }
  if (props.adType === Constants.adTypes.MY_ADS) {
    if (user && !user.company?.lp) {
      headingText = `Mina annonser ${statusText}`;
    } else {
      headingText = `Våra annonser ${statusText}`;
    }
  } else if (props.adType === Constants.adTypes.MY_BARGAINS) {
    headingText = `Mina fynd ${statusText}`;
  }

  return (
    <>
      <Header heading={headingText} linkBack={() => history.push(pages.HOME)} />
      <SearchField initialValue={props.setting.searchString} onSearch={undefined} />
      {props.adType === Constants.adTypes.SEARCH ? (
        <Grid container>
          <Grid item xs={12} md={6}>
            <ListItem id="categories-button" button className={classes.categoryButton} onClick={handleOpenCategory}>
              <ListItemText primary={category ? category.title : "Kategori"} />
              <ExpandMore />
            </ListItem>
            <Menu
              className={classes.categoryList}
              PopoverClasses={{ paper: classes.popoverPaper }}
              id="customized-menu"
              getContentAnchorEl={null}
              anchorEl={anchorElCategories}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElCategories)}
              onClose={() => setAnchorElCategories(null)}
            >
              {categories.map(category => {
                let listItemClass = `${classes.listItem} ${classes.listItemChild}`;
                if (!category.parentId) {
                  listItemClass = `${classes.listItem} ${classes.listItemParent}`;
                }
                return (
                  <MenuItem
                    className={listItemClass}
                    button
                    key={category.id}
                    onClick={() => handleSelectCategory(category.id)}
                  >
                    {category.title}
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
          <Grid item xs={12} md={6}>
            <ListItem button className={classes.categoryButton} onClick={handleOpenFilter}>
              <ListItemText primary={filterText} />
              <ExpandMore />
            </ListItem>
            <Menu
              className={classes.categoryList}
              PopoverClasses={{ paper: classes.popoverPaper }}
              id="customized-menu"
              getContentAnchorEl={null}
              anchorEl={anchorElFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElFilter)}
              onClose={() => setAnchorElFilter(null)}
            >
              <MenuItem className={classes.listItem} button onClick={handleClearFilter}>
                Rensa filter
              </MenuItem>
              {companies?.map(company => {
                return (
                  <MenuItem
                    className={classes.listItem}
                    button
                    key={company.id}
                    onClick={() => handleSelectCompany(company.id)}
                  >
                    {company.text}
                    {props.setting.selectedCompanies.includes(company.id) && <CheckIcon color="primary" />}
                  </MenuItem>
                );
              })}
            </Menu>
          </Grid>
        </Grid>
      ) : null}

      {!mobile && (
        <div className={classes.breadcrumbLine}>
          {props.setting.selectedCategory ? (
            <Breadcrumbs aria-label="breadcrumb">
              <Button
                color="primary"
                onClick={() => {
                  props.setSelectedCategory("");
                }}
              >
                Alla
              </Button>
              {category ? (
                parentCategory && parentCategory.id !== "" ? (
                  <Button color="primary" onClick={() => props.setSelectedCategory(parentCategory.id)}>
                    {parentCategory.title}
                  </Button>
                ) : (
                  <Typography>{category.title}</Typography>
                )
              ) : null}
              {parentCategory && parentCategory.id !== "" ? <Typography>{category.title}</Typography> : null}
            </Breadcrumbs>
          ) : null}
          {/*<div className={classes.chips}>
          {selectedCompanies.map((company, index) => {
            return <Chip key={index} label={company} onDelete={() => toggleSelectedCompany(company)} color="primary" />;
          })}
        </div>*/}
        </div>
      )}
      <div>
        <IconButton onClick={props.setGridView}>
          <AppsIcon />
        </IconButton>
        <IconButton onClick={props.setListView}>
          <ViewListIcon />
        </IconButton>
      </div>
      {props.setting && props.setting.adListView === adListView.GRID ? (
        <Grid alignItems="stretch" container spacing={3}>
          {filteredAds.map(ad => {
            return (
              <Grid key={ad.id} item xs={12} sm={6} lg={3}>
                <ResultCard result={ad} onClick={handleCardSelect}></ResultCard>
              </Grid>
            );
          })}
        </Grid>
      ) : (
        <div className={classes.adList}>
          {filteredAds.map(ad => {
            return <ResultPanel key={ad.id} result={ad} onClick={handleCardSelect}></ResultPanel>;
          })}
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    installation: state.installation,
    setting: state.setting,
  };
};

const mapDispatchToProps = {
  setGridView: settingActionCreators.setGridView,
  setListView: settingActionCreators.setListView,
  setSearchString: settingActionCreators.setSearchString,
  setSelectedCategory: settingActionCreators.setSelectedCategory,
  toggleSelectedCompany: settingActionCreators.toggleSelectedCompany,
  clearSelectedCompanies: settingActionCreators.clearSelectedCompanies,
  saveScrollPosition: settingActionCreators.saveScrollPosition,
  removeScrollPosition: settingActionCreators.removeScrollPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdList);
