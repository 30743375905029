// Initialized in store but in needed to be here too...
import storage from "../utils/storage";
import store from "../store";

const initialState = {
  loggedIn: null,
  hasBeenInitialized: false,
  rememberMe: null,
  sessionId: null,
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        sessionId: action.sessionId,
        loggedIn: true,
      };
    case LOGOUT:
      return {
        ...state,
        sessionId: null,
        userId: "",
        loggedIn: false,
      };
    case SET_HAS_BEEN_INITIALIZED:
      return {
        ...state,
        hasBeenInitialized: action.hasBeenInitialized,
      };
    case SET_LOGGED_IN:
      return {
        ...state,
        loggedIn: action.loggedIn,
      };
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.sessionId,
      };
    case SET_REMEMBER_ME: {
      let rememberMe = false;
      if (typeof action.rememberMe === "string") {
        rememberMe = action.rememberMe === "true" ? true : false;
      } else {
        rememberMe = action.rememberMe;
      }
      return {
        ...state,
        rememberMe: rememberMe,
      };
    }
    default:
      return state;
  }
};

//Actions
const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const SET_HAS_BEEN_INITIALIZED = "SET_HAS_BEEN_INITIALIZED";
const SET_SESSION_ID = "SET_SESSION_ID";
const SET_REMEMBER_ME = "REMEMBER_ME";
const SET_LOGGED_IN = "SET_LOGGED_IN";

// Action creators
export function login(sessionId) {
  return { type: LOGIN, sessionId };
}

export function logout() {
  return { type: LOGOUT };
}

export function setHasBeenInitialized(hasBeenInitialized) {
  return { type: SET_HAS_BEEN_INITIALIZED, hasBeenInitialized };
}

export function setSessionId(sessionId, rememberMe) {
  storage.saveItem("sessionId", sessionId, rememberMe);
  return { type: SET_SESSION_ID, sessionId };
}

export function setRememberMe(rememberMe) {
  //Store in storage
  storage.saveItem("rememberMe", rememberMe, rememberMe);
  return { type: SET_REMEMBER_ME, rememberMe };
}

export function setLoggedIn(loggedIn, rememberMe) {
  //Store in storage
  storage.saveItem("loggedIn", loggedIn, rememberMe);
  return { type: SET_LOGGED_IN, loggedIn };
}

export function setLoggedOut() {
  let state = store.getState();

  const rememberMe = state.session.rememberMe;
  const loggedIn = false;
  storage.saveItem("loggedIn", loggedIn, rememberMe);
  return { type: SET_LOGGED_IN, loggedIn };
}

export function resetSessionId() {
  let state = store.getState();

  const rememberMe = state.session.rememberMe;
  storage.saveItem("sessionId", null, rememberMe);
  const sessionId = null;
  return { type: SET_SESSION_ID, sessionId };
}

export default sessionReducer;
