const _POST = "POST";
const _GET = "GET";
const _PUT = "PUT";

const _BASE_URL = process.env.REACT_APP_API_URL;

const ACCOUNT_PATH = "/circAc/account";
const NETWORK_BASE_PATH = "/circNetwork";

const NETWORKS_PATH = NETWORK_BASE_PATH + "/networks";

const AD_PATH = "/ads";
const LOGISTICS_PARTNER_PATH = "/logistics-partners";

const _LOGIN = {
  path: `${ACCOUNT_PATH}/login`,
  method: _POST,
};
const _LOGOUT = {
  path: `${ACCOUNT_PATH}/logout`,
  method: _POST,
};

const REGISTER = {
  path: `${ACCOUNT_PATH}/register`,
  method: _POST,
  doFreeze: true,
  successMessage: "Instruktioner för att slutföra registreringen skickades till din e-postadress",
};

const CREATE = `${ACCOUNT_PATH}/create`;

const _USER_UPDATE = {
  path: `${ACCOUNT_PATH}/update`,
  method: _POST,
  doFreeze: true,
  successMessage: "Din profil har uppaterats",
};
const _GET_USER_INFO = {
  path: `${ACCOUNT_PATH}/user`,
  method: _GET,
};

const _REQUEST_RESET_PASSWORD = {
  path: `${ACCOUNT_PATH}/password`,
  doFreeze: true,
  method: _POST,
};

const _RESET_PASSWORD = {
  path: `${ACCOUNT_PATH}/password/reset`,
  doFreeze: true,
  method: _POST,
};
const _SET_PASSWORD = {
  path: `${ACCOUNT_PATH}/password/set`,
  method: _POST,
};

const _CREATE_AD = {
  path: `${AD_PATH}`,
  method: _POST,
  doFreeze: true,
};

const _UPDATE_AD = {
  path: `${AD_PATH}`,
  method: _PUT,
  doFreeze: true,
};

const _LIST_ADS = {
  path: `${AD_PATH}`,
  method: _GET,
};

const _GET_STATISTICS = {
  path: `${AD_PATH}/statistics`,
  method: _GET,
};

const _GET_START_PAGE_HTML = {
  path: `${AD_PATH}/startpage`,
  method: _GET,
};

const _UPDATE_START_PAGE_HTML = {
  path: `${AD_PATH}/startpage`,
  method: _PUT,
};

const _LIST_TRANSPORT_SERVICES = {
  path: `${LOGISTICS_PARTNER_PATH}/transport-services`,
  method: _GET,
};

const _UPDATE_TRANSPORT_SERVICE = {
  path: `${LOGISTICS_PARTNER_PATH}/transport-services`,
  method: _PUT,
};

const _ORDER_TRANSPORT = {
  path: `${AD_PATH}/:adId/orderTransport`,
  method: _POST,
  doFreeze: true,
};

const NETWORKS = {
  path: NETWORKS_PATH,
  method: _GET,
};
const _getUrl = service => {
  if (typeof service === "string") {
    if (service.includes("http")) {
      //Fallback to let old links work
      return service;
    }
  }
  return service.path ? _BASE_URL + service.path : _BASE_URL + service;
};

const service = Object.freeze({
  getUrl: service => {
    return _getUrl(service);
  },

  BASE_URL: _BASE_URL,

  POST: _POST,
  GET: _GET,
  PUT: _PUT,
  LOGIN: _LOGIN,
  LOGOUT: _LOGOUT,
  NETWORKS,
  REGISTER: REGISTER,
  CREATE: CREATE,
  GET_USER_INFO: _GET_USER_INFO,
  REQUEST_RESET_PASSWORD: _REQUEST_RESET_PASSWORD, //Sends reset token to user email
  RESET_PASSWORD: _RESET_PASSWORD, //Resets email together with received token
  SET_PASSWORD: _SET_PASSWORD, //Sets new email together with current email
  USER_UPDATE: _USER_UPDATE,
  CREATE_AD: _CREATE_AD,
  UPDATE_AD: _UPDATE_AD,
  LIST_ADS: _LIST_ADS,
  GET_STATISTICS: _GET_STATISTICS,
  GET_START_PAGE_HTML: _GET_START_PAGE_HTML,
  UPDATE_START_PAGE_HTML: _UPDATE_START_PAGE_HTML,
  LIST_TRANSPORT_SERVICES: _LIST_TRANSPORT_SERVICES,
  UPDATE_TRANSPORT_SERVICE: _UPDATE_TRANSPORT_SERVICE,
  ORDER_TRANSPORT: _ORDER_TRANSPORT,
});

export default service;
