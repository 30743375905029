/* eslint-disable react/prop-types */
// React/javascript libraries
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// Material ui
import { Button, Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
// Myloc imports
import MylocErrorMessage from "../myloc-components/MylocErrorMessage";
import accountServices from "../services/accountServices";
import * as sessionActionCreators from "../reducers/sessionReducer";
import * as userActions from "../reducers/userReducer";

import services from "../services/services";
import pages from "../utils/pages";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  rememberMeCheckBox: {
    alignSelf: "center",
    marginTop: theme.spacing(3),
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  link: {
    alignSelf: "center",
  },
}));

const Logout = props => {
  let history = useHistory();
  const classes = useStyles();

  const [showError, setShowError] = useState(false);
  const [errorMessage] = useState("");

  useEffect(() => {});

  const handleLogout = event => {
    event.preventDefault();

    //Just dispatch the removal of sessionId and set loggedIn to false for now
    props.setLoggedOut();
    props.resetSessionId();
    props.resetUser();

    // Call rest service to remove the persistent session backend
    // https://www.wrike.com/open.htm?id=474101593

    //Logout
    accountServices.handleRequest(services.LOGOUT, props.session.sessionId, function() {
      //Redirect user to login screen after successful registration
      history.push(pages.HOME);
    });
  };

  return (
    <>
      {props.session.loggedIn ? (
        <form onSubmit={handleLogout}>
          <Container className={classes.container} maxWidth="sm">
            <Button variant="contained" color="primary" type="submit">
              Klicka här för att logga ut
            </Button>
          </Container>
        </form>
      ) : (
        <Typography variant="h5" align="center">
          Du är utloggad!
        </Typography>
      )}
      <MylocErrorMessage showError={showError} setShowError={setShowError} errorMessage={errorMessage} />
    </>
  );
};

const mapDispatchToProps = {
  setLoggedOut: sessionActionCreators.setLoggedOut,
  resetSessionId: sessionActionCreators.resetSessionId,
  resetUser: userActions.resetUser,
};

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
