import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import howItWorks from "../data/howItWorks";

const useStyles = makeStyles(theme => ({
  listItem: {
    marginTop: theme.spacing(3),
  },
  listSubItem: {
    marginTop: theme.spacing(1),
  },
}));

const HowItWorks = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4">Så fungerar det!</Typography>
      <ul>
        {howItWorks.map(paragraph => (
          <>
            <li className={classes.listItem}>{paragraph.title}</li>
            <ul>
              {paragraph.content.map((item, index) => (
                <li className={classes.listSubItem} key={index}>
                  {item}
                </li>
              ))}
            </ul>
          </>
        ))}
      </ul>
    </div>
  );
};

export default HowItWorks;
