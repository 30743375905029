import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import storage from "../utils/storage";
import store from "../store";
import * as sessionActionCreators from "../reducers/sessionReducer";
import services from "../services/services";
import accountServices from "../services/accountServices";

const loadStateFromStorage = async rememberMe => {
  //First load rememberMe to know where to look

  let doRememberMe = await storage.loadItem("rememberMe", rememberMe);

  if (doRememberMe === null) {
    doRememberMe = false;
  }

  store.dispatch(sessionActionCreators.setRememberMe(doRememberMe));

  //Load sessionId
  const sessionId = await storage.loadItem("sessionId", doRememberMe);

  store.dispatch(sessionActionCreators.setSessionId(sessionId, doRememberMe));

  //Load loggedIn
  const loggedIn = await storage.loadItem("loggedIn", doRememberMe);
  store.dispatch(sessionActionCreators.setLoggedIn(loggedIn, doRememberMe));
};

const initializeState = async rememberMe => {
  loadStateFromStorage(rememberMe);
};

const getUserData = sessionId => {
  accountServices.handleRequest(services.GET_USER_INFO, sessionId);
};
const init = rememberMe => {
  initializeState(rememberMe);
};

const MylocInit = () => {
  const rememberMe = useSelector(state => state.session?.rememberMe);
  const sessionId = useSelector(state => state.session?.sessionId);
  const loggedIn = useSelector(state => state.session?.loggedIn);
  const email = useSelector(state => state.user?.email);

  const [hasBeenInitialized, setHasBeenInitialized] = useState(false);

  useEffect(() => {
    if (!hasBeenInitialized) {
      init(rememberMe);
    }
    setHasBeenInitialized(true);
  }, [hasBeenInitialized, rememberMe]);

  useEffect(() => {
    if (sessionId !== null && loggedIn && email === "") {
      getUserData(sessionId);
    }
  }, [email, loggedIn, rememberMe, sessionId]);

  return null;
};

export default MylocInit;
