const initialState = {
  notLoggedIn: {
    open: false,
    message: "",
  },
  networkNotSelected: {
    open: false,
    message: "",
  },
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOT_LOGGED_IN_OPEN:
      return {
        ...state,
        notLoggedIn: {
          open: action.notLoggedIn.open,
          message: action.notLoggedIn.message,
        },
      };
    case SET_NETWORK_NOT_SELECTED:
      return {
        ...state,
        networkNotSelected: {
          open: action.networNotSelected.open,
          message: action.networNotSelected.message,
        },
      };
    default:
      return state;
  }
};

//Actions
const SET_NOT_LOGGED_IN_OPEN = "SET_NOT_LOGGED_IN_OPEN";
const SET_NETWORK_NOT_SELECTED = "SET_NETWORK_NOT_SELECTED";

// Action creators
export function setNotLoggedInOpen(notLoggedInOpen, message) {
  return { type: SET_NOT_LOGGED_IN_OPEN, notLoggedIn: { open: notLoggedInOpen, message: message } };
}

export function setNetworkNotSelectedOpen() {
  return { type: SET_NETWORK_NOT_SELECTED, networNotSelected: { open: true, message: "Välj marknad" } };
}

export function setNetworkNotSelectedClosed() {
  return { type: SET_NETWORK_NOT_SELECTED, networNotSelected: { open: false, message: "" } };
}
export default dialogReducer;
