/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";

import { useTranslation } from "react-i18next";
import pages from "../utils/pages";
import { setNetwork } from "../reducers/networkActions";
import services from "../services/services";
import accountServices from "../services/accountServices";
import MylocSelect from "../myloc-components/MylocSelect";
import { useHistory } from "react-router-dom";
import MylocSmallHeader from "./MylocSmallHeader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },

  button: {
    color: "white",
    textTransform: "uppercase",
  },
  text: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(1),
  },
  media: { height: 200 },
}));

const Home = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const network = useSelector(state => state.network.network);
  const [networks, setNetworks] = useState();
  let history = useHistory();

  const getNetworks = () => {
    accountServices.handleRequest(services.NETWORKS, null, setNetworks);
  };
  useEffect(() => {
    if (!networks) {
      getNetworks();
    }
  }, [networks]);

  const handleNetworkSelect = event => {
    const network = event.target.value;

    dispatch(setNetwork(network));
    history.push(pages.toNetwork(network));
  };

  return (
    <>
      <Hidden smUp>
        <MylocSmallHeader />
      </Hidden>
      {!network && networks && (
        <div style={{ width: "100%" }}>
          <Box display="flex" justifyContent="center">
            <MylocSelect
              items={networks}
              selectedItem={network}
              itemValueField={"id"}
              itemKey={"id"}
              title={t("SELECT_NETWORK")}
              handleSelect={handleNetworkSelect}
            />
          </Box>
        </div>
      )}
      <Grid container spacing={10} style={{ padding: "24px" }}>
        <Grid xs={12} sm={12} md={12} lg={4} xl={4} item={true}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            {t("Mindre miljöpåverkan")}
          </Typography>
          <Card className={classes.card}>
            <CardMedia className={classes.media} image="/images/environmentimpact.jpg" />
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} xl={4} item={true}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            Påverka genom att dela
          </Typography>

          <Card className={classes.card}>
            <CardMedia className={classes.media} image="/images/reuser.jpg" />
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} xl={4} item={true}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            Minska kostnaderna
          </Typography>
          <Card className={classes.card}>
            <CardMedia className={classes.media} image="/images/giver.jpg" />
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    installation: state.installation,
    session: state.session,
  };
};

export default connect(mapStateToProps)(Home);
