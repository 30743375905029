/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import React from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";

import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import * as Constants from "../constants";

import { HashRouter as Router, Switch, Route } from "react-router-dom";
//import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

import Hidden from "@material-ui/core/Hidden";
import { Box } from "@material-ui/core";

import OrderTransport from "./OrderTransport";
import TransportSettings from "./TransportSettings";
import OrderStorage from "./OrderStorage";
import Ad from "./Ad";
import AdList from "./AdList";
import AdDetails from "./AdDetails";
import Login from "./Login";
import Logout from "./Logout";
import Home from "./Home";
import NetworkHome from "./NetworkHome";
import Register from "./Register";
import LinksToPartners from "./LinksToPartners";
import Apply from "./Apply";
import PrivateRoute from "./PrivateRoute";
import Footer from "./Footer";
import MenuSharing from "./MenuSharing";
import NotLoggedInDialog from "./NotLoggedInDialog";
import NetworkNotSelectesDialog from "./NetworkNotSelectesDialog";

import MylocErrorMessage from "../myloc-components/MylocErrorMessage";
import MylocSuccessMessage from "../myloc-components/MylocSuccessMessage";
import MylocProgressIndicator from "../myloc-components/MylocProgressIndicator";
import MylocLoadingBackdrop from "../myloc-components/MylocLoadingBackdrop";
import MylocTopMenu from "../myloc-components/MylocTopMenu";
import MylocMenuLinks from "../myloc-components/MylocMenuLinks";
import ScrollToTop from "./ScrollToTop";

import pages from "../utils/pages";
import adDetailModes from "../utils/adDetailModes";
import ResetPassword from "./ResetPassword";
import HowItWorks from "./HowItWorks";
import InstructionVideos from "./InstructionVideos";
import MylocSmallHeader from "./MylocSmallHeader";
import NetworkRoute from "./NetworkRoute";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  appBar: {},
  customizeToolbar: {
    minHeight: 70,
    paddingTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  outerContainer: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  content: {
    flexGrow: 1,
    overflow: "hidden",
    padding: theme.spacing(3),
    "padding-bottom": theme.spacing(16),
    [theme.breakpoints.up("xs")]: {
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
    [theme.breakpoints.up("sm")]: {
      //marginLeft: -drawerWidth,
      paddingTop: theme.spacing(22),
    },
  },
  searchLine: {
    display: "flex",
  },
  rightToolbar: {
    marginLeft: "auto",
    marginRight: -12,
    display: "flex",
  },
  rightSection: {
    width: "100%",
  },
  headSearchLine: {
    "padding-left": theme.spacing(16),
    "min-width": "250px",
    "max-width": "600px",
    "margin-right": 0,
    "margin-left": "auto",
  },
  largeIcon: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.0em",
    },
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "column",
  },
  menuLabel: { display: "flex", "justify-content": "center" },
  menuAppBarIcons: {
    display: "flex",
    "margin-left": "auto",
    "margin-right": "auto",
  },
  menuAppBarIconLabel: {
    display: "flex",
    "justify-content": "center",
  },
  iconAndLinks: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const NavigationContent = () => {
  const classes = useStyles();
  //const theme = useTheme();
  //const { t } = useTranslation();

  return (
    <Router>
      <ScrollToTop />
      <Container className={classes.outerContainer} maxWidth="lg">
        <div className={classes.root}>
          <CssBaseline />
          <Hidden xsDown implementation="css">
            <AppBar position="fixed" style={{ background: "white", boxShadow: "none" }} className={classes.appBar}>
              <Container maxWidth="lg">
                <Toolbar className={classes.customizeToolbar}>
                  <div className={classes.iconAndLinks}>
                    <MylocSmallHeader />
                  </div>
                  <MylocTopMenu />
                </Toolbar>
              </Container>
              <MylocMenuLinks />
            </AppBar>
          </Hidden>
          <main className={classes.content}>
            <Box className={classes.main}>
              <MylocProgressIndicator />
              <MylocLoadingBackdrop />
              <Switch>
                <Route exact path={pages.HOME} render={() => <Home />} />
                <Route exact path={pages.HOW_IT_WORKS}>
                  <HowItWorks />
                </Route>
                <Route exact path={pages.INSTRUCTION_VIDEOS}>
                  <InstructionVideos />
                </Route>
                <Route exact path={pages.LOGIN} render={() => <Login />} />
                <Route exact path={pages.LOGOUT} render={() => <Logout />} />
                <Route exact path={pages.RESET_PASSWORD} render={() => <ResetPassword />} />
                <Route exact path={pages.APPLY} render={() => <Apply />} />
                <Route exact path={pages.USER_PROFILE} render={() => <Register />} />
                <Route exact path={pages.MY_PAGE} render={() => <h1>Here you can change your user settings</h1>} />

                <Route exact path={pages.MY_ADS} render={() => <AdList adType={Constants.adTypes.MY_ADS}></AdList>} />
                <Route
                  exact
                  path={pages.MY_BARGAINS}
                  render={() => <AdList adType={Constants.adTypes.MY_BARGAINS}></AdList>}
                />
                <Route exact path={pages.MENU} render={() => <MenuSharing />} />

                <Route exact path={pages.LINKS}>
                  <LinksToPartners />
                </Route>
                <PrivateRoute exact path={pages.AD_CREATE}>
                  <AdDetails mode={adDetailModes.CREATE} />
                </PrivateRoute>
                <PrivateRoute exact path={pages.AD_EDIT}>
                  <AdDetails mode={adDetailModes.EDIT} />
                </PrivateRoute>
                <PrivateRoute exact path={pages.AD_PUBLISH}>
                  <AdDetails mode={adDetailModes.PUBLISH} />
                </PrivateRoute>

                <Route exact path={pages.TRANSPORT_SETTINGS}>
                  <TransportSettings />
                </Route>

                <Route exact path={pages.WATCHES} render={() => <h1>Here you can see your watches</h1>} />

                <Route exact path={pages.WATCHES_CREATE} render={() => <h1>Here you can create new watches</h1>} />
                <Route
                  exact
                  path={pages.WATCHES_ID}
                  render={({ match }) => <h1>Info about watch with id {match.params.id}</h1>}
                />

                {/* Place Network pages in bottom to not mixup static links with networks, like /links vs /Dela-Syd */}
                <NetworkRoute exact path={pages.NETWORK_ADS}>
                  <AdList adType={Constants.adTypes.SEARCH}></AdList>
                </NetworkRoute>

                <NetworkRoute exact path={pages.NETWORK_HOME}>
                  <NetworkHome />
                </NetworkRoute>

                <NetworkRoute exact path={pages.NETWORK_AD_SHOW}>
                  <Ad />
                </NetworkRoute>
                <NetworkRoute exact path={pages.AD_ORDER_TRANSPORT}>
                  <OrderTransport />
                </NetworkRoute>
                <NetworkRoute exact path={pages.AD_ORDER_STORAGE}>
                  <OrderStorage />
                </NetworkRoute>
              </Switch>
              <MylocSuccessMessage />
              <MylocErrorMessage />
              <NotLoggedInDialog />
              <NetworkNotSelectesDialog />

              <Hidden smUp>
                <Footer></Footer>
              </Hidden>
            </Box>
          </main>
        </div>
      </Container>
    </Router>
  );
};
const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps)(NavigationContent);
