/* eslint-disable react/prop-types */
// React/javascript libraries
import React, { useState, useEffect } from "react";
import { Typography, Button, Paper, Box, Checkbox } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
import MylocTextField from "../myloc-components/MylocTextField";
import logisticsPartnerServices from "../services/logisticsPartnerServices";
import adServices from "../services/adServices";
import { connect, useSelector } from "react-redux";
import startOfToday from "date-fns/startOfToday";
import format from "date-fns/format";
import toDate from "date-fns/toDate";
import compareAsc from "date-fns/compareAsc";
import { fade } from "@material-ui/core/styles/colorManipulator";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import * as messageActionCreators from "../reducers/messageReducer";
import * as errorActionCreators from "../reducers/errorReducer";
import services from "../services/services";
import pages from "../utils/pages";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },
  paper: {
    backgroundColor: fade(theme.palette.primary.light, 0.3),
    margin: theme.spacing(2),
    padding: theme.spacing(1),
    display: "flex",
  },
  paperHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
}));

const OrderTransport = props => {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  // Do these locally for now, maybe later in an addressReducer that could be used in other places where we have addresses
  const [addressRow1, setAddressRow1] = useState("");
  const [addressRow2, setAddressRow2] = useState("");
  const [postalNo, setPostalNo] = useState("");
  const [city, setCity] = useState("");
  const [deliveryInfo, setDeliveryInfo] = useState("");
  const [referenceId, setReferenceId] = useState("");
  const [deliveryDate, setDeliveryDate] = useState(format(startOfToday(), "yyyy-MM-dd"));
  const [deliveryTime, setDeliveryTime] = useState(format(new Date(), "HH:mm"));
  const [transportServices, setTransportServices] = useState([]);
  const [selectedTransportService, setSelectedTransportService] = useState("");

  const network = useSelector(state => state.network.network);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (user) {
      setAddressRow1(user.address?.addressRow1);
      setAddressRow2(user.address?.addressRow2);
      setPostalNo(user.address?.postalNo);
      setCity(user.address?.city);
      setReferenceId(user.userInformation?.referenceId);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.company && user.company.id) {
      logisticsPartnerServices.handleRequest(
        services.LIST_TRANSPORT_SERVICES,
        `?network=${network}&customer=${user.company.id}`,
        data => {
          setTransportServices(data.transportServices);
        },
      );
    }
  }, [network, user]);

  const handleOrderTransport = evt => {
    evt.preventDefault();

    let orderType = "";
    let logisticsCompany = "";
    if (transportServices[selectedTransportService] && transportServices[selectedTransportService].logisticsPartner) {
      orderType = transportServices[selectedTransportService].orderType;
      logisticsCompany = transportServices[selectedTransportService].logisticsPartner.id;
    }

    if (!logisticsCompany) {
      props.setError({ errorMessage: "Transportör måste väljas", error: "" });
      return;
    }

    const deliveryDateTime = toDate(
      new Date(
        deliveryDate.substring(0, 4),
        deliveryDate.substring(5, 7) - 1,
        deliveryDate.substring(8, 10),
        deliveryTime.substring(0, 2),
        deliveryTime.substring(3, 5),
        0,
      ),
    );
    if (compareAsc(deliveryDateTime, new Date()) < 1) {
      props.setError({ errorMessage: "Önskad leveranstid har redan passerat", error: "" });
    }

    const data = {
      information: deliveryInfo,
      address: {
        addressRow1: addressRow1,
        addressRow2: addressRow2,
        postalNo: postalNo,
        city: city,
        country: "SE",
      },
      toStorage: false,
      orderType: orderType,
      logisticsCompany: logisticsCompany,
      reference: referenceId,
      requestedDateTime: deliveryDateTime,
    };
    data.queryParam = {
      sessionId: props.session.sessionId,
    };
    data.pathParam = {
      adId: props.id,
    };

    adServices.handleRequest(services.ORDER_TRANSPORT, data, () => {
      history.push(`${pages.toNetworkADS(network)}/${props.id}`);
    });
  };

  return (
    <form onSubmit={handleOrderTransport}>
      <Header heading="" linkBack={() => history.push(`${pages.toNetworkADS(network)}/${props.id}`)} />
      <Typography className={classes.heading} variant="h4">
        Beställ transport
      </Typography>
      <Typography variant="h6">Välj vem som ska leverera</Typography>
      {transportServices
        .filter(transportService => transportService.category === "PICKUP")
        .map((transportService, index) => {
          let description = transportService.description.split("\n");
          return (
            <Paper key={index} className={classes.paper}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxOutlinedIcon fontSize="large" />}
                className={classes.checkbox}
                checked={selectedTransportService === index}
                onChange={() => setSelectedTransportService(index)}
                color={theme.palette.primary.dark}
                inputProps={{ "aria-label": "logistics partner checkbox" }}
              />
              <div>
                <Typography className={classes.paperHeading}>{transportService.logisticsPartner.name}</Typography>
                {description.map((line, index) => (line ? <Typography key={index}>{line}</Typography> : <br />))}
              </div>
            </Paper>
          );
        })}
      {!transportServices.find(service => service.category === "PICKUP") && (
        <Paper className={classes.paper}>
          <div>
            <Typography className={classes.paperHeading}>Ingen transportör tillgänglig</Typography>
          </div>
        </Paper>
      )}
      <Typography variant="h6">Till vilken adress vill du få leveransen?</Typography>
      <div className={classes.addressForm}>
        <MylocTextField
          required
          fullWidth
          value={addressRow1}
          label="Adress 1"
          onChange={e => setAddressRow1(e.target.value)}
        />
        <MylocTextField fullWidth value={addressRow2} label="Adress 2" onChange={e => setAddressRow2(e.target.value)} />
        <MylocTextField
          required
          fullWidth
          value={postalNo}
          label="Postnummer"
          onChange={e => setPostalNo(e.target.value)}
        />
        <MylocTextField required fullWidth value={city} label="Ort" onChange={e => setCity(e.target.value)} />
        <MylocTextField
          required
          fullWidth
          value={referenceId}
          label="Referensnummer"
          onChange={e => setReferenceId(e.target.value)}
        />
        <MylocTextField
          fullWidth
          multiline
          value={deliveryInfo}
          label="Information till transportör"
          onChange={e => setDeliveryInfo(e.target.value)}
        />
        <Box display="flex">
          <MylocTextField
            className={classes.date}
            required
            id="new-date"
            label="Önskad leverans"
            value={deliveryDate}
            type="date"
            onChange={e => setDeliveryDate(e.target.value)}
          />
          <MylocTextField
            className={classes.time}
            required
            id="new-time"
            label="tid"
            value={deliveryTime}
            type="time"
            onChange={e => setDeliveryTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </div>

      <Button type="submit" variant="contained" color="primary">
        Beställ
      </Button>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    session: state.session,
    installation: state.installation,
  };
};

const mapDispatchToProps = {
  setError: errorActionCreators.setError,
  setMessage: messageActionCreators.setMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderTransport);
