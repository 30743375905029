/* eslint-disable react/prop-types */
// React/javascript libraries
import React, { useState, useEffect } from "react";
import { Typography, Paper, IconButton } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";

import MylocTextField from "../myloc-components/MylocTextField";
import CreateIcon from "@material-ui/icons/Create";
import RoundedButton from "../components/RoundedButton";

import services from "../services/services";
import logisticsPartnerServices from "../services/logisticsPartnerServices";

const useStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: fade(theme.palette.primary.light, 0.3),
    margin: theme.spacing(2),
    padding: theme.spacing(1),
  },
  paperHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  paperHeading: {
    fontWeight: "bold",
  },
  paperTextField: {
    backgroundColor: "white",
  },
}));

const TransportSettings = props => {
  const classes = useStyles();
  const [transportServices, setTransportServices] = useState([]);
  const [indexToEdit, setIndexToEdit] = useState(-1);

  const network = useSelector(state => state.network.network);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    if (user?.company?.id) {
      logisticsPartnerServices.handleRequest(
        services.LIST_TRANSPORT_SERVICES,
        `?network=${network}&logisticsCompany=${user.company.id}`,
        data => {
          setTransportServices(data.transportServices);
        },
      );
    }
  }, [user, network]);

  const handleChangeDescription = (evt, indexToChange) => {
    const newTransportService = { ...transportServices[indexToChange], description: evt.target.value };

    const newTransportServices = transportServices.map((transportService, index) =>
      indexToChange === index ? newTransportService : transportService,
    );
    setTransportServices(newTransportServices);
  };

  const handleUpdate = index => {
    const request = {};
    request.transportService = transportServices[index];
    // add query and path parameters
    request.queryParam = {
      sessionId: props.session.sessionId,
      network: network,
    };

    logisticsPartnerServices.handleRequest(services.UPDATE_TRANSPORT_SERVICE, request, () => {
      setIndexToEdit(-1);
    });
  };

  return (
    <div>
      <Typography variant="h4">Logistikinställningar</Typography>
      {transportServices.map((transportService, index) => {
        let orderCategory = "";
        if (transportService.category === "PICKUP") {
          orderCategory = "Inlagring";
        }
        if (transportService.category === "STOCKIN") {
          orderCategory = "Transport";
        }
        let description = transportService.description.split("\n");

        return (
          <Paper key={index} className={classes.paper}>
            <div className={classes.paperHeader}>
              <Typography className={classes.paperHeading}>
                {orderCategory} - {transportService.customer}
              </Typography>
              {indexToEdit === -1 && (
                <IconButton onClick={() => setIndexToEdit(index)}>
                  <CreateIcon />
                </IconButton>
              )}
            </div>
            <div>
              {index === indexToEdit ? (
                <>
                  <MylocTextField
                    className={classes.paperTextField}
                    fullWidth
                    multiline
                    label="Beskrivning"
                    value={transportService.description}
                    onChange={evt => handleChangeDescription(evt, index)}
                  />
                  <div>
                    <RoundedButton color="primary" onClick={() => handleUpdate(index)}>
                      Ok
                    </RoundedButton>
                    <RoundedButton onClick={() => setIndexToEdit(-1)}>Avbryt</RoundedButton>
                  </div>
                </>
              ) : (
                description.map((line, index) => (line ? <Typography key={index}>{line}</Typography> : <br />))
              )}
            </div>
          </Paper>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    installation: state.installation,
    session: state.session,
  };
};

const mapDispatchToProps = {
  //  setError: errorActionCreators.setError,
  //  setMessage: messageActionCreators.setMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransportSettings);
