import React, { useState, useEffect } from "react";
import { ButtonBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MylocVideo from "../myloc-components/MylocVideo";

const useStyles = makeStyles(theme => ({
  imageButton: {
    borderRadius: "10px",
    border: "solid 1px darkgrey",
    overflow: "hidden",
    height: "90px",
    width: "160px",
  },
  mainVideo: {
    padding: theme.spacing(3),
    margin: "auto",
  },
  selectGrid: {
    display: "flex",
    justifyContent: "center",
  },
  selectPanel: {
    padding: theme.spacing(1),
  },
}));

const InstructionVideos = () => {
  const classes = useStyles();

  const [activeVideoUrl, setActiveVideoUrl] = useState("/videos/get-started.mp4");
  const [activePreviewUrl, setActivePreviewUrl] = useState("/videos/get-started.png");
  const [showVideo, setShowVideo] = useState(true);

  // I cannot get the video to restart with the preview screen without this hack
  useEffect(() => {
    setShowVideo(true);
  }, [showVideo]);
  return (
    <div>
      <div className={classes.mainVideo}>
        {showVideo && <MylocVideo url={activeVideoUrl} previewImage={activePreviewUrl}></MylocVideo>}
      </div>
      <div className={classes.selectGrid}>
        <div className={classes.selectPanel}>
          <ButtonBase
            onClick={() => {
              setActiveVideoUrl("/videos/get-started.mp4");
              setActivePreviewUrl("/videos/get-started.png");
              setShowVideo(false);
            }}
          >
            <img className={classes.imageButton} src="/videos/get-started.png" alt="get started"></img>
          </ButtonBase>
        </div>
        <div className={classes.selectPanel}>
          <ButtonBase
            onClick={() => {
              setActiveVideoUrl("/videos/new-ad.mp4");
              setActivePreviewUrl("/videos/new-ad.png");
              setShowVideo(false);
            }}
          >
            <img className={classes.imageButton} src="/videos/new-ad.png" alt="new ad"></img>
          </ButtonBase>
        </div>
      </div>
    </div>
  );
};

export default InstructionVideos;
