/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import queryString from "query-string";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, FormControlLabel, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useHistory } from "react-router-dom";
import MylocTextField from "../myloc-components/MylocTextField";
import accountServices from "../services/accountServices";
import ReactHtmlParser from "react-html-parser";
import MylocErrorMessage from "../myloc-components/MylocErrorMessage";
import { connect, useSelector } from "react-redux";
import * as actions from "../reducers/userReducer";
import * as commonActionCreators from "../reducers/commonReducer";
import * as errorActionCreators from "../reducers/errorReducer";
import services from "../services/services";
import pages from "../utils/pages";
import DetailSegment from "./DetailSegmentWithHeader";
import Header from "../components/Header";

const useStyles = makeStyles(theme => ({
  heading: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(1),
  },

  button: {},
  root: {
    "margin-top": theme.spacing(4),
  },

  media: {
    height: 300,
  },
}));

const Register = props => {
  //const [email, setEmail] = useState("");
  const [accessMessages, setAccessMessages] = useState([]);
  const [id, setId] = useState("");
  const [firstTimeRegistration, setFirstTimeRegistration] = useState(false);
  const [companies, setCompanies] = useState([]);
  const location = useLocation();
  const history = useHistory();

  const network = useSelector(state => state.network.network);
  const user = useSelector(state => state.user.user);

  // get user info
  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const id = queryParams.id;
    setId(id);

    if (!props.user.email && user.email) {
      props.setEmail(user?.email);
      props.setFirstName(user?.userInformation?.firstName);
      props.setSurname(user?.userInformation?.surname);
      props.setPhoneNumber(user?.userInformation?.phoneNumber);
      props.setAddressRow1(user?.address?.addressRow1);
      props.setAddressRow2(user?.address?.addressRow2);
      props.setPostalNo(user?.address?.postalNo);
      props.setCity(user?.address?.city);
      props.setCompany(user?.company?.description);
      props.setCompanyDescription(user?.company?.description);
      props.setFacility(user?.facility?.id);
      props.setFacilityDescription(user?.facility?.description);
      props.setReferenceId(user?.userInformation?.referenceId);
    }

    if (id && !props.user?.email && !props.registrationCompleted) {
      accountServices.getInfo(id).then(info => {
        if (info) {
          setFirstTimeRegistration(true);
          props.setEmail(info.email);
          if (info.companies) {
            setCompanies(info.companies);
          }
          if (info.accessMessages) {
            const extendedAccessMessages = info.accessMessages.map(accessMessage => {
              return { ...accessMessage, approved: false };
            });
            setAccessMessages(extendedAccessMessages);
          }
        }
      });
    }
  }, [location, props, user]);

  const classes = useStyles();
  const { t } = useTranslation();
  const [showError, setShowError] = useState(false);
  const [errorMessage] = useState("");

  const registerUser = evt => {
    evt.preventDefault();

    const newAccount = {
      network: network,
      accountId: id,
      password: props.user.password,
      company: props.user.company.id,
      facility: props.user.facility.id,
      userInformation: {
        firstName: props.user.userInformation.firstName,
        surname: props.user.userInformation.surname,
        phoneNumber: props.user.userInformation.phoneNumber,
        referenceId: props.user.userInformation.referenceId,
      },
      address: {
        addressRow1: props.user.address.addressRow1,
        addressRow2: props.user.address.addressRow2,
        postalNo: props.user.address.postalNo,
        city: props.user.address.city,
        country: "SE",
        description: "En sådan där testadress",
      },
      userMessages: accessMessages.map(accessMessage => {
        return { messageId: accessMessage.messageId, accept: accessMessage.approved };
      }),
    };

    let service;
    let callback = null;
    let data;

    if (!firstTimeRegistration) {
      service = services.USER_UPDATE;
      delete newAccount.accountId;
      delete newAccount.password;
      delete newAccount.company;
      delete newAccount.network;
      delete newAccount.userMessages;
      data = { id: props.session.sessionId, newAccount };

      //When user data is changed, we need to get load it again, after successful update as a callback
      callback = function() {
        accountServices.handleRequest(services.GET_USER_INFO, props.session.sessionId);
      };
    } else {
      accessMessages.forEach(message => {
        if (!message.approved) {
          props.setError({
            errorMessage: "Villkoren för lagring av användaruppgifter måste godkännas",
            error: "",
          });
          return;
        }
      });
      service = services.CREATE;
      data = { id, newAccount };

      callback = () => {
        props.setRegistrationCompleted();
        props.clearUser();
      };
    }

    accountServices.handleRequest(service, data, callback);
  };

  const handleAccessMessageChange = (evt, currentAccessMessage) => {
    currentAccessMessage.approved = evt.target.checked;
    const newAccessMessages = accessMessages.map(accessMessage => {
      if (accessMessage.messageId === currentAccessMessage.messageId) {
        return currentAccessMessage;
      } else {
        return accessMessage;
      }
    });

    setAccessMessages(newAccessMessages);
  };

  const messagesNotApproved = () => {
    let notApproved = false;

    if (accessMessages.length) {
      for (const message of accessMessages) {
        if (!message.approved) {
          notApproved = true;
          break;
        }
      }
    }

    return notApproved;
  };

  return (
    <>
      {props.registrationCompleted ? (
        <Card className={classes.root}>
          <CardMedia className={classes.media} image="/images/giver.jpg" />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              Tack! Nu kan du <Link to={pages.LOGIN}>logga in</Link>
            </Typography>
          </CardContent>
        </Card>
      ) : (
        <>
          <Header
            heading={firstTimeRegistration ? "Registrering" : "Profil"}
            linkBack={() => history.pushState(pages.toNetworkADS(network))}
          />
          <form onSubmit={registerUser}>
            <DetailSegment headingText={t("Hur du loggar in")}>
              <MylocTextField fullWidth disabled label={t("Email")} value={props.user.email ?? ""} />

              {firstTimeRegistration && (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Password")}
                  type="password"
                  onChange={e => props.setPassword(e.target.value)}
                />
              )}
            </DetailSegment>
            <DetailSegment headingText={t("Vi vill veta lite om dig")}>
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  label={t("First name")}
                  value={props.user.userInformation.firstName}
                  onChange={e => props.setFirstName(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  required
                  label={t("First name")}
                  value={props.user.userInformation.firstName}
                  onChange={e => props.setFirstName(e.target.value)}
                />
              )}
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Last name")}
                  value={props.user.userInformation.surname}
                  onChange={e => props.setSurname(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Last name")}
                  value={props.user.userInformation.surname}
                  onChange={e => props.setSurname(e.target.value)}
                />
              )}
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  label={t("Phone no")}
                  value={props.user.userInformation.phoneNumber}
                  onChange={e => props.setPhoneNumber(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  label={t("Phone no")}
                  value={props.user.userInformation.phoneNumber}
                  onChange={e => props.setPhoneNumber(e.target.value)}
                />
              )}
            </DetailSegment>
            <DetailSegment headingText={t("Var jobbar du?")}>
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Address 1")}
                  value={props.user.address.addressRow1}
                  onChange={e => props.setAddressRow1(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Address 1")}
                  value={props.user.address.addressRow1}
                  onChange={e => props.setAddressRow1(e.target.value)}
                />
              )}
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  label={t("Address 2")}
                  value={props.user.address.addressRow2}
                  onChange={e => props.setAddressRow2(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  label={t("Address 2")}
                  value={props.user.address.addressRow2}
                  onChange={e => props.setAddressRow2(e.target.value)}
                />
              )}
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Postal No")}
                  value={props.user.address.postalNo}
                  onChange={e => props.setPostalNo(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Postal No")}
                  value={props.user.address.postalNo}
                  onChange={e => props.setPostalNo(e.target.value)}
                />
              )}
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  label={t("City")}
                  value={props.user.address.city}
                  onChange={e => props.setCity(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  required
                  label={t("City")}
                  value={props.user.address.city}
                  onChange={e => props.setCity(e.target.value)}
                />
              )}

              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  select
                  label={t("Municipality")}
                  value={props.user.company.id}
                  SelectProps={{
                    native: true,
                  }}
                  onChange={e => {
                    props.setCompany(e.target.value);
                    props.setCompanyDescription(e.target.textContent);
                  }}
                >
                  <option key="" value=""></option>
                  {companies.map(company => (
                    <option key={company.company} value={company.company}>
                      {company.name}
                    </option>
                  ))}
                </MylocTextField>
              ) : (
                <MylocTextField
                  disabled
                  fullWidth
                  required
                  label={t("Municipality")}
                  value={props.user.company.description}
                  onChange={e => props.setCompany(e.target.value)}
                />
              )}
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  select
                  label={t("Management")}
                  defaultValue=""
                  value={props.user.facility.id}
                  SelectProps={{
                    native: true,
                  }}
                  onChange={e => {
                    props.setFacility(e.target.value);
                    props.setFacilityDescription(e.target.textContent);
                  }}
                >
                  <option key="" value=""></option>
                  {companies.map(company =>
                    company.facilities.map(facility =>
                      facility.name !== company.name ? (
                        <option key={facility.facilty} value={facility.facility}>
                          {facility.name}
                        </option>
                      ) : null,
                    ),
                  )}
                </MylocTextField>
              ) : (
                <MylocTextField
                  disabled
                  fullWidth
                  required
                  label={t("Management")}
                  value={props.user.facility.description}
                  onChange={e => props.setFacility(e.target.value)}
                />
              )}
              {firstTimeRegistration ? (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Reference no")}
                  value={props.user.userInformation.referenceId}
                  onChange={e => props.setReferenceId(e.target.value)}
                />
              ) : (
                <MylocTextField
                  fullWidth
                  required
                  label={t("Reference no")}
                  value={props.user.userInformation.referenceId}
                  onChange={e => props.setReferenceId(e.target.value)}
                />
              )}
            </DetailSegment>
            {accessMessages.map((accessMessage, index) => {
              return (
                <div key={index}>
                  {ReactHtmlParser(accessMessage.content)}

                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => handleAccessMessageChange(e, accessMessage)}
                        checked={accessMessage.approved}
                        color="primary"
                      />
                    }
                    label="Jag godkänner"
                  />
                </div>
              );
            })}
            {/* 
        {firstTimeRegistration && (
          <Button type="submit" style={{ borderRadius: 25 }} color="primary" variant="contained">
            {firstTimeRegistration ? t("Register") : t("Uppdatera")}
          </Button>
        )}
        */}
            <Button
              type="submit"
              style={{ borderRadius: 25 }}
              color="primary"
              variant="contained"
              disabled={messagesNotApproved()}
            >
              {firstTimeRegistration ? t("Register") : t("Uppdatera")}
            </Button>
            <MylocErrorMessage showError={showError} setShowError={setShowError} errorMessage={errorMessage} />
          </form>
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    session: state.session,
    user: state.user,
    installation: state.installation,
    registrationCompleted: state.common.registrationCompleted,
  };
};

const mapDispatchToProps = {
  setEmail: actions.setEmail,
  setFirstName: actions.setFirstName,
  setSurname: actions.setSurname,
  setPhoneNumber: actions.setPhoneNumber,
  setPassword: actions.setPassword,
  setCompany: actions.setCompany,
  setCompanyDescription: actions.setCompanyDescription,
  setFacility: actions.setFacility,
  setFacilityDescription: actions.setFacilityDescription,
  setReferenceId: actions.setReferenceId,
  setAddressRow1: actions.setAddressRow1,
  setAddressRow2: actions.setAddressRow2,
  setPostalNo: actions.setPostalNo,
  setCity: actions.setCity,
  setCountry: actions.setCountry,
  clearUser: actions.clearUser,
  setRegistrationCompleted: commonActionCreators.setRegistrationCompleted,
  setError: errorActionCreators.setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
