// Constants for this current installation

import { networkActions } from "./networkActions";

const initialState = {
  network: null,
};

const networkReducer = (state = initialState, action) => {
  switch (action.type) {
    case networkActions.SET_NETWORK:
      return {
        ...state,
        network: action.network,
      };
    default:
      return { ...state };
  }
};

export default networkReducer;
