import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: {
          Login: "Sign in",
          Username: "Username",
          Password: "Password",
          Home: "Home",
          Search: "Search",
          "New ad": "New ad",
          Message: "Message",
          Menu: "Menu",
          "Search heading": "Search",
          Watch: "Watch",
          Categories: "Categories",
          Filter: "Filter",
          Condition: "Condition",
          Dimensions: "Dimensions",
          Length: "Length",
          Width: "Width",
          Height: "Height",
          Depth: "Depth",
          Weight: "Weight",
          "Available for pickup": "Available for pickup",
          "Advertiser details": "Advertiser details",
          "Can be picked up here": "Can be picked up here",
          Registrera: "Register",
          Email: "E-mail",
          "First name": "First name",
          "Last name": "Last name",
          "Phone no": "Phone no",
          "Address 1": "Address 1",
          "Address 2": "Address 2",
          "Postal No": "Postal No",
          City: "City",
          Municipality: "Municipality",
          Management: "Management",
          "Reference no": "Reference no/ID no",
          Uppdatera: "Update",
          FOUND_MULTIPLE_NETWORKS: "Found multiple networks",
          NO_NETWORK: "Didn't find a network",
          SELECT_NETWORK: "Select market",
          SELECT_TRIPLE_DOTS: "Select...",
        },
      },
      sv: {
        translation: {
          Login: "Logga in",
          Username: "Användarnamn",
          Password: "Lösenord",
          Home: "Hem",
          Search: "Sök",
          "New ad": "Ny annons",
          Message: "Meddelande",
          Menu: "Meny",
          "Search heading": "Sökning",
          Watch: "Bevaka",
          Categories: "Kategorier",
          Filter: "Filter",
          Condition: "Skick",
          Dimensions: "Dimensioner",
          Length: "Längd",
          Width: "Bredd",
          Height: "Höjd",
          Depth: "Djup",
          Weight: "Vikt",
          "Available for pickup": "Tillgänglig för avhämtning",
          "Advertiser details": "Annonsörsuppgifter",
          "Can be picked up here": "Finns att hämta här",
          Register: "Registrera",
          Email: "E-post",
          "First name": "Förnamn",
          "Last name": "Efternamn",
          "Phone no": "Telefonnr",
          "Address 1": "Adress 1",
          "Address 2": "Adress 2",
          City: "Ort",
          "Postal No": "Postnr",
          Municipality: "Kommun",
          Management: "Förvaltning/verksamhet",
          "Reference no": "Referensnr/IDnr",
          FOUND_MULTIPLE_NETWORKS: "Hittde flera nätverk",
          NO_NETWORK: "Hittade inget nätverk",
          SELECT_NETWORK: "Välj marknad",
          SELECT_TRIPLE_DOTS: "Välj...",
        },
      },
    },
    lng: "sv",
    fallbackLng: "sv",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
