import { createStore, combineReducers } from "redux";
import sessionReducer from "./reducers/sessionReducer";
import networkReducer from "./reducers/networkReducer";
import userReducer from "./reducers/userReducer";
import adReducer from "./reducers/adReducer";
import errorReducer from "./reducers/errorReducer";
import messageReducer from "./reducers/messageReducer";
import commonReducer from "./reducers/commonReducer";
import dialogReducer from "./reducers/dialogReducer";
import settingReducer from "./reducers/settingReducer";

const appReducer = combineReducers({
  session: sessionReducer,
  network: networkReducer,
  user: userReducer,
  newAd: adReducer,
  e: errorReducer,
  message: messageReducer,
  common: commonReducer,
  dialog: dialogReducer,
  setting: settingReducer,
});

const reducer = (state, action) => {
  if (action.type === "RESET_USER") {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = createStore(reducer);

export default store;
