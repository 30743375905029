import axios from "axios";
import services from "./services";
import { getRequest, sendRequest } from "../utils/restUtil";
import networkUtil from "../utils/networkUtil";
import store from "../store";

//const baseUrl = "http://localhost:8282/MyL/rs/ads";
const baseUrl = `${process.env.REACT_APP_API_URL}/ads`;
// This should be solved in some better way later

//Pass in an optional callback function as "postHandlingCallback". It will he handled if call is successful"
// This is an exact copy from adServices should probably be merged into a util
const _handleRequest = async (service, data, postHandlingCallback) => {
  try {
    const responseData = await sendRequest(service, data, postHandlingCallback);
    //Handle success result. Errorhandler handles if not
    _handleResponse(service, responseData);
  } catch (e) {
    console.log(
      "Error ocurred in _handlerRequest, but should have been taken care of in ErrorHandler: " + e.message,
      e,
    );
  }
};

const _handleResponse = (service, data) => {
  switch (service) {
    case services.CREATE_AD:
      _handleCreateAdResponse(data);
      break;
    case services.UPDATE_AD:
      _handleUpdateAdResponse(data);
      break;
    case services.ORDER_TRANSPORT:
      _handleOrderTransportResponse(data);
      break;
    case services.LIST_ADS:
      break;
    case services.GET_STATISTICS:
      break;
    case services.GET_START_PAGE_HTML:
      break;
    case services.UPDATE_START_PAGE_HTML:
      _handleUpdateStartPageHTMLResponse(data);
      break;
    default:
      return;
  }
};

const _handleCreateAdResponse = () => {
  const dataToDispatch = {
    type: "SET_MESSAGE",
    message: "Annons skapad",
  };
  store.dispatch(dataToDispatch);
};

const _handleUpdateAdResponse = () => {
  const dataToDispatch = {
    type: "SET_MESSAGE",
    message: "Annonsen ändrades",
  };
  store.dispatch(dataToDispatch);
};

const _handleOrderTransportResponse = () => {
  const dataToDispatch = {
    type: "SET_MESSAGE",
    message: "Din beställning är skickad till transportör",
  };
  store.dispatch(dataToDispatch);
};

const _handleUpdateStartPageHTMLResponse = () => {
  const dataToDispatch = {
    type: "SET_MESSAGE",
    message: "Startsidan uppdaterades",
  };
  store.dispatch(dataToDispatch);
};

// GET /ads
const list = filter => {
  const { network } = store.getState().network;
  let queryString = "";
  if (filter.category) {
    queryString = queryString.concat(`&category=${filter.category}`);
  }
  if (filter.owner) {
    queryString = queryString.concat(`&owner=${filter.owner}`);
  }
  if (filter.statusTo && filter.statusFrom) {
    queryString = queryString.concat(`&statusFrom=${filter.statusFrom}&statusTo=${filter.statusTo}`);
  }
  return getRequest(`${baseUrl}?network=${network}${queryString}`);
};

// Get /ads/{id}
const get = id => {
  const network = networkUtil.getNetwork();
  return getRequest(`${baseUrl}/?network=${network}&adId=${id}`);
};

// POST /ads/{id}/publish
const publish = id => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/publish?id=${sessionId}&network=${network}`);
  return request.then(response => response.data);
};

// POST /ads/{id}/unpublish
const unpublish = id => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/unpublish?id=${sessionId}&network=${network}`);
  return request.then(response => response.data);
};

// POST /ads/{id}/mediate
const mediate = (id, recipient, numberOfItems) => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/mediate?id=${sessionId}&network=${network}`, {
    numberOfItems: numberOfItems,
    recipient: recipient,
  });
  return request.then(response => response.data);
};

// POST /ads/{id}/archive
const archive = id => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/archive?id=${sessionId}&network=${network}`);
  return request.then(response => response.data);
};

// POST /ads/{id}/book
const book = (id, numberOfItems) => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/book?id=${sessionId}&network=${network}`, {
    numberOfItems: numberOfItems,
  });
  return request.then(response => response.data);
};

// POST /ads/{id}/unbook
const unbook = (id, numberOfItems, userId) => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/unbook?id=${sessionId}&network=${network}`, {
    numberOfItems: numberOfItems,
    userId: userId,
  });
  return request.then(response => response.data);
};

// Is this needed?
// POST /ads/{id}/unbook-all
const unbookAll = (id, userId) => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/unbookAll?id=${sessionId}&network=${network}`, {
    userId: userId,
  });
  return request.then(response => response.data);
};

const getCategories = () => {
  const network = networkUtil.getNetwork();
  const request = axios.get(`${baseUrl}/categories?network=${network}`);
  return request.then(response => response.data);
};

const getAdLogo = companyGroup => {
  const { sessionId } = store.getState().session;
  const request = axios.get(`${baseUrl}/adLogo?id=${sessionId}&companyGroup=${companyGroup}`);
  return request.then(response => response.data);
};

// POST /ad/{id}/orderTransport
const orderTransport = (id, data) => {
  const { sessionId } = store.getState().session;
  const request = axios.post(`${baseUrl}/${id}/orderTransport?id=${sessionId}`, data);
  return request.then(response => response.data);
};

// POST /ads/{id}/lines
const createLine = (id, newLine) => {
  const request = axios.post(`${baseUrl}/${id}/lines`, newLine);
  return request.then(response => response.data);
};

// DELETE /abs/{id}/lines/{id}
const deleteLine = (id, pictureId) => {
  // const request = axios.delete(`${baseUrl}/${id}/lines/${lineId}`);
  //return request.then(response => response.data);
};

// POST /ads/{id}/pictures
const uploadPicture = (id, uri, isMainImage) => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const formData = new FormData();
  var file = dataURLtoFile(uri, "filename.png");
  formData.append("photo", file);
  formData.append("mainImage", isMainImage);

  const request = axios({
    method: "POST",
    url: `${baseUrl}/${id}/pictures/?id=${sessionId}&network=${network}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });

  return request.then(response => response.data);
};

// DELETE /abs/{id}/pictures/{id}
const deletePicture = (id, pictureId) => {
  const network = networkUtil.getNetwork();
  const { sessionId } = store.getState().session;
  const request = axios.delete(`${baseUrl}/${id}/pictures/${pictureId}?id=${sessionId}&network=${network}`);
  return request.then(response => response.data);
};

// GET /abs/{id}/field=pickupAddress
const getAddress = id => {
  const request = axios.get(`${baseUrl}/${id}?fields=pickupAddress`);
  return request.then(response => response.data);
};

//Pass in an optional callback function as "postHandlingCallback". It will he executed if call is successful, i e redirection"
const handleRequest = (service, data, postHandlingCallback) => {
  _handleRequest(service, data, postHandlingCallback);
};

export default {
  list,
  get,
  //create,
  //update,
  publish,
  unpublish,
  archive,
  book,
  unbook,
  unbookAll,
  mediate,
  orderTransport,
  createLine,
  deleteLine,
  uploadPicture,
  deletePicture,
  getAddress,
  getCategories,
  getAdLogo,
  handleRequest,
};
