/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography, IconButton } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Hidden from "@material-ui/core/Hidden";

import MylocEditor from "../myloc-components/MylocEditor";

import services from "../services/services";
import adServices from "../services/adServices";

import SearchField from "./SearchField";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import BarChart from "./BarChart";
import { capitalize } from "../utils/stringUtil";
import pages from "../utils/pages";
import MylocSmallHeader from "./MylocSmallHeader";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
  },

  button: {
    color: "white",
    textTransform: "uppercase",
  },
  text: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: theme.spacing(1),
  },
  media: { height: 200 },
}));

const NetworkHome = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  let history = useHistory();

  const defaultHTML = '<p style="text-align:center;"><span style="font-size: 36px;">Påverka genom att dela</span></p>';

  const handleSearch = () => {
    history.push(pages.toNetworkADS(network));
  };

  const [giftedRows, setGiftedRows] = useState([]);
  const [receivedRows, setReceivedRows] = useState([]);
  const [editorHTML, setEditorHTML] = useState("");
  const [editorOpen, setEditorOpen] = useState(false);

  const network = useSelector(state => state.network.network);
  const user = useSelector(state => state.user.user);

  useEffect(() => {
    adServices.handleRequest(services.GET_STATISTICS, `?network=${network}`, data => {
      setGiftedRows(data.giftedRows);
      setReceivedRows(data.receivedRows);
    });
  }, [network]);

  useEffect(() => {
    if (user && user.companyGroup) {
      adServices.handleRequest(
        services.GET_START_PAGE_HTML,
        `?network=${network}&companyGroup=${user.companyGroup}`,
        data => {
          if (data) {
            setEditorHTML(data);
          } else {
            setEditorHTML(defaultHTML);
          }
        },
      );
    }
  }, [network, user]);

  let bestNumberOfReused = 0;
  let bestReuser = "";
  receivedRows.forEach(item => {
    if (Math.max(bestNumberOfReused, item.numberOfRows) > bestNumberOfReused) {
      bestReuser = item.companyGroup;
      bestNumberOfReused = Math.max(bestNumberOfReused, item.numberOfRows);
    }
  });
  bestReuser = capitalize(bestReuser);

  let bestNumberOfGifted = 0;
  let bestGiver = "";
  giftedRows.forEach(item => {
    if (Math.max(bestNumberOfGifted, item.numberOfRows) > bestNumberOfGifted) {
      bestGiver = item.companyGroup;
      bestNumberOfGifted = Math.max(bestNumberOfGifted, item.numberOfRows);
    }
  });
  bestGiver = capitalize(bestGiver);

  const gifted = {
    data: giftedRows.map(item => {
      return {
        value: item.numberOfRows,
        name: capitalize(item.companyGroup),
        color: "green",
      };
    }),
  };

  const received = {
    data: receivedRows.map(item => {
      return {
        value: item.numberOfRows,
        name: capitalize(item.companyGroup),
        color: "#b2962e",
      };
    }),
  };

  const handleEditorSave = newHTML => {
    const newObject = { html: newHTML };
    newObject.queryParam = {
      sessionId: props.session.sessionId,
      network: network,
    };
    newObject.pathParam = {
      companyGroup: user.companyGroup,
    };

    adServices.handleRequest(services.UPDATE_START_PAGE_HTML, newObject, data => {
      setEditorHTML(data);
      setEditorOpen(false);
    });
  };

  const handleEditorCancel = () => {
    setEditorOpen(false);
  };

  return (
    <>
      <Hidden smUp>
        <MylocSmallHeader />
        <SearchField showWatches={false} onSearch={handleSearch} />
      </Hidden>
      {!editorOpen ? (
        editorHTML ? (
          <Box marginBottom={5} marginTop={10} dangerouslySetInnerHTML={{ __html: editorHTML }}></Box>
        ) : (
          <Box marginBottom={5} marginTop={10}>
            <Typography align="center" variant="h4">
              Påverka genom att dela
            </Typography>
          </Box>
        )
      ) : null}

      {editorOpen && <MylocEditor initialHTML={editorHTML} onSave={handleEditorSave} onCancel={handleEditorCancel} />}
      {!editorOpen && user && user.authorizedTasks && user.authorizedTasks.includes("AdminHome") && (
        <div style={{ display: "flex", flexDirection: "row-reverse" }}>
          <IconButton size="small" edge="end" onClick={() => setEditorOpen(true)}>
            <CreateIcon />
          </IconButton>
        </div>
      )}
      <Grid container spacing={10} style={{ padding: "24px" }}>
        <Grid xs={12} sm={12} md={12} lg={4} xl={4} item={true}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            {t("Mindre miljöpåverkan")}
          </Typography>
          <Card className={classes.card}>
            <CardMedia className={classes.media} image="/images/environmentimpact.jpg" />
          </Card>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} xl={4} item={true}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            Bästa återbrukaren{bestReuser && ` är ${bestReuser}!`}
          </Typography>
          {gifted ? (
            <div>
              <BarChart chart={received} width={341} height={200} domain={{ y: [0, 20] }}></BarChart>
            </div>
          ) : (
            <Card className={classes.card}>
              <CardMedia className={classes.media} image="/images/reuser.jpg" />
            </Card>
          )}
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} xl={4} item={true}>
          <Typography align="center" gutterBottom variant="h5" component="h2">
            Bästa givaren{bestGiver && ` är ${bestGiver}!`}
          </Typography>
          {received ? (
            <div>
              <BarChart chart={gifted} width={341} height={200} domain={{ y: [0, 20] }}></BarChart>
            </div>
          ) : (
            <Card className={classes.card}>
              <CardMedia className={classes.media} image="/images/giver.jpg" />
            </Card>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps)(NetworkHome);
