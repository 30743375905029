import { useState } from "react";

const useToggle = (initialValues = []) => {
  const [values, setValues] = useState(initialValues);
  const toggleValue = val => {
    const newValues = [...values];
    if (newValues.indexOf(val) > -1) {
      newValues.splice(values.indexOf(val), 1);
    } else {
      newValues.push(val);
    }
    setValues(newValues);
  };
  return [values, val => toggleValue(val), setValues];
};

export default useToggle;
