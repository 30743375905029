import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  listItem: {
    marginTop: theme.spacing(3),
  },
}));

const links = [{ url: "https://myloc.se", text: "Myloc" }];

const LinksToPartners = () => {
  const classes = useStyles();
  return (
    <div>
      <Typography variant="h4">Länkar till partners</Typography>
      <ul>
        {links.map((link, index) => {
          return (
            <li className={classes.listItem} key={index}>
              <a href={link.url} target={"_blank"} rel="noopener noreferrer">
                {link.text}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default LinksToPartners;
