/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import MylocStars from "../myloc-components/MylocStars";
import CompanyLogo from "../components/CompanyLogo";

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1),
    height: "100%",
  },
  card: {
    width: "345px",
    padding: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: "80%", // 16:9
  },
  title: {
    textTransform: "UpperCase",
  },
  image: {
    height: "200px",
  },
  cardMedia: {
    height: "200px",
  },
}));

const ResultCard = ({ result, onClick }) => {
  const classes = useStyles();

  const { info, images, publishDate } = result;

  let image = "";
  if (images && images.mainFile && images.mainFile.url) {
    if (images.mainFile.thumbnailUrl) {
      image = `${process.env.REACT_APP_TOMCAT_ROOT_URL}/${images.mainFile.thumbnailUrl}`;
    } else if (images.mainFile.url) {
      image = `${process.env.REACT_APP_TOMCAT_ROOT_URL}/${images.mainFile.url}`;
    }
  }

  return (
    <Card className={classes.root} onClick={() => onClick(result)}>
      <CardActionArea>
        {image && (
          <div className={classes.image}>
            <CardMedia
              className={classes.cardMedia}
              component="img"
              alt={info.title}
              image={image}
              title={info.title}
            />
            <CompanyLogo relative={false} companyGroup={info.companyGroup}></CompanyLogo>
          </div>
        )}
        <CardContent>
          <div className={classes.container}></div>
          <Typography gutterBottom variant="h5" component="h2">
            {info.title}
          </Typography>
          {/*<Typography gutterBottom variant="body2">
            {(info.description && info.description.length) > 100
              ? info.description.substring(0, 97) + "..."
              : info.description}
            </Typography>*/}
          <Typography variant="body2" color="textSecondary" component="p">
            Antal kvar: {info.quantity.remaining} av {info.quantity.total}
          </Typography>
          <MylocStars numStars={info.quality.rank}></MylocStars>
          <Typography align="right">{publishDate}</Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ResultCard;
