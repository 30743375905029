/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function MylocSelect(props) {
  const classes = useStyles();
  const { selectedItem, handleSelect, items, itemKey, title, itemValueField } = props;

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          native
          value={selectedItem ? selectedItem : ""}
          onChange={handleSelect}
          label="BBB"
          inputProps={{
            name: "network",
            id: "outlined-native-simple",
          }}
        >
          <option key="" value="">
            {title}
          </option>
          <option key="_" value=""></option>

          {items.map(item => {
            return (
              <option key={item[itemKey]} value={item[itemValueField]}>
                {item["id"]}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
