/* eslint-disable react/prop-types */

import React from "react";

import { Button, Dialog, DialogContent, DialogActions, DialogTitle, Typography } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import * as dialogActionCreators from "../reducers/dialogReducer";
import pages from "../utils/pages";

import { connect } from "react-redux";

const NotLoggedInDialog = props => {
  const location = useLocation();
  return (
    <Dialog open={props.dialog.notLoggedIn.open}>
      <DialogTitle id="form-dialog-book">Inte inloggad</DialogTitle>
      <DialogContent>
        <Typography>{props.dialog.notLoggedIn.message}</Typography>
        <Link
          onClick={() => props.setNotLoggedInOpen(false, "")}
          to={{
            pathname: pages.LOGIN,
            state: { from: location },
          }}
        >
          Inloggning/Registrering
        </Link>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setNotLoggedInOpen(false, "")} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = state => {
  return {
    dialog: state.dialog,
  };
};

const mapDispatchToProps = {
  setNotLoggedInOpen: dialogActionCreators.setNotLoggedInOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotLoggedInDialog);
