const initialState = {
  typeId: 0,
  categoryId: "",
  subCategoryId: "",
  title: "",
  description: "",
  status: {
    id: "",
    description: "",
  },
  owner: {
    email: "",
    firstName: "",
    surName: "",
    phoneNumber: "",
    referenceId: "",
  },
  quantity: {
    total: 1,
    remaining: 0,
  },
  quality: {
    rank: "",
    description: "",
  },
  dimensions: {
    length: 0,
    width: 0,
    height: 0,
    depth: 0,
    weight: 0,
  },
  pickup: {
    addressRow1: "",
    addressRow2: "",
    postalNo: "",
    city: "",
    country: "",
  },
  pickupInformation: "",
  validationErrors: [],
};

// Actions
const SET_CATEGORY = "SET_CATEGORY";
const SET_SUB_CATEGORY = "SET_SUB_CATEGORY";
const SET_TITLE = "SET_TITLE";
const SET_TOTAL_QUANTITY = "SET_TOTAL_QUANTITY";
const SET_DESCRIPTION = "SET_DESCRIPTION";
const SET_QUALITY_RANK = "SET_QUALITY_RANK";
const SET_QUALITY_DESCRIPTION = "SET_QUALITY_DESCRIPTION";
const SET_WIDTH = "SET_WIDTH";
const SET_HEIGHT = "SET_HEIGHT";
const SET_LENGTH = "SET_LENGTH";
const SET_DEPTH = "SET_DEPTH";
const SET_WEIGHT = "SET_WEIGHT";
const SET_PICKUP_ADDRESS_ROW1 = "SET_PICKUP_ADDRESS_ROW1";
const SET_PICKUP_ADDRESS_ROW2 = "SET_PICKUP_ADDRESS_ROW2";
const SET_PICKUP_CITY = "SET_PICKUP_CITY";
const SET_PICKUP_POSTAL_NO = "SET_PICKUP_POSTAL_NO";
const INIT_PICKUP = "INIT_PICKUP";
const SET_PICKUP_INFORMATION = "SET_PICKUP_INFORMATION";
const INIT_AD = "INIT_AD";
const RESET = "RESET";

export default function adReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORY:
      return { ...state, categoryId: action.category, subCategoryId: 1 };
    case SET_SUB_CATEGORY:
      return { ...state, subCategoryId: action.subCategory };
    case SET_TITLE:
      return { ...state, title: action.title };
    case SET_TOTAL_QUANTITY:
      return { ...state, quantity: { ...state.quantity, total: action.totalQuantity } };
    case SET_DESCRIPTION:
      return { ...state, description: action.description };
    case SET_QUALITY_RANK:
      return { ...state, quality: { ...state.quality, rank: action.qualityRank } };
    case SET_QUALITY_DESCRIPTION:
      return { ...state, quality: { ...state.quality, description: action.qualityDescription } };
    case SET_WIDTH:
      return { ...state, dimensions: { ...state.dimensions, width: action.width } };
    case SET_HEIGHT:
      return { ...state, dimensions: { ...state.dimensions, height: action.height } };
    case SET_LENGTH:
      return { ...state, dimensions: { ...state.dimensions, length: action.length } };
    case SET_DEPTH:
      return { ...state, dimensions: { ...state.dimensions, length: action.depth } };
    case SET_WEIGHT:
      return { ...state, dimensions: { ...state.dimensions, weight: action.weight } };
    case SET_PICKUP_ADDRESS_ROW1:
      return { ...state, pickup: { ...state.pickup, addressRow1: action.addressRow1 } };
    case SET_PICKUP_ADDRESS_ROW2:
      return { ...state, pickup: { ...state.pickup, addressRow2: action.addressRow2 } };
    case SET_PICKUP_CITY:
      return { ...state, pickup: { ...state.pickup, city: action.city } };
    case SET_PICKUP_POSTAL_NO:
      return { ...state, pickup: { ...state.pickup, postalNo: action.postalNo } };
    case SET_PICKUP_INFORMATION:
      return { ...state, pickupInformation: action.pickupInformation };
    case INIT_PICKUP:
      return { ...state, pickup: action.pickup };
    case RESET:
      return initialState;
    case INIT_AD:
      return { ...state, ...action.ad };
    default:
      return state;
  }
}

// Action creators
export function setCategory(category) {
  return { type: SET_CATEGORY, category };
}
export function setSubCategory(subCategory) {
  return { type: SET_SUB_CATEGORY, subCategory };
}

export function setTitle(title) {
  return { type: SET_TITLE, title };
}

export function setTotalQuantity(totalQuantity) {
  return { type: SET_TOTAL_QUANTITY, totalQuantity };
}

export function setDescription(description) {
  return { type: SET_DESCRIPTION, description };
}

export function setQualityRank(qualityRank) {
  return { type: SET_QUALITY_RANK, qualityRank };
}

export function setQualityDescription(qualityDescription) {
  return { type: SET_QUALITY_DESCRIPTION, qualityDescription };
}

export function setWidth(width) {
  return { type: SET_WIDTH, width };
}

export function setHeight(height) {
  return { type: SET_HEIGHT, height };
}

export function setLength(length) {
  return { type: SET_LENGTH, length };
}

export function setDepth(depth) {
  return { type: SET_DEPTH, depth };
}

export function setWeight(weight) {
  return { type: SET_WEIGHT, weight };
}

export function setAddressRow1(addressRow1) {
  return { type: SET_PICKUP_ADDRESS_ROW1, addressRow1 };
}

export function setAddressRow2(addressRow2) {
  return { type: SET_PICKUP_ADDRESS_ROW2, addressRow2 };
}

export function setPostalNo(postalNo) {
  return { type: SET_PICKUP_POSTAL_NO, postalNo };
}

export function setCity(city) {
  return { type: SET_PICKUP_CITY, city };
}

export function setPickupInformation(pickupInformation) {
  return { type: SET_PICKUP_INFORMATION, pickupInformation };
}

export function initPickup(pickup) {
  return { type: INIT_PICKUP, pickup };
}

export function reset() {
  return { type: RESET };
}

export function initAd(ad) {
  return { type: INIT_AD, ad };
}

/*export const getNote = (id) => {
    return async dispatch => {
        const notes = await adService.get(id)
        dispatch({
            type: 'INIT_AD',
            data: notes,
        })
    }
}*/
