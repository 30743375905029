/* eslint-disable react/prop-types */
// React/javascript libraries
// eslint-disable-next-line no-unused-vars
import React from "react";
import { useState, useEffect, useCallback } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// Material ui
import { Button, Container, Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, Link } from "react-router-dom";
// Myloc imports
import MylocTextField from "../myloc-components/MylocTextField";
import MylocErrorMessage from "../myloc-components/MylocErrorMessage";
import HrWithText from "./HrWithText";
import accountServices from "../services/accountServices";
import * as sessionActionCreators from "../reducers/sessionReducer";
import * as commonActionCreators from "../reducers/commonReducer";

import services from "../services/services";
import pages from "../utils/pages";
import { setNetwork } from "../reducers/networkActions";
import { setError } from "../reducers/errorReducer";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  rememberMeCheckBox: {
    alignSelf: "center",
    marginTop: theme.spacing(3),
  },
  separator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  link: {
    alignSelf: "center",
  },
}));

const Login = props => {
  let history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);
  const [errorMessage] = useState("");

  const loggedIn = useSelector(state => state.session.loggedIn);
  const user = useSelector(state => state.user.user);
  const network = useSelector(state => state.network.network);

  const dispatch = useDispatch();

  useEffect(() => {
    if (history?.location?.state?.from) {
      setRedirectTo(history.location.state.from.pathname);
    }
  }, [history]);

  const setUserNetwork = useCallback(() => {
    try {
      if (user.networks.length > 1) {
        throw new Error(t("FOUND_MULTIPLE_NETWORKS"));
      } else if (user.networks.length === 0) {
        throw new Error(t("NO_NETWORK"));
      } else {
        dispatch(setNetwork(user.networks[0]));
      }
    } catch (e) {
      dispatch(setError(e));
    }
  }, [dispatch, t, user.networks]);

  useEffect(() => {
    if (loggedIn && user?.email) {
      setUserNetwork();
    }
  }, [loggedIn, setUserNetwork, user]);

  useEffect(() => {
    if (loggedIn && network) {
      history.push(redirectTo ? redirectTo : pages.toNetworkADS(network));
    }
  }, [history, loggedIn, network, redirectTo]);

  const handleLogin = event => {
    event.preventDefault();
    const user = {
      userId: userId,
      password: password,
    };

    //Login
    accountServices.handleRequest(services.LOGIN, user, props.setRegistrationReset);
  };

  const handleUserId = event => {
    setUserId(event.target.value);
  };

  const handlePassword = event => {
    setPassword(event.target.value);
  };

  const toggleRememberMe = (e, fnSetRememberMe) => {
    const valueToSend = e.target.checked;
    fnSetRememberMe(valueToSend);
  };

  return (
    <>
      <form onSubmit={handleLogin}>
        <Container className={classes.container} maxWidth="sm">
          <Typography variant="h5" align="center">
            {t("Login")}
          </Typography>
          <MylocTextField
            id="user-name-input"
            label={t("Userid")}
            value={userId}
            onChange={handleUserId}
            //className={classes.textField}
          />
          <MylocTextField
            id="password-input"
            label={t("Password")}
            value={password}
            type="password"
            onChange={handlePassword}
            autoComplete=""
          />
          <Button variant="contained" color="primary" type="submit">
            {t("Login")}
          </Button>
          <FormControlLabel
            className={classes.rememberMe}
            control={
              <Checkbox
                checked={props.rememberMe}
                color="primary"
                onChange={e => toggleRememberMe(e, props.setRememberMe)}
                value={props.rememberMe}
              />
            }
            label="Kom ihåg mig"
          />
          <Link className={classes.link} to={pages.RESET_PASSWORD}>
            Glömt lösenord?
          </Link>
        </Container>
        <HrWithText style={{ marginTop: "100px" }} text="eller" margin={theme.spacing(6)} />
        <Container className={classes.container} maxWidth="sm">
          <Link className={classes.link} to="/apply">
            Vill du registrera dig?
          </Link>
        </Container>
      </form>
      <MylocErrorMessage showError={showError} setShowError={setShowError} errorMessage={errorMessage} />
    </>
  );
};

const mapDispatchToProps = {
  setRememberMe: sessionActionCreators.setRememberMe,
  setRegistrationReset: commonActionCreators.setRegistrationReset,
};

const mapStateToProps = state => {
  return {
    session: state.session,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
