/* eslint-disable react/prop-types */
import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { IconButton, Typography, InputAdornment } from "@material-ui/core";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import SearchIcon from "@material-ui/icons/Search";
import MylocTextField from "../myloc-components/MylocTextField";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as settingActionCreators from "../reducers/settingReducer";

const useStyles = makeStyles(() => ({
  searchLine: {
    display: "flex",
  },
  watchButton: {
    display: "flex",
    flexDirection: "column",
  },
}));

const SearchField = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  let initialValue = props.initialValue;
  if (!initialValue) {
    initialValue = "";
  }

  return (
    <div className={classes.searchLine}>
      <MylocTextField
        placeholder="Vad söker du?"
        fullWidth
        size="small"
        onChange={evt => {
          props.setSearchString(evt.target.value);
        }}
        value={props.searchString}
        onKeyPress={ev => {
          if (ev.key === "Enter") {
            // Do code here
            ev.preventDefault();
            if (props.onSearch) {
              props.onSearch(props.searchString);
            }
          }
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={() => props.onSearch(props.searchString)}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      >
        {t("Search")}
      </MylocTextField>
      {props.showWatches && (
        <IconButton classes={{ label: classes.watchButton }} aria-label={t("Watch")} size="large">
          <BookmarkBorderIcon fontSize="large" />
          <Typography variant="caption">{t("Watch")}</Typography>
        </IconButton>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    searchString: state.setting.searchString,
  };
};

const mapDispatchToProps = {
  setSearchString: settingActionCreators.setSearchString,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);
