/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Typography } from "@material-ui/core";
import MylocStars from "../myloc-components/MylocStars";
import CompanyLogo from "../components/CompanyLogo";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    margin: "10px",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
  },
  content: {},
  cover: {
    flex: 1,
    margin: "10px",
  },
  title: {
    textTransform: "UpperCase",
  },
  cardContent: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
}));

const ResultCard = ({ result, onClick }) => {
  const classes = useStyles();

  const { info, images, publishDate } = result;

  let image = "";
  if (images && images.mainFile && images.mainFile.url) {
    if (images.mainFile.thumbnailUrl) {
      image = `${process.env.REACT_APP_TOMCAT_ROOT_URL}/${images.mainFile.thumbnailUrl}`;
    } else if (images.mainFile.url) {
      image = `${process.env.REACT_APP_TOMCAT_ROOT_URL}/${images.mainFile.url}`;
    }
  }

  return (
    <Card className={classes.root} onClick={() => onClick(result)}>
      <CardMedia className={classes.cover} image={image} title={info.title} alt={info.title} />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography align="left" variant="h5" className={classes.title}>
            {info.title}
          </Typography>
          <Typography align="left">
            Antal kvar: {info.quantity.remaining} av {info.quantity.total}
          </Typography>
          <MylocStars numStars={info.quality.rank}></MylocStars>
          {/*<Typography variant="body2" align="left">
            {info.description && info.description.length > 100
              ? info.description.substring(0, 97) + "..."
              : info.description}
            </Typography>*/}
          <Typography align="right">{publishDate}</Typography>
        </CardContent>
      </div>

      <CompanyLogo relative={true} companyId={info.companyGroup}></CompanyLogo>
    </Card>
  );
};

export default ResultCard;
